import React, { useState, useEffect } from "react";
import {
  ContentWrapper,
  CustomModal,
  OnboardingWrapper,
} from "../../component";
import DashboardContainer from "../../container/dashboardContainer";
import FeatherIcon from "feather-icons-react";
import { useHistory } from "react-router-dom";
import { OverviewColumn, OverviewHeader, OverviewContainer, OverviewInner, OverviewRow, OverviewTitle } from './user-dashboard-styles/user-dashboard-styles';

import {
  Button,
  HeaderWrapper,
  Label, PlayerForm, Select,
  PlayerContainer,

  PlayerHeader,

} from "./players-styles/players";
import { isAuthenticated } from "../../utils/data";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { Loading } from "../../container/loading";
import {
  GamesContainer,
} from "./games-styles/games-styles";

import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";
import { applyFilters } from '../../utils/filter'
import ReactPaginate from "react-paginate";

const ManageWinnings = () => {
  let history = useHistory();
  const {
    obj: { token },
  } = isAuthenticated();
  const [showModal, setShowModal] = useState(false);
  const [allActivities, setActivitiesList] = useState();
  const [count, setCount] = useState('');
  const [selectedReward,setSelectedReward] = useState();
  const [reward, setReward] = useState()
  

  const [spinner, setSpinner] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const [status, setStatus] = useState();
  const [pageLimit, setPageLimit] = useState(15);
  const PageCount = Math.ceil(count / pageLimit);



 
const changePage = ({ selected }) => {
  getActivitiesList(selected)
}
  

  const [loading, setLoading] = useState(false);

 const getRewardStatus = async()=>{
   const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/payment/api/user-rewards/status-list`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    setStatus(data?.obj)
  
 }


  const getActivitiesList = async (page) => {

    const filter = {
      page: page ? page  : currentPage,
      size: pageLimit,
      orderDirection: "DESC",
      orderColumn: "id",
    };

    const URL = applyFilters(
      `${process.env.REACT_APP_API_URL}/payment/api/user-rewards`,
      { ...filter }
    );
    setLoading(true);
    try {
      const { data } = await axios.get(`${URL}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      

      if (data) {
        setLoading(false);
        setCount(data?.obj?.count)
        setActivitiesList(
          data?.obj?.userRewards
        );
      }
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === "Network Error") {
          toast.error("Please check your network connection and try again!");
          setLoading(false);
        } else {
          toast.error("System Error!");
          setLoading(false);
        }
      }
    }
  };

 const updateRewardStatus = (item)=>{
     setSelectedReward(item)
     setShowModal(true)
 }

console.log(selectedReward)

 const confirmRewardStatus = async()=>{

  try{
    let payload ={
      userRewardId: selectedReward.id,
      rewardStatus: reward
    }
 
   const {data} = await axios.put(`${process.env.REACT_APP_API_URL}/payment/api/user-rewards/update-status`,payload,
       {
         headers: {
           Authorization: `Bearer ${token}`,
         },
       }
     )
     getActivitiesList();
     console.log("Response", data)
     setShowModal(false)
  }catch(e){
   console.log("Error updating status>>", e)
  }

 }

  useEffect(() => {
    getActivitiesList();
    getRewardStatus()
  },[])
  

 



  return (
    <DashboardContainer>
        {showModal && (
          <CustomModal setShowModal={setShowModal}>
                  <OnboardingWrapper.FormGroup > 
                    <div>
                    <div className="manage-winning-user-info" ><span> Username</span> : {selectedReward?.user?.name}</div>
                    <div className="manage-winning-user-info"><span> Phone</span> : {selectedReward?.user?.phone}</div>
                    <div className="manage-winning-user-info"><span> Email</span> : {selectedReward?.user?.email}</div>
                    <div className="manage-winning-user-info"><span> Location</span> : </div>
                    </div>
                    <br/>
                  <Label>Change Reward Status </Label>
                  <Select onChange={(e)=>setReward(e.target.value)} defaultValue={reward}>
                     {status?.map((statusType, i)=>(
                       <option key={i} value={statusType}>{statusType}</option>
                     ))} 
                  </Select>
                 
                </OnboardingWrapper.FormGroup>
                <Button onClick={confirmRewardStatus}>
                  Confirm
                </Button>
          </CustomModal>
       )}
      <ContentWrapper>
        <PlayerContainer>
          <HeaderWrapper>
            <PlayerHeader>
             Redeemed Items
              <div onClick={() => history.push("/rewards")}>
                <FeatherIcon icon="arrow-left" />
               Rewards
              </div>
            </PlayerHeader>
            <Button >
              Total:{count}
            </Button>
          </HeaderWrapper>
          <GamesContainer>
            <div className="players-table">
              {spinner && (
                    <Loading />
                  )}  

                  {loading && (
                    <Loading />
                  )}  

              {!loading && (
                <table className="players">
                  <tbody>
                    <tr>
                      <th>Username</th>
                      <th>Items</th>
                      <th>Redeemed with</th>
                      <th>Amount(NGN)</th>     
                      <th>Status</th>
                      <th>Coin Value </th>
                      <th> Point Value</th>
                      <th>Date</th>
                      <th>Update Status</th>
                    </tr>
                   
                    {allActivities?.map((activity, i) => (
                      
                      <tr key={i}>
                         <td>{activity?.user?.name}</td>
                        <td>{activity?.reward?.name}</td>
                        <td className="points">{activity?.valueType}</td>
                        <td className="points">{activity?.reward?.currency}  {(activity?.reward?.rewardAmount).toLocaleString() }</td>
                    
                        <td>
                          <div className="user-avatar" style={{color:activity?.rewardStatus === 'APPROVED' || activity?.rewardStatus==='CONFIRMED'  || activity?.rewardStatus === 'PROCESSED' ? '#06e12b' : activity?.rewardStatus === 'PENDING' ? 'gray' :  activity?.rewardStatus === 'PROCESSING' ?'#ed9717': 'red'}}>
                          {activity?.rewardStatus === 'APPROVED' || activity?.rewardStatus==='CONFIRMED' || activity?.rewardStatus === 'PROCESSED' ? 'Completed' :  activity?.rewardStatus == "PENDING" ? 'Pending' :  activity?.rewardStatus === 'PROCESSING'? 'Processing': activity?.rewardStatus === 'REJECTED'? 'Rejected' : "Failed"}
    
                          </div>
                        </td>
                        <td>
                          <div className="user-avatar" style={{color:activity?.valueType === 'COINS' ? '#ed9717' : ''}}>
                            {(activity?.reward?.coinValue).toLocaleString() }
                          </div>
                        </td>
                        <td>
                          <div className="user-avatar" style={{color:activity?.valueType === 'POINT' ? '#ed9717' : ''}}>
                            {(activity?.reward?.pointValue).toLocaleString() }
                          </div>
                        </td>

                        <td className="points">
                          <div className="cat-action-btn">
                           {moment(activity.createdTime).format("LLL")}  
                          </div>
                        </td>
                        <td style={{textAlign:'center'}}>
                         <button className='manage-winning-btn' onClick={()=>updateRewardStatus(activity)}>
                            Change 
                        </button>
                        </td>
                        
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
            <div style={{ justifyContent: "center", display: "flex" }}>
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={PageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
          </GamesContainer>
        </PlayerContainer>
      </ContentWrapper>
      
      <Toaster position="top-center" />
    </DashboardContainer>
  );
};

export default ManageWinnings;
