import React, { useState, useEffect, useLayoutEffect, useMemo } from "react";
import { ContentWrapper, CustomModal } from "../../component";
import DashboardContainer from "../../container/dashboardContainer";
import FeatherIcon from "feather-icons-react";
import { useHistory, Link } from "react-router-dom";
import {
  Button,
  HeaderWrapper,
  UserStatTotal,
  PlayerContainer,
  PlayerHeader,
  FilterUsers,
  UserStat,
  UserStatBox,
  UserStatRow,
  PlayerSearch,
} from "./players-styles/players";
import { isAuthenticated } from "../../utils/data";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { Loading } from "../../container/loading";
import ReactPaginate from "react-paginate";
import {
  TabList,
  TabHeader,
  TabContent,
} from "../../container/table-styles/table-styles";
import Fuse from "fuse.js";
import {
  GameColumn,
  GameDetailsWrapper,
  GameHeader,
  GameRow,
  GamesButton,
  GameSmall,
} from "./games-styles/games-styles";

const Player = () => {
  let history = useHistory();
  const {
    obj: { token },
  } = isAuthenticated();

  const addPlayer = () => {
    history.push("/add-player");
  };

  const [loading, setLoading] = useState(false);
  const [players, setPlayers] = useState();
  const [showModal, setShowModal] = useState(false);
  const [role, setRole] = useState(`ROLE_CLIENT`);
  const [updateDOM, setUpdateDOM] = useState();

  const [initial, setInitial] = useState();
  const [value, setValue] = useState();
  const [searchTerm, setSearchTerm] = useState("");

  const [postsPerPage] = useState(5);
  const [offset, setOffset] = useState(1);

  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(20);
  const [stat, setStat] = useState({});

  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const paginatedPlayers = players?.slice(indexOfFirstPost, indexOfLastPost);
  const PageCount = Math.ceil(players?.length / 20);

  const tablist = [
    { id: 1, name: "Players", type: "ROLE_CLIENT" },
    { id: 2, name: "Games Operation", type: "STAFF" },
    { id: 3, name: "Clients", type: "ROLE_ADMIN_CLIENT" },
    { id: 4, name: "Admin", type: "ROLE_ADMIN" },
  ];

  const changePage = ({ selected }) => {
    console.log(selected);
    setCurrentPage(selected + 1);
  };


  useEffect(() => {
    getUserSum();
  }, []);
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage + 1);
  };
  const getUserSum = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/auth/admin/registration-stat`,
        {
          headers: {
            Authorization: ` Bearer ${token}`,
          },
        }
      );

      setStat(data);
    } catch (err) {
      console.log(err.response);
    }
  };

  const getPlayers = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/auth/admin/users?role=${role}`,
        {
          headers: {
            Authorization: ` Bearer ${token}`,
          },
        }
      );

      if (data) {
        setLoading(false);
        setPlayers(data);
        setInitial(data);
      }
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === "Network Error") {
          toast.error("Please check your network connection and try again!");
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    }
  };

  const deactivateUser = async () => {
    setLoading(true);
    try {
      let payload = {
        email: value.email,
      };

      console.log("PAYLOADD", payload);

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/auth/admin/deactivate-user/`,
        payload,
        {
          headers: {
            Authorization: ` Bearer ${token}`,
          },
        }
      );

      console.log(data);
      if (data) {
        setLoading(false);

        // toast.success('User Blocked Successfully')
        setShowModal(false);
        setInitial(data);
        setUpdateDOM(data)
      }
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === "Network Error") {
          toast.error("Please check your network connection and try again!");
          setLoading(false);
        } else {
          toast.error("System error");
          setLoading(false);
        }
      }
    }
  };

  const confirmDeactivate = (data) => {
    setShowModal(true);
    setValue(data);

    console.log("Email", data);
  };

  useEffect(() => {
    if (players) {
      const fuse = new Fuse(players, { keys: ["name", "email", "id"] });

      const results = fuse.search(searchTerm).map(({ item }) => item);

      if (players.length > 0 && searchTerm.length > 3 && results.length > 0) {
        setPlayers(results);
      } else {
        setPlayers(initial);
      }
    }
  }, [searchTerm]);

  useLayoutEffect(() => {
    getPlayers();
  }, [role, updateDOM]);

  return (
    <DashboardContainer>
      {showModal && (
        <CustomModal setShowModal={setShowModal}>
          <GameDetailsWrapper>
            <GameRow>
              <GameColumn>
                <FeatherIcon icon="info" color="#ed971" />
                <GameHeader style={{color:'var(--humber-light)'}}>Are you Sure!</GameHeader>
                <GameSmall>
                  You want to deactive <span style={{color:'var(--humber-golden)'}} >{value?.name}</span>
                </GameSmall>
              </GameColumn>
            </GameRow>
            <GameRow>
              <GamesButton onClick={deactivateUser}>Yes</GamesButton>
              <GamesButton
                color="rgb(0 80 157)"
                bgColor="#fff"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </GamesButton>
            </GameRow>
          </GameDetailsWrapper>
        </CustomModal>
      )}

      <ContentWrapper>
        <PlayerContainer>
          <HeaderWrapper>
            <PlayerHeader>All Users</PlayerHeader>
            <Button onClick={addPlayer}>
              <FeatherIcon icon="plus" /> Add User
            </Button>
          </HeaderWrapper>
          <UserStat>
            <UserStatBox>
              <UserStatRow>
                <div>Today's registered Users</div>
                <div>{stat["TODAY"]}</div>
              </UserStatRow>
              <UserStatRow>
                <div>Total Registraion for the past 7 days</div>
                <div>{stat["SEVEN DAYS"]}</div>
              </UserStatRow>
              <UserStatRow>
                <div>Total Registraion for the past 30 days</div>
                <div>{stat["1 MONTH"]}</div>
              </UserStatRow>
              <UserStatRow>
                <div>Total Registraion for the past 3 Months</div>
                <div>{stat["3 MONTHS"]}</div>
              </UserStatRow>
            </UserStatBox>
            <UserStatTotal>
              <FeatherIcon icon="users" size="2rem" />
              <h3>TOTAL REGISTERED USERS</h3>
              <h1>{stat["TOTAL REGISTRATIONS"]}</h1>
            </UserStatTotal>
          </UserStat>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: " 2rem",
              justifyContent: "space-between",
            }}
          >
            <FilterUsers value={role} onChange={(e) => setRole(e.target.value)}>
              <option>Filter Users</option>
              {tablist.map((item) => (
                <option key={item.id} value={item.type}>
                  {item.name}
                </option>
              ))}
            </FilterUsers>

            <div className="player-search">
              {/* <PlayerSearch type='search'
                    placeholder='Search by email, name' value={searchTerm} 
                             onChange={({target})=>setSearchTerm(target.value)} /> */}

              <PlayerSearch
                type="search"
                style={{ display: "block", width: "300px", maxWidth: "100%" }}
                placeholder="Search by email, name, userID"
                value={searchTerm}
                onChange={({ target }) => setSearchTerm(target.value)}
              />
            </div>
          </div>
          <TabContent>
            <div className="players-table" style={{ padding: "2rem" }}>
              {loading && <Loading />}

              {!loading && (
                <>
                  <h3 style={{color:'var(--humber-light)'}}>Total: {players?.length}</h3>
                  <br />
                  <table className="players">
                    <tbody>
                      <tr className="tr_deposites">
                        <th>ID</th>
                        <th>Username</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Registered Date</th>
                        <th>Action</th>
                      </tr>

                      {paginatedPlayers?.map((player, i) => (
                        <tr key={i} className="tr_deposites">
                          <td>{player.id}</td>
                          <td className="points">{player.username}</td>
                          <td>
                            <div className="user-avatar">{player.name}</div>
                          </td>

                          <td className="points">{player.email}</td>

                          <td className="points">{player.phone}</td>
                          <td className="points">{player.createdAt}</td>
                          <td>
                            <div className="action-btn">
                              <Link
                                to={{
                                  pathname: `/player-details/${player.email}`,
                                  data: player.phone, // your data array of objects
                                }}
                              >
                                <FeatherIcon icon="eye" />
                              </Link>
                              <div
                                className={
                                  !player.blocked === false
                                    ? "disable"
                                    : "activate"
                                }
                                onClick={() => confirmDeactivate(player)}
                              >
                                {" "}
                                {!player.blocked === false
                                  ? "Disabled "
                                  : " Active"}
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </div>
          </TabContent>
          <div style={{ justifyContent: "center", display: "flex" }}>
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={PageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
          {/* <ReactPaginate
                        previousLabel={"previous" }
                        nextLabel={ "next" }
                        breakLabel={ "..." }
                        breakClassName={ "break-me" }
                        pageCount={ pageCount }
                        onPageChange={ handlePageClick }
                        containerClassName={ "pagination" }
                        subContainerClassName={ "pages pagination" }
                        activeClassName={ "active" } /> */}
        </PlayerContainer>
      </ContentWrapper>
      <Toaster position="top-center" />
    </DashboardContainer>
  );
};

export default Player;
