import React, {useState} from 'react'
import { CustomModal, OnboardingWrapper } from '../component'
import { Input, Label, PlayerForm } from '../pages/dashboard/players-styles/players';
import axios from 'axios'
import toast,{ Toaster } from 'react-hot-toast';
import { isAuthenticated } from '../utils/data';

const ResolvePaymentModal = ({showModal, setShowModal}) => {

  const {
    obj: { token },
  } = isAuthenticated();

 const [values, setValues] = useState({
   email:'',
   processor:"paystack",
   reference:"",
   errors:{
     emailError:'',
     referenceError:''
   }
 })

 const [loading, setLoading] = useState(false)

 const {email, processor, reference, errors} =values
  

  const handleChange = name => e=>{
    setValues({...values, errors:{}, [name] : e.target.value})
  }
 
  const resolvePayment = async(e)=>{
    e.preventDefault();
    setLoading(true);
   if(!email){
     setLoading(false);
     setValues({...values, errors:{emailError:'Email is requred'}})
   }else if (!reference){
    setLoading(false);
    setValues({...values, errors:{referenceError:'Reference is required'}})
   }else{

    try{
      
      let payload={   
         email:email,     
         reference:reference, 
         processor: processor,    
         
        }


        console.log("PAYLOAD", payload)
    
       const {data}  = await axios.post(`${process.env.REACT_APP_API_URL}/payment/api/wallet-top/admin-resolve-payment`, payload, {
        headers: {
          Authorization: ` Bearer ${token}`,
        },
       })
        console.log("RESPONSE", data)

         if(data?.status ===1){
             
           toast.error(data?.description);
            setLoading(false)
         }else{
           
           setLoading(false);
           toast.sucess(data?.description);
         }
 
      }catch(error){
         
       if (error instanceof Error) {
         if(error.message === 'Network Error'){
           toast.error('Please check your network connection!');
           setLoading(false)  
          }else{
           toast.error(error.response.description);
          setLoading(false)
          }
   
        }else{
         toast.error(error.response.description);
        }
       
      }

   }
  }




    return (
      <>
      {showModal && (
          <CustomModal setShowModal={setShowModal}>
             <PlayerForm style={{border: "2px solid #d3d3d3"}}>   
             <h3>{'Resolve Payment ' }</h3>
             <OnboardingWrapper.FormGroup >
               <Label>Email</Label>
                 <Input type="text" value={email}  placeholder="Enter email" onChange={handleChange('email')} />
                 <OnboardingWrapper.Error>{errors?.emailError}</OnboardingWrapper.Error>
               </OnboardingWrapper.FormGroup>
             <OnboardingWrapper.FormGroup  >
               <Label>Payment ReferenceID</Label>
                 <Input type="text" value={reference}  placeholder="Enter refernce" onChange={handleChange('reference')} />
                 <OnboardingWrapper.Error>{errors?.referenceError}</OnboardingWrapper.Error>
               </OnboardingWrapper.FormGroup>
                   
               <OnboardingWrapper.Button onClick={resolvePayment}>
               {loading? <small>Resolving...</small> : "Send"}
               
               </OnboardingWrapper.Button > 
                 
              </PlayerForm>
      

         
 </CustomModal>  
)}
 
 <Toaster position='top-center' />
</>

)

}



export default ResolvePaymentModal
