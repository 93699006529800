import React, {useState, useEffect} from 'react'
import FeatherIcon from "feather-icons-react";


const GuessOption = ({
   setOptions,
  options,
  checkDrawDate,
  currentTime
  }) => {
    const [inputList, setInputList] = useState([{ optionText: "", isCorrectAnswer: "NO" }]);
 
    // handle input change
    const handleInputChange = (e, index) => {
      const { name, value } = e.target;
      const list = [...inputList];
      list[index][name] = value;
      setInputList(list);
      setOptions(list)
    }; 

    console.log('NEW INPUTSSS', inputList)
   
    // handle click event of the Remove button
    const handleRemoveClick = index => {
      const list = [...inputList];
      list.splice(index, 1);
      setInputList(list);
      setOptions(inputList)
    };
   
    // handle click event of the Add button
    const handleAddClick = () => {
      setInputList([...inputList, { optionText: "", isCorrectAnswer: "NO" }]);
    };

    useEffect(()=>{
        if(options?.length > 0){
            setInputList(options); 
        }
    },[])
    
    return (
        <div className="App">
         
          {inputList.map((x, i) => {
            return (
              <div className='answer-options-list' key={i}>
                <input  className="option-input"
                  name="optionText"
                      placeholder="Enter Answer option"
                  value={x.optionText}
                  onChange={e => handleInputChange(e, i)}
                />
            

                 <select value={x.isCorrectAnswer} disabled={currentTime < checkDrawDate ? true : false} name="isCorrectAnswer" className="option-select"  onChange={e => handleInputChange(e, i)}>
                     <option value='YES'>
                               YES
                     </option>
                     <option value='NO'> 
                               NO
                     </option>
                 </select>
                <div>
                  {inputList.length !== 1 && <div className='cat-action-btn'
                    onClick={() => handleRemoveClick(i)} >
                   <FeatherIcon
                   icon="trash-2"
                   size="15px"
                   color="red" /> 
                   </div>}
                 
                </div>
                <div>
                {inputList.length - 1 === i && <button className='add-option-btn' onClick={handleAddClick}>
                 <FeatherIcon icon='plus'  />   Add Option</button>}
                </div>
              </div>
            );
          })}
          {/* <div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div> */}

         
        </div>
      );
}

export default GuessOption
