import React,{useContext} from 'react'
import { Menu, MenuWrapper, List, ListRoute } from './styles/mobile-menu';

import logo from '../../assets/images/biggies-logo.svg'
import {useHistory} from 'react-router-dom';
// import { Context } from '../../context';
import { signout } from '../../utils/data'
import FeatherIcon from 'feather-icons-react'



const SidebarMenu = ({toggle, setToggle}) => {

  
    // const {state, dispatch}  = useContext(Context);

    const logout = () => {

        // dispatch({
        //   type:'LOGOUT',
        
        // })
        signout(()=>{    
          history.push('/');
        });     
     }
     
     ;

    const history = useHistory();
    return (
      
           
             <Menu toggle={toggle}>
              

                {toggle && (
                    <MenuWrapper>
                        <div style={{display:'flex', justifyContent:'space-between'}}>
                            <div><img src={logo} alt='' width='100px' height='40px'/></div> <div><FeatherIcon icon='x-circle' color='rgb(0,80,157)' size='25px'   onClick={()=>setToggle(false)}/></div>
                        </div>
                         
                        
                         <List>
                                <ListRoute onClick={()=>{
                                    setToggle(false); history.push('/dashboard')
                                }}><FeatherIcon icon='home'  /> Dashboard</ListRoute>
                                
                                <ListRoute  onClick={()=>{setToggle(false);  history.push('/games')}}><FeatherIcon icon='github' className='rotate-icon' /> Games</ListRoute>
                                <ListRoute onClick={()=>{setToggle(false);  history.push('/players')}}><FeatherIcon icon='users' />Users</ListRoute>
                                {/* <ListRoute  onClick={()=>{setToggle(false);  history.push('/reports')}}><FeatherIcon icon='send'  /> Reports</ListRoute> */}
                                <ListRoute  onClick={()=>{setToggle(false);  history.push('/rewards')}}><FeatherIcon icon='activity'  /> Rewards</ListRoute>
                                <ListRoute  onClick={()=>{
                                    setToggle(false); history.push('/transactions')
                                }}><FeatherIcon icon='credit-card' /> Deposit</ListRoute>
                                  <ListRoute  onClick={()=>{
                                    setToggle(false); history.push('/profile')
                                }}><FeatherIcon icon='user' /> Profile</ListRoute>
                                <ListRoute  onClick={()=>{
                                    setToggle(false); history.push('/settings')
                                }}><FeatherIcon icon='settings'  />Settings</ListRoute>
                             
                                <ListRoute  onClick={()=>logout()}><FeatherIcon icon='log-out'  /> logout</ListRoute>
                                
                         </List>

                        
                        
                    </MenuWrapper>
                )}
             
             </Menu>
           
        
    )
}

export default SidebarMenu