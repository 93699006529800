import React, {useState} from 'react'
import { ContentWrapper, OnboardingWrapper } from '../../component'
import DashboardContainer from '../../container/dashboardContainer'
import FeatherIcon from 'feather-icons-react'
import { Button, HeaderWrapper, Input,Label, PlayerForm,Select, PlayerContainer, PlayerHeader } from './players-styles/players'
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { isAuthenticated } from '../../utils/data'

const Profile = () => {
  
    const history = useHistory()

    const addPlayer = ()=>{
        history.push('/dashboard')
    }

  const {obj: {token, name,email,phone, username}} = isAuthenticated()
 
    const [values, setValues] = useState({
        username1:'',
        userEmail:'',
        fullname:'',
        userPhone:'',
        
    
        errors:{
          usernameError:'',
          emailError:'',
          nameError:'',
          phoneError:'',
          roleError:'',
        
        }
    
      });
    



      const [loading, setLoading] = useState(false);
    
      const {userEmail, username1, userPhone,  fullname, errors} = values;
    
        const handleChange = name => e=>{  
    
        setValues({...values, errors:{}, [name]: e.target.value})
      }
    
      
      const updateProfile = async(e)=>{
    
        e.preventDefault();
    
        setLoading(true);
        console.log('Values', values)
        if(!email){
           setValues({...values, errors:{emailError: 'Email is required'}})
           setLoading(false);

    
       }else if(!name){
           setValues({...values, errors:{nameError: 'Name is required'}})
           setLoading(false);
    
       }
      else if(!phone){
        setValues({...values, errors:{phonedError: 'Phone is required'}})
        setLoading(false);
 
      }
 
       else{
    
         try{
           
           let payload={   
            username:username1,
            email: userEmail,
            name: fullname,
            phone:userPhone,
               
              
             }
    
    
             console.log("PAYLOAD", payload)
         
            // const {data}  = await axios.post(`https://api.patricia.humbergames.com/user/auth/admin/create-user`, payload,{
            //   headers:{
            //     "Authorization" : `Bearer ${token}`
            //   }
            // })
            //  console.log("RESPONSE", data)
    
            //   if(data?.status ===1){
                  
            //     toast.error(data?.desc);
            //      setLoading(false)
            //   }else{
            //     if(data?.status ===0){
            //       toast.success(`Created successful!`);
                
            //     setLoading(false)
            //     }
                
            //   }
      
           }catch(error){
              
            if (error instanceof Error) {
              if(error.message === 'Network Error'){
                toast.error('Please check your network connection!');
                setLoading(false)  
               }else{
               
               setLoading(false)
               }
        
             }
           }
    
        }
        
    
     }

    return (
        <DashboardContainer>
        <ContentWrapper style={{ height: '100vh'}}>
               <PlayerContainer>
               
                    <HeaderWrapper  gap={'30px'} justifyContent ="flex-start">
                    <FeatherIcon type="button" onClick={()=>history.goBack()} style={{marginBottom:20, color:'var(--humber-golden)'}} icon="arrow-left" />
                    <PlayerHeader color="var(--humber-golden)">
                        GAME SETTINGS - TRIVIA
                    </PlayerHeader>
                    {/* <Button onClick={addPlayer}><FeatherIcon icon='plus' /> Dashboard</Button> */}
                    </HeaderWrapper>
                    <div className='player-form'>
                    <PlayerForm>
                        
                        
                    
                       <OnboardingWrapper.FormGroup >
                       <Label>Token Requirement</Label>
                        <Input type="email" value={email} placeholder="Email address" onChange={handleChange('email')} />
                        <OnboardingWrapper.Error>{errors?.emailError}</OnboardingWrapper.Error>
                      </OnboardingWrapper.FormGroup>
                     
                      <OnboardingWrapper.FormGroup >
                      <Label>Coin to be Awarded</Label>
                        <Input type="text"  value={name} placeholder="Full Name" onChange={handleChange('name')}  />
                        <OnboardingWrapper.Error>{errors?.nameError}</OnboardingWrapper.Error>
                      </OnboardingWrapper.FormGroup>

                      <OnboardingWrapper.FormGroup >
                      <Label>Maximum Questions per Day</Label>
                        <Input type="number" value={phone}  placeholder="Phone" onChange={handleChange('phone')}  />
                        <OnboardingWrapper.Error>{errors?.phoneError}</OnboardingWrapper.Error>
                      </OnboardingWrapper.FormGroup>


                   
                     
                       <OnboardingWrapper.Button onClick={updateProfile}>{loading? <small>Loading...</small> : "Save changes"}</OnboardingWrapper.Button>
                       
                        
                     </PlayerForm>
                                        
                    </div>
               </PlayerContainer>
        </ContentWrapper>
        <Toaster position='top-center'/>
    </DashboardContainer>
    )
}

export default Profile

