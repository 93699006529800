import styled from 'styled-components/macro'

export const Container = styled.div`
    height: 14px;
    width: 100%;
    background: #e0e0de;
    border-radius: 50px;
    margin: 1px;
`

 export const Filler = styled.div`
    height: 100%;
    width: ${({completed})=> completed}%;
    /* background: ${({bgcolor})=> bgcolor}; */
    border-radius: inherit;
    text-align: right;
    /* height: 100%;
    width: 35%;
    border-radius: inherit;
    text-align: right; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 10px;
    padding-right: 1rem;
    background:${({success})=>success  ? '#04972d' : '#00509d'};
    transition: width 1s ease-in-out;
  `


  export const Label = styled.span`
    padding: 5;
    color: white;
    font-weight: bold;
    `
