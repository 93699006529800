import React from 'react'
import { Container,Row, Column, Header, Text, Button, PlayStore, Icon, IconContent, IconText, Small } from './styles/hero-section'

const HeroSection = ({children, ...resProps}) => {
    return (
        <Container {...resProps}>
            {children}
        </Container>
    )
}

export default HeroSection

HeroSection.Row = function HeroSectionRow({children, ...resProps}){

    return <Row {...resProps}>{children}</Row>
}
   
HeroSection.Column = function HeroSectionColumn({children, ...resProps}){

    return <Column {...resProps}>{children}</Column>
}
   
HeroSection.Header = function HeroSectionHeader({children, ...resProps}){

    return <Header {...resProps}>{children}</Header>
}

HeroSection.Text = function HeroSectionText({children, ...resProps}){

    return <Text {...resProps}>{children}</Text>
}
   

HeroSection.Button = function HeroSectionButton({children, ...resProps}){

    return <Button {...resProps}>{children}</Button>
}

HeroSection.PlayStore = function HeroSectionPlayer({children, ...resProps}){

    return <PlayStore {...resProps}>{children}</PlayStore>
}

HeroSection.Icon = function HeroSectionIcon({children, ...resProps}){

    return <Icon {...resProps}>{children}</Icon>
}

HeroSection.IconContent = function HeroSectionIconContent({children, ...resProps}){

    return <IconContent {...resProps}>{children}</IconContent>
}


HeroSection.IconText = function HeroSectionIconText({children, ...resProps}){

    return <IconText {...resProps}>{children}</IconText>
}

HeroSection.Small = function HeroSectionSmall({children, ...resProps}){

    return <Small {...resProps}>{children}</Small>
}
 
 
  
 
    



