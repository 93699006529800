import styled from 'styled-components/macro'


export const Container = styled.div`
 display:flex;
  gap:3rem;
 padding:2.5rem 3rem;
 background-color:var(--humber-dark);
 /* background-image:url('./gulder_assets/admin_bg.png'); */
 background-repeat:no-repeat;
 background-position: right center;
 background-size: contain;
 ${'' /* height: 100vh; */}
 background-attachment: fixed;
 @media (max-width:1000px){
        flex-direction: column; 
    }

 @media (max-width:600px){
    padding: 2rem 1rem;
 }   
  
`

export const Main = styled.div`
   
    /* flex: 1; */
    width: 65%;
    @media (max-width:1024px){
        width: 100%; 
    }
  
`
export const LeftBar = styled.div`
display: flex;
flex-direction: column;
   background: #fff;
   padding: 1.5rem;
    max-width: 320px;
    border-radius: 10px;

    @media (max-width:1024px){
        max-width: 100%;
    }
  
    @media (max-width:600px){
        padding: 1.5rem .8rem;
    }
   
`

