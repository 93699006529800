import styled from "styled-components/macro";

export const ReserveContainer = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: ${(props) => (props.padding ? props.padding : "0px")};
  max-height: 219px;
  gap: 12px;
  background: red;
  border-radius: 10px;
  background: #b9cee3;
  flex-direction: column;
`;

export const ProgressBar = styled.div`
  background-color: black;
  display: flex;
  


`;

export const ContentWrapperreserve = styled.div`
display: flex;
flex-direction: column;
gap:4px


`
