import styled from 'styled-components/macro'

export const Container = styled.div`

display: flex;
flex-direction: column;
align-items: center;
padding: 0;
box-sizing: border-box;
margin: 0;
width: 100%;

`


export const Row = styled.div`
display: flex;
align-items: center;
width:100%;
max-width:1300px;
height: 80vh;


`

export const Column = styled.div`
width: 100%;
max-width: 450px;
/* padding: 0 2rem; */
    

`

export const Header = styled.h1`
font-size: 3.5rem;
color:#00509D;

`

export const Text = styled.p`


`


export const Button = styled.div`
width: 200px;
height: 40px;
border: none;
border-radius: 30px;
background: #00509D;
color: #fff;
margin-top: 2.5rem;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
`

export const PlayStore = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 135px;
border: 2px solid #00509D;
padding: 1px 5px;
color: #00509D;
border-radius: 8px;
margin-top: 5rem;
padding: .3rem .6rem;
margin-right: .8rem;
cursor: pointer;

&:last-of-type{
    margin-right: 0;
}
`

export const Icon = styled.div`
.svg-inline--fa{
    font-size: 28px;
    margin-right: .3rem;
}
`

export const IconContent = styled.div``

export const Small = styled.small`
 font-size: 60%;
font-weight: 600;`

export const IconText = styled.div`
font-size: 17px;
font-weight: 600;
margin-top: -3px;
`