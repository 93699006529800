import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'normalize.css';
import { GlobalStyles} from './globalStyles'
import {Provider} from './Context'


ReactDOM.render(
  <Provider>
    <App />
    <GlobalStyles />
  </Provider>,
  document.getElementById('root')
);


