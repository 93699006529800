import React from 'react'
import { Container, Title, Content , Row, Column, Price, Subtitle} from './styles/accordion'

const Accordion = ({children, ...resProps}) => {
    return (
        <Container {...resProps}>
              {children}
        </Container>
    )
}

export default Accordion



Accordion.Title = function AccordionTitle({children, ...resProps}){
    return <Title {...resProps}>{children}</Title>
}

Accordion.Content = function AccordionContent({children, ...resProps}){
    return <Content {...resProps}>{children}</Content>
}

Accordion.Row = function AccordionRow({children, ...resProps}){
    return <Row {...resProps}>{children}</Row>
}

Accordion.Column = function AccordionColumn({children, ...resProps}){
    return <Column {...resProps}>{children}</Column>
}

Accordion.Price = function AccordionPrice({children, ...resProps}){
    return <Price {...resProps}>{children}</Price>
}

Accordion.Subtitle = function AccordionSubtitle({children, ...resProps}){
    return <Subtitle {...resProps}>{children}</Subtitle>
}