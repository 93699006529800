import React from 'react'
import {Container,Bg, Row, Title, Text, SubTitle, Item,SmallText, Content,PlayNowBtn, Wrapper, ButtonGroup, Button, IconBox, Column, Ul, Li, Footer} from './styles/card'


const Card = ({children, ...resProps})=> {
    return (
       <Container  {...resProps}>{children}</Container> 
    )
}

export default Card

Card.Bg = function CardBg({children, ...resProps}){
    return <Bg  {...resProps}>{children}</Bg>
}


Card.Wrapper = function CardWrapper({children, ...resProps}){
    return <Wrapper  {...resProps}>{children}</Wrapper>
}

Card.Row = function CardBody({children, ...resProps}){
    return <Row  {...resProps}>{children}</Row>
}

Card.Item = function CardItem({children, ...resProps}){
    return <Item  {...resProps}>{children}</Item>
}

Card.Content = function CardContent({children, ...resProps}){
    return <Content  {...resProps}>{children}</Content>
}

Card.Column = function CardColumn({children, ...resProps}){
    return <Column  {...resProps}>{children}</Column>
}

Card.Title = function CardTitle({children, ...resProps}){
    return <Title {...resProps}>{children}</Title>
}
Card.SubTitle = function CardSubTitle({children, ...resProps}){
    return <SubTitle {...resProps}>{children}</SubTitle>
}
Card.Text = function CardText({children, ...resProps}){
    return <Text {...resProps}>{children}</Text>
}
Card.SmallText = function CardSmallText({children, ...resProps}){
    return <SmallText {...resProps}>{children}</SmallText>
}
Card.Ul = function CardUl({children, ...resProps}){
    return <Ul {...resProps}>{children}</Ul>
}
Card.Li = function CardLi({children, ...resProps}){
    return <Li {...resProps}>{children}</Li>
}

Card.IconBox = function CardIconBox({children, ...resProps}){
    return <IconBox {...resProps}>{children}</IconBox>
}

Card.Footer = function CardFooter({children, ...resProps}){
    return <Footer  {...resProps}>{children}</Footer>
}


Card.ButtonGroup = function CardButtonGroup({children, ...resProps}){
    return <ButtonGroup  {...resProps}>{children}</ButtonGroup>
}
Card.PlayNowBtn = function CardPlayNowBtn({children, ...resProps}){
    return <PlayNowBtn {...resProps}>{children}</PlayNowBtn>
}

Card.Button = function CardButton({children, ...resProps}){
    return <Button {...resProps}>{children}</Button>
}

