import styled from 'styled-components/macro'


export const Menu = styled.div`

    position:fixed; z-index:9999; height:100vh; 
    background: #f9f9f938;

    width:100%;
    height: 100%;
    top: 0;
    left: 0;
    left: ${({toggle})=>(toggle? '0': "-100%")};
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition: .3s ease all;
    transition: .3s ease all;
    display:none;

    @media (max-width: 768px){
      display:flex;
      flex-direction: column;
    }
   

`

export const MenuWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem 1.5rem;
    height: 100vh;
    background: #02023b;
    width: 60%;

`

export const List = styled.div`
display:flex;
flex-direction: column;
padding-top:2rem;
color: #fff;



`

export const ListRoute = styled.div`
display: flex;
align-items: center;
gap: 20px;
font-size: .9rem;
color: #fff;
padding: 1rem 0;
border-bottom: 1px solid #a09f9f26;
/* &:last-of-type{
  border-bottom: 1px solid transparent;
    color: #f2ecec;
    margin-top: 2rem;
    width: 100px;
    height: 5px;
    background: #db0202;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
} */


`