import React, { useState, useEffect, Fragment } from "react";
import {
  ContentWrapper,

} from "../../component";
import DashboardContainer from "../../container/dashboardContainer";
import FeatherIcon from "feather-icons-react";
import { useHistory } from "react-router-dom";
import {
  Button,
  HeaderWrapper,

  PlayerContainer,

  PlayerHeader,

} from "./players-styles/players";
import { isAuthenticated } from "../../utils/data";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { Loading } from "../../container/loading";
import {
  GamesButton,
  GamesColumn,
  GamesContainer,
  GamesRow,
  GamesTitle,
} from "./games-styles/games-styles";
import {
  ModalColumn,
  ModalContent,
  ModalHeader,
  ModalRow,
  ModalTitle,
} from "../../component/custom-modal/styles/custom-modal";
import { Label } from "../../component/form/styles/form";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { applyFilters } from '../../utils/filter'
import ReactPaginate from "react-paginate";

const GuessSession = () => {
  let history = useHistory();
  const {
    obj: { token },
  } = isAuthenticated();
  const [showModal, setShowModal] = useState(false);
  const [allActivities, setActivitiesList] = useState();
  const [updateCat, setUpdateCat] = useState(false);
  const [cat, setCat] = useState();
  const [updateDom, setUpdateDom] = useState(false);
  const [catId, setCatId] = useState();
  const [count, setCount] = useState('');
  

  const [spinner, setSpinner] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [pageLimit, setPageLimit] = useState(15);
  const PageCount = Math.ceil(count / pageLimit);

  let start = moment(startDate).format();
  let end = moment(endDate).format();

  console.log("start", catId);



 
const changePage = ({ selected }) => {
  getActivitiesList(selected)
}
  

  const [loading, setLoading] = useState(false);

  const getActivitiesList = async (page) => {



    const filter = {
      page: page ? page  : currentPage,
      size: pageLimit,
      orderDirection: "DESC",
      orderColumn: "id",
    };

    const URL = applyFilters(
      `${process.env.REACT_APP_API_URL}/games/guess-game-answer`,
      { ...filter }
    );
    setLoading(true);
    try {
      const { data } = await axios.get(
        `${URL}`,
        {
          headers: {
            Authorization: ` ${token}`,
          },
        }
      );

      console.log("Guess Game Session", data);

      if (data) {
        setLoading(false);
        setCount(data?.obj?.count)
        setActivitiesList(
          data?.obj?.guessUserAnswers
        );
      }
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === "Network Error") {
          toast.error("Please check your network connection and try again!");
          setLoading(false);
        } else {
          toast.error("System Error!");
          setLoading(false);
        }
      }
    }
  };
  useEffect(() => {
    getActivitiesList()
  },[])
  

 



  return (
    <DashboardContainer>

      <ContentWrapper>
        <PlayerContainer>
          <HeaderWrapper>
            <PlayerHeader>
              Guess Activities
              <div onClick={() => history.push("/games")}>
                <FeatherIcon icon="arrow-left" />
                All Games
              </div>
            </PlayerHeader>
            <Button >
              Total:{count}
            </Button>
          </HeaderWrapper>
          <GamesContainer>
            <div className="players-table">
              {spinner && (
                                     <Loading />
                                   )}  

                                   {loading && (
                                     <Loading />
                                   )}  

              {!loading && (
                <table className="players">
                  <tbody>
                    <tr>
                      <th>Game ID</th>
                      <th>Game Type</th>
                    
                      <th>Result</th>
                      <th>Coin Awarded </th>

                      <th>Date</th>
                    </tr>
                   
                    {allActivities?.map((activity, i) => (
                      
                      <tr key={i}>
                        <td>{activity.id}</td>
                        <td className="points">{activity?.guess?.answerType}</td>

                      

                        <td>
                          <div className="user-avatar" style={{color:activity?.wasCorrectAnswer == 'YES' ? '#06e12b' : activity?.wasCorrectAnswer == null ? 'gray' : 'red'}}>
                          {activity?.wasCorrectAnswer === 'YES' ? 'WON' :  activity?.wasCorrectAnswer == null ? 'PENDING' : 'LOSE'}
    
                          </div>
                        </td>
                        <td>
                          <div className="user-avatar">
                            {activity?.coinAward } Coin Awarded
                          </div>
                        </td>

                        <td className="points">
                          <div className="cat-action-btn">
                           {moment(activity.createdTime).format("LLL")}  
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
            <div style={{ justifyContent: "center", display: "flex" }}>
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={PageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
          </GamesContainer>
        </PlayerContainer>
      </ContentWrapper>
      <Toaster position="top-center" />
    </DashboardContainer>
  );
};

export default GuessSession;
