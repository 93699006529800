import styled from 'styled-components/macro'

export const Modal = styled.div`
position: fixed;
width: 85%;
height: 100vh;
background: #3d3d3d80;
/* background: red; */
display: flex;
justify-content: center;
z-index: 999;



@media (max-width:1024px){
      width:70%;
     
    }

@media (max-width:1000px){
  
    width: 73%;
 
  
}

 @media (max-width:768px){
     width: 100%;
     align-items: center;
 }

`

export const ModalBody = styled.div`
  position:relative;
display: flex;
/* display: contents; */
/* justify-content: center; */
align-items: center;
flex-direction:column;
/* margin-top:2rem; */
height: 100vh;
/* width: 70%; */

@media (max-width:768px){
  overflow: scroll;
  min-height: 100%;
 
}

@media (max-width:600px){
  padding: 0;
    }

animation: zoom-in-zoom-out 1s ease ;

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}

.cancel{
    position: absolute;
    top: 0;
    right: 0;
    padding: 0rem;
    color: #fff;
    fill:var(--humber-secondary);
    width: 40px;
    height: 40px;
    cursor: pointer;

}
@media (max-width:600px){

    /* margin-top: -7rem; */
}

`




export const ModalRow = styled.div`
display: flex;
gap: 30px;

min-width: 500px;
  @media (max-width:600px){
  flex-direction: column;
  min-width: 200px;
}


`

export const ModalContent = styled.div`
display:flex;
@media (max-width:600px){
      overflow: scroll;
      padding: 2rem;
    }
`

export const ModalItem = styled.div`
background: var(--humber-black);
border-radius: 10px;
box-shadow: 0 0 8px 8px rgb(0 0 0 / 9%);
padding: 4rem 2rem;
margin-top:1rem;
width: ${({width})=>width};
/* height: 100%; */
max-height: 450px;
overflow-y: scroll;
overflow-x: hidden;
border: 1px solid var(--humber-golden);
::-webkit-scrollbar {
  width: 10px;
}

`
export const ModalColumn = styled.div`
    padding: 2rem 0;
    border-radius: 10px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
    flex: 1 1 300px;
    border: 1px solid var(--humber-golden);
    cursor: pointer;
  @media (max-width:600px){
    flex: 1 1 200px;
  }

`

export const ModalHeader = styled.div`
text-align: center;
font-size: 1.5rem;
font-weight: 600;
margin: 1rem;
color: var(--humber-golden);

@media(max-width:600px){
  font-size: 1.2rem;
}

`

export const ModalTitle = styled.div`
font-size: 1.2rem;
`