import styled from 'styled-components/macro';


export const Container = styled.div`
display:flex;
align-items:center;
justify-content:center;
padding:2rem 0;
`

export const Body = styled.div`
width: 100%;
color: #949494;
justify-content: center;
max-width: 1200px;

`

export const Row = styled.div`

  font-family: poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: justify;
    white-space: pre-line;
  

`

export const Title = styled.h3`
color:${({color})=>color};
`
export const Subtitle = styled.h4`
border-bottom: ${({borderbottom})=>borderbottom};
`
export const Text = styled.p`
font-family: poppins;
`