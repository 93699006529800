import React, {useState, useEffect} from 'react'
import { ContentWrapper, CustomModal } from '../../component'
import DashboardContainer from '../../container/dashboardContainer';
import axios from 'axios'
import FeatherIcon from 'feather-icons-react' 
import {useHistory, Link} from 'react-router-dom'
import toast, {Toaster} from 'react-hot-toast'
import { OverviewColumn, OverviewHeader, OverviewContainer, OverviewInner, OverviewRow, OverviewTitle } from './user-dashboard-styles/user-dashboard-styles';
import { TableContainer } from './players-styles/players';
import {Loading, Loading2} from '../../container/loading';
import { Button, HeaderWrapper, PlayerColumn, PlayerContainer, PlayerHeader, PlayerSearch } from './players-styles/players';
import moment from 'moment'
import { isAuthenticated } from '../../utils/data';
import { GameColumn, GameDetailsWrapper, GameHeader, GameRow, GamesButton, GamesColumn, GameSmall } from './games-styles/games-styles';
import EditReward from './edit-reward';


const ManageRewards = () => {
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false)
    const [showDeleteModal,setShowDeleteModal] = useState()
    const [rewards, setRewards] = useState([])
    const [itemDetail, setItem]= useState({});
    const [update, setUpdate] = useState();
    const [selectedItem, setSelectedItem] = useState()
    const {obj:{ token}} = isAuthenticated();
  

    const history = useHistory()

        
            

 const getRewardList = async() =>{
    setLoading(true);
    try{
      const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/payment/api/rewards/?size=100`, {
        headers:{
            "Authorization" : ` Bearer ${token}`
          }
      });
      setLoading(false);
      setRewards(data?.obj?.rewards?.sort((a,b) => new Date(a) < new Date(b) ? 1 : -1)) 
    }catch(error){
        setLoading(false);
    }
 }


 const handleDetail = (item)=>{
   setItem(item);
   if(itemDetail){
    setShowModal(true)
   }
 }

const confirmDelete=(item)=>{
    setSelectedItem(item);
    setShowDeleteModal(true)
}


 const deletePrice = async(id)=>{
    setLoading(true);
    try{
      const {data} = await axios.delete(`${process.env.REACT_APP_API_URL}/payment/api/rewards/delete/${id}`, {
        headers:{
            "Authorization" : ` Bearer ${token}`
          }
      });
      setLoading(false);
      setShowDeleteModal(false)
      getRewardList();
    }catch(error){
        setLoading(false);
    }
 }

 
 
 useEffect(()=>{
    getRewardList()
 }, [update])
    return (
        <DashboardContainer>  
            <EditReward item={itemDetail} setUpdate={setUpdate} showModal={showModal} setShowModal={setShowModal} />
            
            {showDeleteModal && (
        <CustomModal setShowModal={setShowDeleteModal}> 
          <GameDetailsWrapper>
            <GameRow>
              <GameColumn>
                <FeatherIcon icon="info" color="#ed971" />
                <GameHeader style={{color:'var(--humber-light)'}}>Are you Sure!</GameHeader>
                <GameSmall>
                  You want to delete <span style={{color:'var(--humber-golden)'}} >{selectedItem?.name}</span>
                </GameSmall>
              </GameColumn>
            </GameRow>
            <GameRow>
              <GamesButton onClick={()=>deletePrice(selectedItem?.id)}>Yes</GamesButton>
              <GamesButton
                color="rgb(0 80 157)"
                bgColor="#fff"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </GamesButton>
            </GameRow>
          </GameDetailsWrapper>
        </CustomModal>
      )}
            
             <ContentWrapper >
               <PlayerContainer >
               <OverviewContainer>
                  <OverviewHeader>Manage Rewards</OverviewHeader>
                  <GamesButton  onClick={()=>history.push('/add-reward')}>
                     <FeatherIcon icon="plus" size="15px" /> 
                     Add Reward  
                </GamesButton>
                 <TableContainer>
                 
                <br/>

                {loading ? <Loading/> :
                 <table className='reward-table'>
                     <tbody>
                    <tr>
                        <th>Reward Ref</th>
                        <th>Reward Image</th> 
                        <th>Reward Name</th>
                        <th>Amount</th>
                        <th>Item Instock</th>
                        <th>Reward Available</th>
                        <th>Point Value</th>
                        <th>Coin Value</th>
                        <th>Created Date</th>
                      
                        <th>Action</th>
                    </tr>
                    {rewards?.map((reward, i)=>(
                    <tr key={i}>     
                        <td>{i + 1}</td>
                        <td><img src={reward.imageUrl} alt={reward.name} /></td>
                        <td>{reward.name}</td>
                        <td>{reward.currency} {(reward.rewardAmount).toLocaleString()}</td>
                        <td >{reward.inStock}</td>
                        <td style={{color : reward.isAvailable==="YES"?'#008045':'red'}}>
                            {reward.isAvailable}</td>
                        <td>{(reward.pointValue).toLocaleString()} Points</td>
                        <td>{(reward.coinValue).toLocaleString()} Coins</td>
                        <td>{moment(reward.createdby).format('ll')}</td>
                        <td>
                        <div className='cat-action-btn'>
                          0064064717
                           <FeatherIcon icon='edit-3' onClick={()=>handleDetail(reward)}/>
                        <FeatherIcon icon='trash-2' onClick={()=>confirmDelete(reward)} />
                        </div>
                        </td>
                    </tr>
                      ))}
                      </tbody>  
                    </table>
                    }
                 </TableContainer>
             </OverviewContainer>
                    
                   
                
                    {/* < ReactPaginate
                        previousLabel={"previous" }
                        nextLabel={ "next" }
                        breakLabel={ "..." }
                        breakClassName={ "break-me" }
                        pageCount={ pageCount }
                        onPageChange={ handlePageClick }
                        containerClassName={ "pagination" }
                        subContainerClassName={ "pages pagination" }
                        activeClassName={ "active" } /> */}
               </PlayerContainer>
             
        </ContentWrapper>

        </DashboardContainer>
    )
}

export default ManageRewards

