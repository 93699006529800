import DashboardContainer from "../../container/dashboardContainer";
import { useEffect, useState } from "react";
import image from "../../assets/images/avatar.png";
import FeatherIcon from "feather-icons-react";
import {
  ContentWrapper,
  CustomModal,
  OnboardingWrapper,
  ProgressBar,
} from "../../component";
import { Container, ResultsRow, ResultColumn } from "./avatar-styles/results";
import {
  HeaderWrapper,
  PlayerContainer,
  PlayerHeader,
  Select
  // Container
} from "./players-styles/players";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { Loading } from "../../container/loading";
import toast, { Toaster } from "react-hot-toast";
import { isAuthenticated } from "../../utils/data";

const AvatarResults = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [allAvatar, setAllAvatar] = useState([]);
  const [activityId, setactivityId] = useState();
  const [loading1, setLoading1] = useState(false);
  const [activities, setActivities] = useState([]);
  const {
    obj: { token },
  } = isAuthenticated();

  useEffect(() => {
    getAllAvatars();
    // getScheduledActivity();
    getActivitiesList()
  }, []);

  const SubmitResults = async () => {
    setLoading1(true);

    const rest = allAvatar
      .filter((avatar) => avatar.points.length > 0)
      .map(({ id, points }) => ({
        avatarId: id,
        point: points,
      }));
    if (rest.length === 0) {
      toast.error("Please fill in the Points for each Avatar");
      setLoading1(false);
      return;
    }
    if(!activityId) {
      toast.error("Please Selected an activity");
      setLoading1(false);
      return;
    }
    const payload = {
      activityId: activityId,
      avatarPointList: rest,
    };


console.log(payload);
    
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/games/avatar-activity/points`,
        payload,
        {
          headers: {
            Authorization: ` ${token}`,
          },
        }
      );
      if (data) {
        setLoading1(false);
        console.log(data);
        toast.success("Points added successfully");
      }
    } catch (error) {
      setLoading1(false);
      if (error instanceof Error) {
        if (error.message === "Network Error") {
          toast.error("Please check your network connection and try again!");
        } else {
          toast.error("Failed to add points");
          setLoading(false);
        }
      }
    }
  };
  const getScheduledActivity = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/games/avatar-activity/scheduled`,
        {
          headers: {
            Authorization: ` ${token}`,
          },
        }
      );
      if (data) {
        setactivityId(data?.obj?.id);
        console.log(data?.obj);
      }
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === "Network Error") {
          toast.error("Please check your network connection and try again!");
        } else {
          // toast.error("Failed to retrieve Guess games");
          // setLoading(false);
        }
      }
    }
  };

  const selectActivity = (event) =>{
    // console.log(event.target.value)
    console.log(JSON.parse(event.target.value))
    const selected = JSON.parse(event.target.value)
    setactivityId(selected?.id)
    toast.success(`${selected?.activityTitle} selected` )

  } 
  const getActivitiesList = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/games/avatar-activity`,
        {
          headers: {
            Authorization: ` Bearer ${token}`,
          },
        }
      );

      console.log(data);
      setLoading(false);

      setActivities(
        data?.obj?.avatarActivities?.sort((a, b) =>
          new Date(a) < new Date(b) ? 1 : -1
        )
      );
    } catch (error) {
      setLoading(false);
    }
  };
  const getAllAvatars = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/games/avatar?size=1000`,
        {
          headers: {
            Authorization: ` ${token}`,
          },
        }
      );

      setLoading(false);
      if (data) {
        setLoading(false);
        console.log(data?.obj?.avatar);
        const sortedArray = data?.obj?.avatar?.sort((a, b) =>
          new Date(a) < new Date(b) ? 1 : -1
        );
        const avatars = sortedArray.map((item) => {
          const obj = Object.assign({}, item);
          obj["points"] = "";
          return obj;
        });

        setAllAvatar(avatars);
      }
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === "Network Error") {
          toast.error("Please check your network connection and try again!");
          setLoading(false);
        } else {
          toast.error("Failed to retrieve Guess games");
          setLoading(false);
        }
      }
    }
  };
  return (
    <DashboardContainer>
      <ContentWrapper>
        <PlayerContainer>
          <div>
            <FeatherIcon
              type="button"
              onClick={() => history.goBack()}
              color="var(--humber-light)"
              icon="arrow-left"
            />
          </div>
          <HeaderWrapper>
            <PlayerHeader color={"var(--humber-golden)"}>
              ENTER RESULTS
            </PlayerHeader>
            <Select onChange={(event)=>selectActivity(event)}   width="200px" color={"var(--humber-golden)"}>
              <option selected disabled>Select activity</option>
            
              {
                activities?.map((activity)=>(
                  <option value={JSON.stringify(activity)} >{activity?.activityTitle}</option>
                ))
              }
            </Select>
            {allAvatar.length > 0 ? (
              <OnboardingWrapper.Button
                style={{ margin: 0}}
                borderRadius="0"
                onClick={SubmitResults}
                
              >
                {loading1 ? "Submitting..." : "Submit"}
              </OnboardingWrapper.Button>
            ) : (
              <OnboardingWrapper.Button 
                style={{ margin: 0 }}
                borderRadius="0"
                onClick={() => history.push("/add-avatar")}
              >
                CREATE AVATAR
              </OnboardingWrapper.Button>
            )}
          </HeaderWrapper>
          {loading && <Loading />}

          {!loading && (
            <Container
              style={{
                padding: "45px",
                border: "1px solid var(--humber-light)",
                borderRadius: "11px",
              }}
            >
              {allAvatar.length == 0 && (
                <div className="d-flex justify-content-center">
                  <span style={{ color: "var(--humber-golden)" }}>
                    AVATAR LIST EMPTY, PLEASE ADD AVARTA
                  </span>
                </div>
              )}
              <ResultsRow>
                {allAvatar?.map((avatar, i) => (
                  <ResultColumn
                    style={{ marginBottom: "30px" }}
                    key={avatar?.id}
                  >
                    <ResultsRow
                      style={{
                        justifyContent: "flex-start",
                        gap: "25px",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <img style={{ width: "50px" }} src={avatar?.imgUrl} />
                      </div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <h1>{avatar?.name}</h1>
                        <div className="d-flex justify-content-start align-items-center">
                          <small>Contestant&nbsp;{avatar?.id}</small>
                          <div className="input">
                            <input
                              type="number"
                              onChange={(e) => {
                                allAvatar[i].points = e.target.value;
                                setAllAvatar([...allAvatar]);
                              }}
                              value={avatar?.points}
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div>
                        <input />
                    </div> */}
                    </ResultsRow>
                  </ResultColumn>
                ))}
                <ResultColumn></ResultColumn>
              </ResultsRow>
            </Container>
          )}
        </PlayerContainer>
      </ContentWrapper>
      <Toaster position="top-center" />
    </DashboardContainer>
  );
};

export default AvatarResults;
