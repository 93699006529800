import React, { useState, useLayoutEffect } from 'react'
import { ContentWrapper, OnboardingWrapper } from '../../component'
import DashboardContainer from '../../container/dashboardContainer'
import FeatherIcon from 'feather-icons-react'
import { Button, HeaderWrapper, Input, Label, PlayerForm, Select, PlayerContainer, PlayerHeader } from './players-styles/players'
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { isAuthenticated } from '../../utils/data'
import { FormGroup, SettingsContainer, SettingsRow, Subtitle } from './setting-styles/setting-styles'
import { Loading } from '../../container/loading'
import CurrencySettings from '../../container/currency-settings'

const Settings = () => {

  const { obj: { token } } = isAuthenticated()

  const [gameCurrency, setGameCurrency] = useState({
    id: '',
    currency: "NGN",
    currencyAmount: '',
    tokenValue: '',
    meta: {}
  })


  const [gameEntryType, setGameEntryType] = useState("TOKEN")

  const [gameEntryScramble, setGameEntryScramble] = useState(
    {
      game: "CROSSWORD",
      type: `${gameEntryType}`,
      value: 10,
      meta: {}
    })
  const [gameEntryTrivia, setGameEntryTrivia] = useState(
    {
      game: "TRIVIA",
      type: `${gameEntryType}`,
      value: 10,
      meta: {}
    }
  )


  const [gameEntryAvatar, setGameEntryAvatar] = useState(
    {
      game: "AVATAR",
      type: `${gameEntryType}`,
      value: 10,
      meta: {}
    }
  )

  const [gameEntryGuess, setGameEntryGuess] = useState(
    {
      game: "GUESS",
      type: `${gameEntryType}`,
      value: '',
      meta: {}
    })



  //Games Scoring
  const [gameScoringScramble, setGameScoringScramble] = useState(
    {
      game: "CROSSWORD",
      type: "COIN",
      fixed: true,
      value: 10,
      meta: {}
    })
  const [gameScoringTrivia, setGameScoringTrivia] = useState(
    {
      game: "TRIVIA",
      type: "COIN",
      fixed: true,
      value: 10,
      meta: {}
    })

  const [gameScoringAvatar, setGameScoringAvatar] = useState(
    {
      game: "AVATAR",
      type: "COIN",
      fixed: true,
      value: 10,
      meta: {}
    })

  const [gameScoringGuess, setGameScoringGuess] = useState(
    {
      game: "GUESS",
      type: "COIN",
      fixed: true,
      value: 10,
      meta: {}
    })



  const gameType = [
    {
      role: "Token",
      value: "TOKEN",
    },
    {
      role: "Coins",
      value: "COIN",
    },
    {
      role: "Points",
      value: "POINT",
    },

  ]

  const [loading, setLoading] = useState(false);

  const handleChange = name => e => {

    setGameCurrency({ ...gameCurrency, [name]: e.target.value })
  }

  const handleGameType = e => {
    console.log(e.target.value);
    setGameEntryType()
  }



  const getGameSettings = async () => {

    setLoading(true)
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/games/game-settings`, {
      headers: {
        "Authorization": `${token}`
      }
    })
    setLoading(false)

    setGameEntryScramble({
      ...gameEntryScramble,
      id: data?.obj?.gameEntryCosts?.[0]?.id,
      game: data?.obj?.gameEntryCosts?.[0]?.game,
      type: data?.obj?.gameEntryCosts?.[0]?.type,
      value: data?.obj?.gameEntryCosts?.[0]?.value,
    });
    setGameEntryTrivia({
      ...gameEntryTrivia,
      id: data?.obj?.gameEntryCosts?.[1]?.id,
      game: data?.obj?.gameEntryCosts?.[1]?.game,
      type: data?.obj?.gameEntryCosts?.[1]?.type,
      value: data?.obj?.gameEntryCosts?.[1]?.value,
    });
    setGameEntryAvatar({
      ...gameEntryAvatar,
      id: data?.obj?.gameEntryCosts?.[2]?.id,
      game: data?.obj?.gameEntryCosts?.[2]?.game,
      type: data?.obj?.gameEntryCosts?.[2]?.type,
      value: data?.obj?.gameEntryCosts?.[2]?.value,
    });
    setGameEntryGuess({
      ...gameEntryGuess,
      id: data?.obj?.gameEntryCosts?.[3]?.id,
      game: data?.obj?.gameEntryCosts?.[3]?.game,
      type: data?.obj?.gameEntryCosts?.[3]?.type,
      value: data?.obj?.gameEntryCosts?.[3]?.value,
    });

    setGameScoringAvatar({
      ...gameScoringAvatar,
      id: data?.obj?.gameScorings?.[0]?.id,
      game: data?.obj?.gameScorings?.[0]?.game,
      type: data?.obj?.gameScorings?.[0]?.type,
      value: data?.obj?.gameScorings?.[0]?.value,
    });
    setGameScoringGuess({
      ...gameScoringGuess,
      id: data?.obj?.gameScorings?.[1]?.id,
      game: data?.obj?.gameScorings?.[1]?.game,
      type: data?.obj?.gameScorings?.[1]?.type,
      value: data?.obj?.gameScorings?.[1]?.value,
    });
    setGameScoringTrivia({
      ...gameScoringTrivia,
      id: data?.obj?.gameScorings?.[2]?.id,
      game: data?.obj?.gameScorings?.[2]?.game,
      type: data?.obj?.gameScorings?.[2]?.type,
      value: data?.obj?.gameScorings?.[2]?.value,
    });
    setGameScoringScramble({
      ...gameScoringScramble,
      id: data?.obj?.gameScorings?.[3]?.id,
      game: data?.obj?.gameScorings?.[3]?.game,
      type: data?.obj?.gameScorings?.[3]?.type,
      value: data?.obj?.gameScorings?.[3]?.value,
    });
  }


  const saveSettings = async (e) => {

    e.preventDefault();
    setLoading(true);
    try {

      let payload = {
 
        gameEntry: [
          gameEntryScramble,
          gameEntryTrivia,
          gameEntryAvatar,
          gameEntryGuess

        ],
        gameScoring: [
          gameScoringScramble,
          gameScoringTrivia,
          gameScoringGuess,
          gameScoringAvatar
        ]



      }


      console.log("PAYLOAD", payload)

      const { data } = await axios.put(`${process.env.REACT_APP_API_URL}/games/game-settings`, payload, {
        headers: {
          "Authorization": `${token}`
        }
      })
      console.log("RESPONSE", data)
      setLoading(false)

      if (data?.status === 1) {

        toast.error(data?.desc);
        setLoading(false)
      } else {
        if (data?.status === 0) {
          toast.success(`Games settings configured successful!`);

          setLoading(false)
        }

      }

    } catch (error) {

      if (error instanceof Error) {
        if (error.message === 'Network Error') {
          toast.error('Please check your network connection!');
          setLoading(false)
        } else {

          setLoading(false)
        }

      } else {

      }

    }
  }

const gameFixType = [
  {
   type:"Fixed",
   value:true
  },
  {
    type:"Pari-mutuel",
    value:false
   },
]

  useLayoutEffect(() => {
    getGameSettings()
  }, [])

  return (
    <DashboardContainer>
      <ContentWrapper>
        <PlayerContainer>
          <HeaderWrapper>
            <PlayerHeader>
              Game Settings
            </PlayerHeader>
            {/* <Button ><FeatherIcon icon='plus' /> Update</Button> */}
          </HeaderWrapper>
          {loading ?
           <Loading />:
           <>
             <CurrencySettings />
            <br /> <br />
           <div className='player-form'>
           <SettingsContainer bgColor="#000">

          
             <div>
               <h3>Game Entry</h3>
               <small>Input the cost to enter a game.</small>
               <FormGroup >
                 <Label>Games Type</Label>
                 <Select value={gameEntryType} onChange={handleGameType}>
                   {gameType.map((type) => (
                     <option key={type.value} value={type.value}>{type.role}</option>
                   ))}

                 </Select>

               </FormGroup>

               <SettingsRow>
                 <FormGroup >
                   <Label>Trivia</Label>
                   <Input type="number" value={gameEntryTrivia.value} placeholder={`Trivia  2${gameEntryType}S`} onChange={(e) => setGameEntryTrivia({ ...gameEntryTrivia, value: e.target.value })} />

                 </FormGroup>
                 <FormGroup >
                   <Label>Guess </Label>
                   <Input type="number" value={gameEntryGuess.value} placeholder={`Guess  10${gameEntryType}S`} onChange={(e) => setGameEntryGuess({ ...gameEntryGuess, value: e.target.value })} />

                 </FormGroup>
                 <FormGroup >
                   <Label>Avatar</Label>
                   <Input type="number" value={gameEntryAvatar.value} placeholder={`Avatar  10${gameEntryType}S`} onChange={(e) => setGameEntryAvatar({ ...gameEntryAvatar, value: e.target.value })} />

                 </FormGroup>

               </SettingsRow>
               <SettingsRow>

                 <FormGroup >
                   <Label>Scramble</Label>
                   <Input type="number" value={gameEntryScramble.value} placeholder={`Scramble  10${gameEntryType}S`} onChange={(e) => setGameEntryScramble({ ...gameEntryScramble, value: e.target.value })} />

                 </FormGroup>
               </SettingsRow>
             </div>


             <div>
               <h3>Game Scoring</h3>
               <small>Determine what a player is rewarded with, for activities on the platform.</small>

        
               <br/>
              
               <h4>Trivia Game Scoring </h4>
               <SettingsRow>
                
                 <FormGroup >
                       <Label> Type</Label>
                       <Select value={gameScoringTrivia.fixed}  onChange={(e)=>setGameScoringTrivia({ ...gameScoringTrivia, fixed: e.target.value })}>
                          {gameFixType.map((type)=>(
                            <option value={type.value}>{type.type}</option>
                          ))}            
                    </Select>
                      
                    </FormGroup> 
                <FormGroup >
                   <Label> Value</Label>
                   <Input type="number" value={gameScoringTrivia.value} placeholder="10points" onChange={(e) => setGameScoringTrivia({ ...gameScoringTrivia, value: e.target.value })} />

                 </FormGroup>
                
                

               </SettingsRow>
               <br/>
               <div style={{border: '1px solid #333'}}>
               </div>

               <h4>Guess Game Scoring </h4>
               <SettingsRow>
               <FormGroup >
                       <Label>Type</Label>
                       <Select value={gameScoringGuess.fixed}  onChange={(e)=>setGameScoringGuess({ ...gameScoringGuess, fixed: e.target.value })}>
                          {gameFixType.map((type)=>(
                            <option value={type.value}>{type.type}</option>
                          ))}
                           
                        </Select>
                          
                       </FormGroup> 
               <FormGroup >
                   <Label>Value </Label>
                   <Input type="number" value={gameScoringGuess.value} placeholder="10points" onChange={(e) => setGameScoringGuess({ ...gameScoringGuess, value: e.target.value })} />
                 </FormGroup>
                
               </SettingsRow>
               <br/>
               <div style={{border: '1px solid #333'}}>
               </div>
               <h4> Avatar Game Scoring</h4>
               <SettingsRow>
               <FormGroup >
                       <Label>Type</Label>
                       <Select value={gameScoringAvatar.fixed}  onChange={(e)=>setGameScoringAvatar({ ...gameScoringAvatar, fixed: e.target.value })}>
                          {gameFixType.map((type)=>(
                            <option value={type.value}>{type.type}</option>
                          ))}                   
                        </Select>        
               </FormGroup> 
               <FormGroup >
                   <Label> Value </Label>
                   <Input type="number" value={gameScoringAvatar.value} placeholder="10points" onChange={(e) => setGameScoringAvatar({ ...gameScoringAvatar, value: e.target.value })} />

                 </FormGroup>
                 
               </SettingsRow>
               <br/>
               <div style={{border: '1px solid #333'}}>
               </div>
               <h4>Scramble Game Scoring </h4>
               <SettingsRow>
               <FormGroup >
                       <Label>Type</Label>
                       <Select value={gameScoringScramble.fixed}   onChange={(e)=>setGameScoringScramble({ ...gameScoringScramble, fixed: e.target.value })}>
                          {gameFixType.map((type)=>(
                            <option value={type.value}>{type.type}</option>
                          ))}
                           
                        </Select>
                          
                       </FormGroup> 
                 <FormGroup >
                   <Label> Value</Label>
                   <Input type="number" value={gameScoringScramble.value} placeholder="10points" onChange={(e) => setGameScoringScramble({ ...gameScoringScramble, value: e.target.value })} />

                 </FormGroup>
                
               </SettingsRow>

               {/* <h4>*COINS</h4>
                       <SettingsRow>
                       <FormGroup >
                       <Label>Win a Trivia Play</Label>
                           <Input type="number" value={username}  placeholder="1 coin" onChange={handleChange('username')} />
                           <OnboardingWrapper.Error>{errors?.usernameError}</OnboardingWrapper.Error>
                       </FormGroup> 
                       <FormGroup >
                       <Label>Win a Guess Play </Label>
                       <Select value={role}  onChange={handleChange('role')}>
                          {guessRoles.map((role)=>(
                            <option value={role.value}>{role.role}</option>
                          ))}
                           
                        </Select>
                           <OnboardingWrapper.Error>{errors?.usernameError}</OnboardingWrapper.Error>
                       </FormGroup> 
                      
                       <FormGroup >
                       <Label>Win an Avatar Play </Label>
                       <Select value={role}  onChange={handleChange('role')}>
                          {avatarRoles.map((role)=>(
                            <option value={role.value}>{role.role}</option>
                          ))}
                           
                        </Select>
                           <OnboardingWrapper.Error>{errors?.usernameError}</OnboardingWrapper.Error>
                       </FormGroup> 
                       
                       

                       </SettingsRow> */}
             </div>




             <br /><br />

             <OnboardingWrapper.Button onClick={saveSettings}>{loading ? <small>Loading...</small> : "Save "}</OnboardingWrapper.Button>


           </SettingsContainer>

         </div>
           </>

          }
        </PlayerContainer>
      </ContentWrapper>
      <Toaster position='top-center' />
    </DashboardContainer>
  )
}

export default Settings
