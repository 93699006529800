import React from 'react'
import {Container, Menu, Humbuger, Logo, LinkWrapper, LinkRow,LinkColumn, LinkIcon,Link} from './styles/sidebar'

const Sidebar = ({children, ...resProps}) => {
    return (
        <Container  {...resProps}>
               {children}
        </Container>
    )
}

export default Sidebar


Sidebar.Menu = function SidebarMenu({children, ...resProps}){
    return <Menu {...resProps}>{children}</Menu>
}

Sidebar.Humbuger = function SidebarHumbuger({children, ...resProps}){
    return <Humbuger {...resProps}>{children}</Humbuger>
}

Sidebar.Logo = function SidebarLogo({children, ...resProps}){
    return <Logo {...resProps}>{children}</Logo>
}

Sidebar.LinkWrapper = function SidebarLinkWrapper({children, ...resProps}){
    return <LinkWrapper {...resProps}>{children}</LinkWrapper>
}

Sidebar.LinkRow = function SidebarLinkRow({children, ...resProps}){
    return <LinkRow {...resProps}>{children}</LinkRow>
}

Sidebar.LinkColumn = function SidebarLinkColumn({children, ...resProps}){
    return <LinkColumn {...resProps}>{children}</LinkColumn>
}

Sidebar.LinkIcon = function SidebarLinkIcon({children, ...resProps}){
    return <LinkIcon {...resProps}>{children}</LinkIcon>
}

Sidebar.Link = function SidebarLink({children, ...resProps}){
    return <Link {...resProps}>{children}</Link>
}