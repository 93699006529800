import {Route, Redirect} from 'react-router-dom'
import { isAuthenticated } from '../utils/data'



export const ProtectedRoutes = ({component: Component, ...rest }) => (
    <Route {...rest } 
    render={props => isAuthenticated()? (
        <Component {...props} />
 
    ) : (
       <Redirect to={{pathname: "/",  state: { from: props.location }}} />
 
    )} />
    
)




 
export const AdminRoutes = ({component: Component, ...rest }) => (
    <Route {...rest } render={props => isAuthenticated() && isAuthenticated().obj.role==='ROLE_ADMIN'? (
        <Component {...props} />
 
    ) : (
       <Redirect to={{pathname: "/dashboard",  state: { from: props.location }}} />
 
    )} />
    
)

export const ClientRoutes = ({component: Component, ...rest }) => (
    <Route {...rest } render={props => isAuthenticated() && isAuthenticated().obj.role==='ROLE_ADMIN_CLIENT'? (
        <Component {...props} />
 
    ) : (
       <Redirect to={{pathname: "/dashboard",  state: { from: props.location }}} />
 
    )} />
    
)

export const StaffRoutes = ({component: Component, ...rest }) => (
    <Route {...rest } render={props => isAuthenticated() && isAuthenticated().obj.role==='STAFF'? (
        <Component {...props} />
 
    ) : (
       <Redirect to={{pathname: "/dashboard",  state: { from: props.location }}} />
 
    )} />
    
)



// export const PrivateRoute = ({component:Component, ...restProps})=>(
//     <Route {...restProps} render={props=>isAuthenticated ?
//        <Component {...props} /> : <Redirect to={{pathname:'/', state:{from: props.location}}} />
//     }  />

// )