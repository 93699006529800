import React, { useState, useLayoutEffect } from "react";
import { ContentWrapper, CustomModal } from "../../component";

import DashboardContainer from "../../container/dashboardContainer";
import { PlayerContainer, Select } from "./players-styles/players";
import { DataSearch } from "./reward-styles/reward-styles";
import {
  FormInput,
 
} from "./setting-styles/setting-styles";
import { OverviewHeader } from "./user-dashboard-styles/user-dashboard-styles";
import FeatherIcon from "feather-icons-react";
import {
  GamesButton,
  InputLabel,
  FlexContainer,
} from "./games-styles/games-styles";
import { isAuthenticated } from "../../utils/data";
import { applyFilters } from "../../utils/filter";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { Loading } from "../../container/loading";
import { useHistory, useLocation } from 'react-router-dom'



const AddScramble = ({match}) => {
  const [showModal, setshowModal] = useState(false);
  const [showModal2, setshowModal2] = useState(false);
  const [trivials, setTrivias] = useState([]);
  const [creationStep, setCreationStep] = useState(1);
  const [updateStep, setUpdateStep] = useState(1);
  const [wordId, setWordId] = useState();
 
  const [selectedFile, setSelectedFile] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);
  const history = useHistory()

  const [ wordText, setWordText] = useState()
  const [ bulkOptions, setbulkOptions] = useState({
      triviaId: '',
      optionText: '',
      isCorrectAnswer: '',

  })
  const {optionText, isCorrectAnswer} = bulkOptions
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [viewTrivia, setViewTrivia] = useState({});
  const [triviaOptionList, setTriviaOptionList] = useState([]);
  
  const [CreationType , setCreationType] = useState('inputs');
  const [update, setUpdate] = useState();
  

 
  const {
    obj: { token },
  } = isAuthenticated();
  
  const [count, setCount] = useState("");
  const openModal = () => {
    setshowModal(true);
    setWordText('')
  };
  const [currentPage, setCurrentPage] = useState(0);
  const [postPerPage, setPostPerPage] = useState(20);
  const [categoryId, setCategoryId] = useState();

  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const transactions = trivials?.slice(indexOfFirstPost, indexOfLastPost);
  const [words, setWords] = useState();
  const [pageLimit, setPageLimit] = useState(10);
  const PageCount = Math.ceil(count / pageLimit);

  const changePage = ({ selected }) => {
    getAllCrossWords(selected)
    
  };
  const formatTime = (time) => {
    return moment(time).calendar();
  };

  const createScramble = async () => {
    setLoading(true);
    if(!wordText){
      toast.error('Please enter correct word for system to scramble');
      setLoading(false);
    }else{
        let payload = {
            wordText: wordText,
            categoryId:categoryId
        }
        try {
            const { data } = await axios.post(
              `${process.env.REACT_APP_API_URL}/games/crossword-game`,
              payload,
              {
                headers: {
                  Authorization: ` ${token}`,
                },
              }
            );
            toast.success("Word scramble successfully");
            setUpdate(data)
            setLoading(false);
            setWordText()
         
          } catch (error) {
            console.log("NEW EROR",error.response.data)
            if (error instanceof Error) {
                if(error.response.data){
                  toast.error(error.response.data.obj?.[0]?.message);
                  setLoading(false);
                } 

              else if (error.message === "Network Error") {
                toast.error("Please check your network connection!");
                setLoading(false);
              } else {
                setLoading(false);
              }
            } else {
              toast.error(error.response.desc);
              setLoading1(false);
            }
          }
    }
    
  };

  useLayoutEffect(() => {
    let id = match.params.id
    setCategoryId(id)
    if(categoryId){
      getAllCrossWords(); 
    }

    
  }, [categoryId, update]);

  const handleUpdate = (word) => {
   setWordId(word.id);
   setWordText(word.wordText)
   setshowModal2(true); 
  }; 


  const updateCrossWords = async () => {
    setLoading(true);
    if(!wordText){
     toast.error('Field is required');
    setLoading(false);

    }else {
        try { 
            let payload = {
                wordText:wordText,
                id: wordId,
                categoryId:categoryId
              };
             const { data } = await axios.put(
            `${process.env.REACT_APP_API_URL}/games/crossword-game`,
            payload,
            {
              headers: {
                Authorization: ` ${token}`,
              },
            }
          );
    
          toast.success("Scramble word updated successfully");
          setUpdate(data)
          setLoading(false);
          setTimeout(()=>{
            setshowModal2(false); 
          }, 1000) 
         
        } catch (error) {
          if (error instanceof Error) {
            if (error.message === "Network Error") {
              toast.error("Please check your network connection!");
              setLoading(false);
            } else {
              setLoading(false);
            }
          } else {
            toast.error(error.response.desc);
            setLoading(false);
          }
        }
    }
   
    
  };

 
  const getAllCrossWords = async (page) => {
    setLoading(true)

    const filter = {
      page: page ? page  : currentPage,
      size: pageLimit,
      orderDirection: "DESC",
      orderColumn: "id",
    };
    const URL = applyFilters(
      `${process.env.REACT_APP_API_URL}/games/crossword-game/?categoryId=${categoryId}`,
      { ...filter }
    );
    try {
      const { data } = await axios.get(`${URL}`, {
        headers: {
          Authorization: ` ${token}`,
        },
      });
         let sort = data?.obj?.crossWordGame?.sort((a,b) => new Date(a) < new Date(b) ? 1 : -1)
        setWords(data?.obj)
        // console.log(data?.obj)
        setCount(data?.obj?.count)
      setLoading(false);
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === "Network Error") {
          toast.error("Please check your network connection and try again!");
          setLoading(false);
        } else {
          toast.error("System Error!");
          setLoading(false);
        }
      }
      setLoading(false);
    }
  };

  const uploadFile = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', selectedFile);
     axios.post(
      `${process.env.REACT_APP_API_URL}/games/crossword-game/upload?categoryId=${categoryId}`,
      formData,
      {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    ).then((res)=>{
     
      setLoading(false);
      setSelectedFile(null)
      toast.success('File uploaded successfully')
      setshowModal(false)
      // console.log("FILES XLSX", res);
      getAllCrossWords();
    })
    .catch((err)=>{
      setLoading1(false);
      if(err.response.status == 415){
        toast.error(err.response.data.desc + ', Please upload an excel format')
      } else if(err.response.status == 400){
        toast.error('No data found')
      }
      else{
        toast.error(err.response.data.desc)
      }
      
    })
  }

  
  const handleUpload =  (event) => {
    setSelectedFile(event.target.files[0]);
	setIsFilePicked(true);
    };


  

 


  return (
    <DashboardContainer>
          <>
          
          {showModal && (
            <CustomModal width="600px" setShowModal={setshowModal}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  boxSizing: "border-box",
                  maxHeight: '500px',
                  overflowY: 'auto'
                }}
              >
                <h2 style={{ padding: "0 0 20px 0", color:'var(--humber-golden)' }}>
                  Create Scramble Word
                </h2>
                <FlexContainer justifyContent={"flex-start"}>
                 
                
                  <InputLabel onClick={()=>setCreationType('inputs')}
                    width={"220px"}
                    bgColor={`${CreationType == "inputs" ? '#994f09' : 'none'}`} 
                    color={"#FFf"}
                    radius='10px'
                  >
                    <div className="d-flex justify-content-center align-items-center">
                      Add Word
                    </div>
                  </InputLabel>
                  <span style={{color:'var(--humber-light)'}}> OR </span>
                  <InputLabel onClick={()=>setCreationType('upload')}
                    width={"220px"}
                    bgColor={ `${CreationType == "upload" ? '#994f09' : 'none'}`}
                    radius='10px'
                  >
                    <div className="d-flex justify-content-center align-items-center">
                      {" "}
                      Upload File
                    </div>
                  </InputLabel>
                </FlexContainer>

                {CreationType == "inputs" && (
                  <>
                    <div style={{ display: "flex", width: "100%" }}>
                      <FormInput
                        name="wordText"
                        onChange={(e)=> setWordText(e.target.value)}
                        value={wordText}
                        width="100%"
                        
                        placeholder="Enter correct word for system to scramble"
                      />
                    </div>
                    
                  
                      <div
                        style={{
                          width: "100%",
                          margin: "20px 0",
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                        }}
                      >
                        
                 </div>
                  
                    <div>
                      
                      {
                        creationStep == 1 && (
                          <GamesButton   onClick={createScramble}>  {loading ? 'Scramble....' :'Scramble'} </GamesButton>
                        )
                      }
                      {
                        creationStep == 2 && (
                          <GamesButton  onClick={createScramble}> {loading ? 'Scramble...' : 'Scramble'} </GamesButton>
                        )
                      }
                    </div>
                  </>
                )}

                {
                  CreationType == "upload" && (
                    
                  
                    <>
                    <FlexContainer  justifyContent={"center"}>
                  <InputLabel radius="20px" onClick={()=>setCreationType('upload')}
                    width={"180px"}
                    htmlFor="upload"
                    bgColor={'#3e3a2c'}
                  >
                  <span className="d-flex" style={{justifyContent: 'center'}}>{selectedFile?.name ? selectedFile?.name :'Select file'  }</span>
                    <input onChange={handleUpload}  id="upload" style={{display: "none"}}  type="file"/>
                  </InputLabel>
                  
                  
                  <GamesButton style={{margin: "0"}} onClick={uploadFile}>
                    {loading1 ? 'Uploading' : 'Upload'}
            </GamesButton>
                </FlexContainer>

                    </>
                  )
                }
              </div>
            </CustomModal>
          )}

         
          {showModal2 && (
            <CustomModal width="600px" setShowModal={setshowModal2}>
              <div style={{ width: "100%",color:'#d3d3d3' }}>
                <h3>
                  UPDATE SCRAMBLE 
                </h3>

                {updateStep == 1 && (
                  <>
                    <div style={{ margin: "20px 0" }}>
                      <FormInput
                        name="questionText"
                        onChange={(e)=> setWordText(e.target.value)}
                        value={wordText}
                        width="100%"
                       
                      />
                    </div>
                    <div className="d-flex" style={{ gap: "20px" }}>
                      <GamesButton onClick={updateCrossWords}>
                        {" "}
                        {loading ? "Update..." : "Update"}
                      </GamesButton>
                     
                    </div>
                  </>
                )}

                {updateStep == 2 && (
                  <>
                    {triviaOptionList.map((options, i) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            width: "80%",
                            flexWrap: "wrap",
                            justifyContent: "space-between",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                        </div>
                      );
                    })}

                    

                    <div className="d-flex" style={{ gap: "20px" }}>
                      <GamesButton
                        onClick={() => {
                          setUpdateStep(1);
                        }}
                      >
                        {" "}
                        Back
                      </GamesButton>
                    </div>
                  </>
                )}
              </div>
            </CustomModal>
          )}
  
          </>
      <ContentWrapper style={{height:'100vh'}}>
        <PlayerContainer>
          <OverviewHeader>CREATE SCRAMBLE</OverviewHeader>
         {!loading &&(
              <div style={{ display: "flex", justifyContent: "space-between" }}>
              <GamesButton onClick={openModal}>
                <FeatherIcon icon="plus" size="15px" />
                Add word
              </GamesButton>
              <GamesButton onClick={()=>history.push('/settings')}>Game Configuration</GamesButton>
              <GamesButton>Total: {words?  words.count :'0'}</GamesButton>
            </div>
         )}

          <div className="players-table">
            {loading && <Loading />}
            {!loading && (
              <table className="players">
                <tbody>
                  <tr>
                    <th>ID</th>
                    <th>SCRAMBLED WORDS</th>
                    <th>CORRECT ANSWERS</th>
                    <th>ACTIONS</th>
                  </tr>

                 
                    
              

                  {words?.crossWordGame?.map((word, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td className="points">{word?.scrambledText}</td>
                      <td className="points">
                         {word?.wordText}
                      </td>
                     
                      {/* <td>{formatTime(word?.createdTime)}</td> */}
                      <td className='points'>
                        <div className='cat-action-btn'>
                        <FeatherIcon icon='eye' onClick={()=>{handleUpdate(word) }}/>
                        <FeatherIcon icon='trash-2' />
                        </div>
                    </td>
                     
                    </tr>
                  ))}
                </tbody>
              </table>
              
            )}

            <div style={{ justifyContent: "center", display: "flex", alignItems: "center", flexDirection:'column' }}>
            {!loading && (!words?.crossWordGame?.length ) && (
               <p style={{color:'#d3d3d3'}}>No Data Found !</p>
            )}
           
           

            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={PageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
          </div>

         
         
        </PlayerContainer>
      </ContentWrapper>
      <Toaster />
    </DashboardContainer>
  );
};

export default AddScramble;
