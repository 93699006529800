import React from 'react'
import { ContentWrapper } from '../../component'
import DashboardContainer from '../../container/dashboardContainer'
import { PlayerContainer } from './players-styles/players'
import { OverviewHeader } from './user-dashboard-styles/user-dashboard-styles'
const TriviaActivity = () => {
    return (
        <DashboardContainer>
            <ContentWrapper>
                <PlayerContainer>
                    <OverviewHeader>TRIVIA ACTIVITY</OverviewHeader>
                </PlayerContainer>
            </ContentWrapper>
        </DashboardContainer>
    )
}

export default TriviaActivity
