import React from 'react'
import { Container, Main, LeftBar } from './content-wrapper'

const ContentWrapper = ({children, ...resProps}) => {
    return (
        <Container {...resProps}>
              {children}
        </Container>
    )
}

export default ContentWrapper


ContentWrapper.Main = function ContentWrapperMain({...resProps}){
    return <Main  {...resProps} ></Main>
}

ContentWrapper.LeftBar = function ContentWrapperLeftBar({...resProps}){
    return <LeftBar  {...resProps} ></LeftBar>
}

