import React, { useState, useEffect } from "react";
import { ContentWrapper } from "../../component";
import DashboardContainer from "../../container/dashboardContainer";
import FeatherIcon from "feather-icons-react";
import ReactPaginate from "react-paginate";
import {
  Button,
  HeaderWrapper,
  PlayerSearch,
  PlayerContainer,
  PlayerHeader,
  UserStat,
  UserStatTotal,


} from "./players-styles/players";
import { isAuthenticated } from "../../utils/data";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { Loading } from "../../container/loading";
import NumberFormat from "react-number-format";
import moment from "moment";
import Fuse from "fuse.js";
import { useHistory, useLocation } from "react-router-dom";

import ResolvePaymentModal from "../../container/resolve-payment-modal";


const TransactionDetails = ({match}) => {

  const {state} = useLocation()
 
  const history = useHistory()


const today = new Date();

const filterDate = new Date(new Date().setDate(today.getDate() - state)).getTime();


  const {
    obj: { token },
  } = isAuthenticated();

  const [initial, setInitial] = useState();
  const [trans, setTrans] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(20);
  const [transactionStat, setTransactionStat] = useState();
  const [transDate, setTransDate] = useState('');
 

  const [showModal, setShowModal] = useState(false)

  // const todayTrans = now.getTime();
  // const sevenDaysTrans = last7days.getTime()
  // const thirtyDaysTrans = priorDate.getTime()

  // console.log('LAST 30days', priorDate.getTime())
  // console.log("LAST 7 days", last7days.getTime())
  // console.log("Now", now.getTime())

  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const transactions = trans?.slice(indexOfFirstPost, indexOfLastPost)?.filter((transtn)=> new Date(transtn.transactionDate).getTime() >= filterDate)
  const PageCount = Math.ceil(trans?.filter((transtn)=> new Date(transtn.transactionDate).getTime() >= filterDate).length / 20);
  // const PageCount = Math.ceil(trans?.length / 20);



  const changePage = ({ selected }) => {
    console.log(selected)
    setCurrentPage(selected + 1);
  };

  const totalTransactions = transactions?.reduce((initial, current)=>{
      return initial + parseInt(current.amount)
  }, 0)

  const getTransactions = async () => {
    setLoading(true);
    try {
     
      const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/payment/api/transactions/all`,
        {
          headers: {
            Authorization: ` Bearer ${token}`,
          },
        }
      );

      console.log(data);

      if (data) {
        setLoading(false);
        data?.obj.sort((a, b) => (new Date(a) < new Date(b) ? 1 : -1));
        setTrans(data?.obj);
        setInitial(data?.obj);
      }
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === "Network Error") {
          toast.error("Please check your network connection and try again!");
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    }
  };

  const getTotalSum =  async () => {
    try{
        const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/payment/api/transactions/auth/admin/transaction-stat`, {
          headers:{
            Authorization:`Bearer ${token}`
          }
        })

       setTransactionStat(data)
    }catch(error){

    }
  }


//  const totalTrans = transactions?.filter((transtn)=> new Date(transtn.transactionDate).getTime())


//  console.log("TRAAAAA>>>", state)


  useEffect(()=>{
   
    const getDate = match.params
    console.log("PARAMS", getDate)
    if(getDate){
      setTransDate(match.params)
    }
   

  }, [])

  useEffect(() => {
    getTransactions();
    // getTotalSum();
  }, []);

  useEffect(() => {
    if (trans) {
      const fuse = new Fuse(trans, {
        keys: ["customerEmail", "transactionReference", "transactionDate"],
      });

      const results = fuse.search(searchTerm).map(({ item }) => item);

      if (trans.length > 0 && searchTerm.length > 3 && results.length > 0) {
        setTrans(results);
      } else {
        setTrans(initial);
      }
    }
  }, [searchTerm]);


  const resolvePay = ()=>{
    setShowModal(true)
  }




  return (
    <DashboardContainer>


        <ResolvePaymentModal showModal={showModal} setShowModal={setShowModal} />
      <ContentWrapper>
        <PlayerContainer>
          <HeaderWrapper>
            <PlayerHeader 
             onClick={()=>history.push('/transactions')}
            style={{textTransform:'capitalize', display:'flex', flexDirection:'row',cursor:'pointer', alignItems: 'center', gap:'5'}}>
            <span ><FeatherIcon icon='arrow-left' /></span>
            <span>{transDate?.date} Deposit </span>
            </PlayerHeader>

           
          </HeaderWrapper>
          
          <UserStat>
            
              <UserStatTotal>
                <div style={{padding: '50px'}}>
                <FeatherIcon  icon='users' size='2rem'/>
                <h3>TOTAL DEPOSITS (₦)</h3>
                <h1><NumberFormat
                          value={totalTransactions}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"₦"}
                        /></h1>

                </div>
              </UserStatTotal>
            

             
               {/* <PlayerSearch type='search' style={{ display: "block", width: "100%", maxWidth: "88%" }}
                    placeholder='Search by email, name' value={searchTerm} 
                             onChange={({target})=>setSearchTerm(target.value)} /> */}
           
                </UserStat>
                <br/>
                <div style={{display:'flex', justifyContent:'space-between'}}>          
                 <Button width='250px' onClick={resolvePay}>Resolve failed transaction </Button>
                  <PlayerSearch
                      type="search"
                      placeholder="Search by email, reference,"
                      value={searchTerm}
                      onChange={({ target }) => setSearchTerm(target.value)}
                    />     
                </div>
                
                <br />
          <div className="players-table" style={{padding:'2rem'}}>
            {loading && <Loading />}

            {!loading && (
              <table className="players">
                <tbody>
                  <tr className="tr_deposites">
                    <th>Reference</th>
                    <th>Email</th>
                    <th>Method</th>
                    <th>Amount</th>
                    <th>Date</th>
                  </tr>

                  

                  {transactions?.map((tran, i) => (
                    <>
                  
                     {transactions && (           
                       <tr key={i} className="tr_deposites">
                       <td>#{tran.transactionReference}</td>
                       <td className="points">{tran.customerEmail}</td>
                       <td>
                         <div className="user-avatar">{tran.processor}</div>
                       </td>
 
                       <td className="points" style={{ fontFamily: "arial" }}>
                         <NumberFormat
                           value={tran.amount}
                           displayType={"text"}
                           thousandSeparator={true}
                           prefix={"₦"}
                         />
                       </td>
 
                       <td className="points">
                         {moment(tran.transactionDate).format("MMM Do YYYY")}
                        
                       </td>
                     </tr>
        
                     )}

 
                     
                    </>
                   
                  ))}
                </tbody>
              </table>
            )}
              
              <div>
                  {transactions?.length ===0 }
              </div>
                     
          </div>

          <div style={{ justifyContent: "center", display: "flex" }}>
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={PageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        </PlayerContainer>
      </ContentWrapper>
      <Toaster position="top-center" />
    </DashboardContainer>
  );
};

export default TransactionDetails;
