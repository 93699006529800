import { createGlobalStyle } from "styled-components";
export const GlobalStyles = createGlobalStyle`
/* 
@font-face {
  font-family: "Poppins-SemiBold";
  src: url("/give-raffle-fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
}



@font-face {
  font-family: "Poppins-Regular";
  src: url("/give-raffle-fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Poppins-Bold";
  src: url("/give-raffle-fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Poppins-ExtraBold";
  src: url("/give-raffle-fonts/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("/give-raffle-fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: 400;
} */


*{
  box-sizing: border-box;
}
html, body{
  font-family: 'Philosopher', sans-serif; ; 
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   background :#fff;
   font-size: 16px;
   color:#000; 
   padding:0px;
   margin:0px;


 }

h1, h2, h3{
 font-weight:600 ;
 
 margin-bottom: 0;
}
p{
  font-family: 400;
 
}

.hero-section{
  background: url('/bg-banner.png');
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 900px 900px;
} 

 .react-multi-carousel-list {
    background: #000 !important;
}

.blink{


animation: blink 1s linear infinite;
}
@keyframes blink{
0%{opacity: 0;}
/* 50%{opacity: .5;} */
100%{opacity: 1;}
};

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.selected-games{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 1rem;
  margin-bottom:1rem;
}

.ball{
  width: 40px;
    height: 40px;
    background: linear-gradient(180deg, #FFCC00 0%, rgba(255, 204, 0, 0.598958) 45.76%, #ED9717 100%);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 1.1rem;
    font-weight: 900;
    margin-right:1rem;
  &:last-of-type{
    margin-right: 0;
  }
}
.selected-keys{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: .3rem 1rem;
  flex-flow: row wrap;
 
}


.keys{
  /* background: #292929;
  color: #fff; */
  background: #ffffff;
  color: #000000;
     width: 45px;
    height: 45px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    font-size: 1.1rem;
    font-weight: 900;
    margin: 4px;
  flex: 0 1 calc(20% - 8px);
  
}



table {
 
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border:1px solid #d3d3d354;
  text-align: left;
  padding: 8px;
}
th{
  color:#d3d3d3;
}

.time-wrapper{
  width:90px;
  @media (max-width:360px){
    width: 72px;
  }
}

.time{
  width: 30px;
    height: 30px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: .3rem;
    border: 1px solid #00509D;
    border-radius: 5px;
    color: #00509D
}

.day{
  padding: 2px 0 0 0;
  color: #00509D;
  font-size: .8rem;
}

.draws{
  border: none;
    background: #00509D;
    color: #fff;
    width: 130px;
    margin-left: 1rem;
    height: 50px;
    border-radius: 30px;

}

.btn-large{
  background: #FDC500;
  color:#fff;
   max-width: 600px;
  text-align: center;
  margin:  auto;
  padding: .7rem 0rem;
  border-radius: 10px;
  /* margin-top:4rem; */
  margin-bottom:4rem;
  box-shadow: rgb(0 0 0 / 16%) 0px 10px 20px;
  
  h1{
    font-size: 2.5rem;
    margin:0;
    letter-spacing: 1px;
  }
}

.steps{

  text-align: center;
  margin: auto;
  max-width: 700px;
  padding: 4rem 0;
  h1{
    color:#00509D ;
    font-size: 2.5rem;
  }

  .step-description{
    max-width: 600px;
    margin: auto;
    padding-bottom: 1.5rem;
      p{
    text-align: justify;
    line-height: 1.5rem;
    }
  }
 
}


.steps-contents{
  display: flex;
    width: 100%;
    margin: auto;
    justify-content: space-between;
}
.steps-box{
  background: #FDC500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 165px;
  height: 160px;
  border-radius: 10px;

  img{
    width: 45%;
    
  }
  &:nth-child(3) img{
    width: 35%;
  }
 
}

.steps-title{
  color:#fff;
  padding-top:1rem;
}

.results{
  background: #EBF5FF;
  padding: 3rem 0;
  margin: 5rem 0;
}




.result-contents{
  display: flex;
  width: 100%;
  flex-direction:column;
  margin: auto;
 
  h1{
    color: #00509D;
    font-size: 2.5rem;
    text-align: center;
  }   
}


.result-wrapper{
  display: flex;
  width: 100%;
  max-width: 1000px;
  justify-content: space-around;
  margin: auto;
  padding: 3rem 0;
}
.result-box{
  display: flex;
  justify-content: center; 
background: #fff;
width: 400px;
height: 300px;
box-shadow: 0px 10px 20px #00000029;
border-radius: 10px;

}


.result-body{
   display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 2.5rem;
}
.result-body h1{
  color:#141414;
}

.result-body .amount-wrapper{
  display: flex;
    justify-content: space-around;
    align-items: center;
}
.result-body .amount{
  padding-bottom:1rem;
  h2{
    color:#00509D;
    font-size: 2rem;
    margin: 0;
  }
  p{
    color: #141414;
    text-align: left;
    /* font-weight: 600; */
    margin: 4px 0;
  }
}

.result-body p{
  color:#B4B5BF;
  text-align: center;
}


.enter-win-section{
  background: url('/enter-win-bg.svg');
  background-color: #FDC500;
  margin: 10rem 0;
}

.container{
  width:100%;
  max-width: 1300px;
  display: flex;
  justify-content: space-between;
  margin:auto;
  padding: 4rem 0;
  img{
    width:100%;
  }
}
.enter-win-contents{
  flex-grow:1;
  width: 50%;
  padding: 2rem;
  /* max-width: 650px; */

h1{
  color:#00509D;
  font-size: 2.5rem;

  span{
    color:#fff;
  }
}
  p{
    color: #fff;
    text-align: justify;
    line-height: 1.5rem;
    font-weight: normal;
  }
}

.enter-win-btn button{
  width: 200px;
    height: 40px;
    border: none;
    border-radius: 30px;
    background: #00509D;
    color: #fff;
    margin-top: 2.5rem;
    margin-top: 5rem;
    cursor: pointer;
}

.download-app .contain{
  width:100%;
  max-width: 1300px;
  display: flex;
  justify-content: space-between;
  margin:auto;
  gap: 30px;
  img{
    width: 100%;
    max-width: 600px;
    height: auto;

  }
  .enter-win-contents{
  flex-grow:1;
  width: 40%;
  padding: 0;

  &:nth-child(2){
    flex-grow:1;
  width: 60%;
  padding: 0;
  }

h1{
  color:#00509D;
  font-size: 2.5rem;

}
  p{
    color: #000;
    text-align: justify;
    line-height: 1.5rem;
    font-weight: normal;
  }
}

}


.stat{
  h1{
    color: #fdc500;
    margin-bottom: 0px;
    margin-top: 0px;
  }
  .steps-title{
    padding: 0;
  }
  p{
    line-height: 1.5rem;
  }
}
.brand-section{
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap:wrap

}
.brand-wrapper{
  display: flex;
  min-height: 400px;
  gap: 30px;
}

.brands{
    width: 100%;
    max-width: 200px;
    max-height: 200px;
    background: #EBF5FF;
    box-sizing: border-box;
    padding: 2rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
 
    img{
    width: 100%;
    max-width: 170px;
    height: auto;
    }
    h2{
      color: #B4B5BF;
    font-size: 3rem;
    margin-top: 0px;
    margin-bottom: 0px;
    }
    h3{
      margin-bottom:0;
    }

    &:nth-child(2){
      align-self: center;
    }
}


.testimonials{

}

.testimonials-contents {

    display: flex;
    width: 100%;
    flex-direction: column;
    margin: auto;

   h1 {
    color: #00509D;
    font-size: 2.5rem;
    text-align: center;
}
}
.testimonials-wrapper {
  
    display: flex;
    width: 100%;
    max-width: 1300px;
    justify-content: space-between;
    margin: auto;
    padding: 3rem 0;
    gap: 40px;
}

.testimonials-box {
    
    display: flex;
    background: #fff;
    align-items: center; 
    flex: 1;
    box-shadow: 0px 10px 20px #00000029;
    border-radius: 10px;
    padding: 1.5rem .7rem;
}

.testimonial-body{
    display: flex;
    flex-direction: column;  
   
    width: 100%;
    padding: 1rem .5rem;


}

.image-box{
  flex: 1 1 0;
  &:last-of-type{
    flex: 2;
  }
  img{
    width: 100%;
    height: auto;
    max-width: 220px;
  }
  h2{
    padding-bottom: .3rem;
  }
 p{
  padding-right: 1rem;
  line-height: 1.5rem;
 }
 small{
 
  span{
   color:#00509D;

 }
 }
 .hr-line{
  border: 1px solid #FDC500;
  width: 40%;
  margin: 7px 0;
 }
}
.inner-wrapper{
  display: flex;
  gap: 30px;
  align-items: center;
}

.testimonials-header{
  width: 100%;
    max-width: 750px;
    margin: auto;
}

.hr-line-2px{
  border: 3px solid #FDC500;
  width: 20%;
  margin: 10px 0 0 16px;
}

::placeholder{
  color:#a3a3a4;
  font-weight: 400;
  font-size: .8rem;
}

.onboarding-bg{
  background: url('/onboardingBg.png');
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: center center;
    background-size: cover;

    @media (max-width:768px){
      display:none;
    }
}

.react-tel-input .selected-flag {
    outline: none;
    position: relative;
    width: 38px;
    height: 100%;
    padding: 0 0 0 8px;
    border-radius: 3px 0 0 3px;
    background: #ebf5ff !important;
    margin-left: .5rem;
    
  }

  .react-tel-input .form-control {
    width: 100%;
    height: 50px;
    
    padding-left: 47px !important;
    border: 1px solid #ebf5ff !important;
    box-sizing: border-box;
    border-radius: 10px;
    background: #ebf5ff !important;
}

.react-tel-input .flag-dropdown {
  
    background-color: transparent !important;
    border: 1px solid transparent !important;
   
}





//User dashboard

.walletContainer{
  display: flex;

    justify-content: space-around;
    width: 100%;
    align-items: center;
    padding: 0rem 0;
    border-radius: 20px;
  
    gap: 30px;
}


.walletbg{
  background: url('/wallet-bg.svg');
  background-repeat: no-repeat;
  background-position: center center;
   min-height: 300px;
   width: 100%;
   background-size:cover;
} 

.wallet-header{
  font-size: 1.1rem;
  color: #FDC500;

  @media (max-width:600px){   
    font-size: .9rem;
    color: #FDC500;
    text-align: center;
  }
}

.wallet-balance{
  font-weight: 600;
    font-size: 2.5rem;
    color: #fff;
    padding: 1rem 0;
    text-align: center;
  @media (max-width: 600px){
    font-size: 1.5rem;
  }  
}

.btn-group{
  display: flex;
  gap: 30px;
}

.button{
    display: flex;
    align-items: center;
    width: 140px;
    justify-content: space-around;
    height: 45px;
    border-radius: 5px;
    border: #ffdead00;
    background: #fff;
    color: #00509D;
    cursor: pointer;

}
.button-1{
  display: flex;
    align-items: center;
    width: 140px;
    justify-content: space-around;
    height: 45px;
    border-radius: 5px;
    border: #ffdead00;
    background: #fff;
    color: #00509D;
    cursor: pointer;
  border: 2px solid #00509D;
}

.enter-draw-row{
  display: flex;
    padding: 2rem 0rem;
    justify-content: space-between;

    @media (max-width:480px){
      flex-direction: column;
    /* align-items: flex-end; */
    gap: 20px;
    }
}

.complete-profile{
  position: relative;
  padding: 0 1.8rem 0 0;
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 5px;
    small{
      color: #a09e9e;
    }

  .remove-popup{
    background: #8c8c8c;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -.5rem;
    top: -.5rem;
    cursor: pointer;
  }  
}

.user-draws{
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
  padding: 1.3rem 2.5rem;

  .draws-header{
    font-size: 1.3rem;
    color: #00509D;
    padding-bottom:.3rem
  }
  .user-ticket-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    margin-bottom: 1rem;
    @media (max-width:600px){
      flex-direction:column;
      gap: 10px;
    }

    .user-ticket{
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      background: url('/ticket-bg.svg');
      background-repeat: no-repeat;
      background-position: center center;
      min-height: 50px;
      padding: .5rem 2rem;
      width: 100%;
      background-size:contain;

      @media (max-width:600px){
        background-size:cover;
      }
      @media (max-width:480px){
        background-size:contain;
      }


     .ticketid{
      font-size: 1.1rem;
     }
      .ticket-date{
        font-size: .8rem;
        color: #B4B5BF;
      }
    }
  }
}

.buy-ticket-row{
  display: flex;
  flex-direction: column;
    background: #fff;
    border-radius: 10px;
    padding: 1.3rem 2.5rem;
    margin: 2rem 0 0 0;
    @media (max-width:600px){
      padding: 1.3rem 1rem;
    }
    .buy-ticket-header{
      font-size: 1.3rem;
      color: #00509D;
      padding-bottom: .3rem;
    }

    .buy-ticket-container{
       display: flex;
       gap: 30px;
       flex-wrap: wrap;
       padding-top:1rem;
       justify-content: space-between;

      @media (max-width:600px){
        flex-direction: column;
      }
   

    .ticket-box{
    /* flex: 0 0 32%; */
    flex: 1;
    background: #EBF5FF;
    padding: 1rem;
    border-radius: 10px;

    .ticket-box-header{
      color: #00509D;
      padding: .7rem 0;
      span{
        color: #FDC500;
      }
    }
    .content{
      font-size: .8rem;
      color: #B4B5BF;
      line-height: 1.3;
      font-weight: normal;
    }

    .buy-ticket{
      button{
        background: transparent;
        margin-top: 1rem;
        color: #06E12B;
        border-color: #06E12B;
        border: 1px solid;
        border-radius: 5px;
        height: 30px;
        cursor: pointer;
      }
    }
    }

    


    }
    
}

.header{
  font-size: 1.3rem;
  color: #00509D;
  padding-bottom: .3rem;
}

.left-sidebar-box{
  margin-bottom: 1.5rem;
}

.recent-winners{
  display: flex;;
  justify-content: space-between;
  align-items: center;

  .winner-header{
    color: #00509D;
    font-size: 1.2rem;
  }

  .view-more-btn{
    button{
      height: 40px;
      cursor: pointer;
    color: #00509D;
    border-radius: 5px;
    padding: 0px 1rem;
    background: transparent;
    border: 2px solid;

    };
  }
}

.collapse-icon{
  cursor: pointer;
  @media (max-width:768px){
    display: none;
  }
}

.tab-list {
  @media (max-width:1000px){
    overflow-x: scroll;
   }
}
.players-table{
  overflow-x: scroll;
  tbody td, th {
    border-top: 1px solid #d3d3d354 !important;
    text-align: left;
    padding: 8px;
    font-size: 1rem;
    height: 30px;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
  @media (max-width:2900px){
   overflow-x: auto;
  }
}

.dashboard-table{
  overflow-x: scroll;
  padding:2px;
  background:var(--humber-black);
  border-radius:21px;
  tbody td, th {
    text-align: left;
    padding: 8px;
    font-size: 1rem;
    height: 30px;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
  @media (max-width:2900px){
   overflow-x: auto;
  }
}

#table tbody td, th {
    border-top: 1px solid #d3d3d354 !important;
    text-align: left;
    padding: 8px;
    font-size: .8rem;
    height: 30px;/* 

    text-align: left;
    padding: 8px;
    font-size: .9rem;
    height: 30px;
    padding: 8px; */
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.back-btn{
  display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.enter-draw-box{
  display:flex; flex-direction: column; width:100%; height:80vh;
}

.enter-draw{
  color: #8e8e8e;
}

.add-ticket{
  width: 100%;
  max-width: 320px;
  margin: auto;
  text-align: center;
  padding: 2rem 2.5rem;
  border: 1px solid #00509D;
  background: #fff;
  border-radius: 10px;
  .title{
    font-size: 1.5rem;
    padding: 1.3rem 0px;
    color: #00509D;
   
  }
  @media (max-width:600px){
    width: 100%;
    max-width: 230px;
    margin: auto;
    text-align: center;
    padding: 1rem 2rem;
    .title{
    font-size: 1.2rem;
    padding: 1.3rem 0px;
    color: #00509D;
   
  }
  }
p{
  color: #B4B5BF;
    font-size: 14px;
    margin-top: 0;
    margin-bottom:1rem;
}

button{
    background: #00509d;
    border: 1px solid #00509d;
    width: 170px;
    height: 40px;
    color: #fff;
    border-radius: 30px;
    margin: 2rem 0;
    cursor: pointer;
    }
.counter-box{
  display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    height: 60px;
    border: 1px solid #00509D;
    border-radius: 5px;
    color:#00509D ;
    p{
      margin:0;
      font-size:2rem;
      color:#00509d;
    }
    
}

.counter-btn{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: 1px solid #00509D;
  border-radius: 50%;
  cursor: pointer;
  svg{
    color: #00509D;
  }
}
}

a{
  text-decoration:none;
}

.tickets-summary{
  display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #00509D;
    padding-bottom: .5rem;
    margin-bottom:1rem;
    p{
      color: #00509D;
      font-size: 1.3rem;
      margin: 0;
    }
}

.tickets-total{
  display: flex;
    justify-content: space-between;
    align-items: center;
}

.topup-input{
  input{
    height: 40px;
    width: 250px;
    background: #EBF5FF;
    border: 1px solid #00509D;
    padding: 0 1rem;
    border-radius: 10px;
    ::placeholder{
     font-size: 1rem;
     color: #B4B5BF;
    }
  }
}

.game-history-tab{
  color: #949494;
  cursor: pointer;
  padding-right: 1.1rem;
}
.active-tab{
  border-bottom: 6px solid #00509D;
  padding: 0 0 .5rem 0;
  margin-bottom: -1.2rem;
  cursor: pointer;
  padding-right: 1.1rem;
  color: #00509D;
}


.table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  color: #aeacac;
  font-weight: 400;
}


.table-wrapper{
  padding: 2rem;
  background: #fff;
  margin-top: 3rem;
  
  @media (max-width:600px){
    overflow: scroll;
  }
}
tbody{
  font-size: 14px;
  color: #141414;
}

#customers {
  border-collapse: collapse;
  width: 100%;
}

#customers tbody td, th {
  
    border-top: 1px solid #111 !important;
    text-align: left;
    padding: 8px;
    font-size: .9rem;
    height: 30px;

  padding: 8px;
}



#customers tr:hover {
  background-color: #dddddd59;
 cursor: pointer;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  color: #aeacac;
  font-weight: 400;
}

.table tbody td, th {
  border-top: 1px solid #565656  !important;
    text-align: left;
    padding: 10px 8px;
}

td, th {
    border: 0px solid #d3d3d354 !important;
    text-align: left;
    padding: 8px;
}
.d-flex {
  display: flex;
}
.justify-content-start{
  justify-content:flex-start
}
.justify-content-end{
  justify-content:flex-end
}
.justify-content-between{
  justify-content:space-between
}
.justify-content-around{
  justify-content:space-around
}
.justify-content-center{
  justify-content:center
}
.justify-content-evenly{
  justify-content:space-evenly;
}

.align-items-center{
  align-items: center;
}

.col-md-6{
  flex:50%
}
.col-md-3{
  flex:25%
}
.col-md-4{
  flex:33.3333333%
}
.col-md-2{
  flex:16.66666667%
}
.col-md-5{
  flex:41.66666667%
}
.col-md-7{
  flex:58.33333333%;
}
.col-md-8{
    flex:66.66666667%
}

${
  "" /* table, th, td, tr {
  border:1px solid black;
} */
}

${
  "" /* tr:nth-child(odd){
  background-color:#02025d17
} */
}





//Loading...
.loader,
.loader:after {
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
}
.loader {
  margin: 60px auto;
  /* transform: translate(-50%, -50%);
  top: 50%;
  left: 50%; */
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 6px solid #02025d;
  border-right: 6px solid #ed9717;
  border-bottom: 6px solid #02025d;
  border-left: 6px solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


.players-games{
  background: #fff;
  padding: 0 1rem;

}

.action-btn{
  display: flex;
  align-items: center;
  gap: 30px;
  font-size: .8rem;
  .disable{
    background: #ff03031c;
    color: #eb0c0c;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 80px;
    border-radius: 5px;
    cursor: pointer;
  }
  .activate{
    background: #10a986;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 80px;
    border-radius: 5px;
    cursor: pointer;
    color: #fff;
  }
}

.player-search{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right:1rem;
}


/* .StripeElement{
  height: 40px !important;
    width: 300px !important;
    border: 1px solid #d3d3d3 !important;
    display: block;
    align-items: center;
    padding: 0 .5rem;
    border-radius: 5px !important;
    margin-bottom: 1rem !;
    padding-top: .5rem;
} */


.text-avatar{
    color: var(--humber-black);
    width: 29px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--humber-golden);

}
.text-avatar + span {
  color: var(--humber-golden)
}



.cat-action-btn + div {
  padding:6px;
  border-radius:5px;
  
}

.cat-action-btn + .enable{
  background:#1b7916
}

.cat-action-btn + .disabled{
  background:#80808069
}


.cat-action-btn{
  display: flex;
  align-items: center;
  gap: 10px;
  svg{
    width:30px;
    height: 30px;
    cursor: pointer;
    color: var(--humber-golden);
    background-color: none;
    padding: .3rem;
    border-radius: 5px;
    &:nth-last-child(-n+1){
      color: red;
      background-color: #ff05051a;
      padding: .3rem;
      border-radius: 5px;
    }
  }

 
}

.react-datepicker__input-container{
  input{
    width: 100%;
    height: 50px;
    border: 1px solid var(--humber-golden);
    box-sizing: border-box;
    border-radius: 10px;
    background: transparent;
    padding: 0 1rem;
    font-size: 14px;
    outline: none;
    color:var(--humber-light)
  }
}

.text-left{
  text-align:left!important;
}
.text-center{
  text-align:center!important;
}
.text-right{
  text-align:right!important;
}
${
  "" /* .border-right{
  border-right: 1px solid black!important;
} */
}

.paginationBttns {
  width: 80%;
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: center;
}

${
  "" /* .paginationBttns a {
  padding: 10px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid #02025d;
  color: #2b2eff;
  cursor: pointer;
} */
}

.paginationBttns a {
  padding: 6px  11px;
  margin: 8px;
    border-radius: 5px;
    border: 1px  solid var(--humber-golden);
    color: var(--humber-golden);
    cursor: pointer;
    font-size: 13px;
    cursor: pointer;
}
.paginationBttns a:hover {
  color: var(--humber-black);
    background-color: var(--humber-golden);
}

.paginationActive a {
  color: var(--humber-black);
    background-color: var(--humber-golden);
}


.paginationDisabled a {
  color: white;
    background-color: #dcdcdc69;
    border: 1px solid #dcdcdc69;
    cursor:not-allowed
}

.border_table, .tr_sum{
   
    border-top: 1px solid #00509d82 !important;
    
}
.border_table table, .border_table th, .border_table td, .border_table tr {
  border:2px solid #00509d82!important;
}



.tr_sum:nth-child(odd) {
  background-color:#02025d17 ;
}
.tr_sum:nth-child(even){
  background:#02025d40
}
.tr_deposites:nth-child(odd), .tr_deposites:nth-child(even){
  /* background:#02025d17 */
}
.tr_deposites{
  border: 1px solid #fff;
}
.tr_deposites td, .tr_deposites th{
 border-bottom: 2px solid #84858633 !important;
}
.tr:nth-child(odd){
  background-color:#02025d17 ;
}

[disabled] { 
        cursor: not-allowed;
  }


  .reward-image{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
    width: 300px;
    background: #ebf5ff40;
    margin: 2rem 0 0 0;
    border-radius: 10px;
    border: 1px solid var(--humber-golden);
    img{
      height: 230px;
    width: 200px;
    object-fit: scale-down;
    }
    h3{
      margin-top: 0px;
    color: #d3d3d3;
    }
  }
  .file-input{
    height: 35px;
    border: 1px solid var(--humber-golden);
    /* width: 300px; */
    border-radius: 5px;
    overflow: hidden;
    .upload-btn{
    /* height: 100%;
    padding: 0 1rem;
    background: #ed9717;
    border: 1px solid #ed9717;
    outline: none;
    font-size: .8rem;
    cursor: pointer; */

    height: 100%;
    padding: 0 1rem;
    background: var(--gradient-primary);
    border: 1px solid var(--humber-golden);
    outline: none;
    font-size: .8rem;
    color: var(--humber-black);
    cursor: pointer;
    }
    span{
      padding-left:.5rem;
      font-size:.8rem;
    }
  }

 .guess-card{
  .guess-title{
  color:#ffffffa1; font-size:1.17rem; font-weight:600;  
    }
  .guess-body{
    display:flex; flex-wrap:wrap; gap:10px;
    .guess-card-item{
    display:flex; justify-content:center; cursor:pointer; align-items:center; flex: 0 0 24%; height: 150px;  color: #8a8a8e;
     background:var(--humber-dark); border:1px solid #dcdcdc;   border-radius:10px;min-height:233px;
     @media (max-width:1000px){
      flex:0 0 48%;
     }
     @media (max-width:768px){
      flex:0 0 31.6%;
     }
     @media (max-width:600px){
      flex:0 0 48%;
     }
     @media (max-width:480px){
      flex:0 0 100%;
     }
    }


    .guess-card-items{
     flex:0 0 24%; height:150px; border:1px solid #dcdcdc; background:var(--humber-dark); border-radius:10px;
     overflow:hidden;
     min-height: 233px;

     
    .action-icon{
        position: absolute;
        top: 0;
        right: 0;
       
        background: #f2f4f6;
        height: 100%;
        justify-content: center;
        /* padding: 0 .5rem; */
        width: 40px;
        align-items: center;
        flex-direction: column;
        gap: .7rem;
        opacity:0;
        display: flex;
        transform: translateX(30px);
        transition: all .5s ease-in-out;

        svg{
         
          width: 20px;
          height:20px;
          cursor: pointer;
        }

      }
  
      &:hover{
       .action-icon{
         /* display: flex; */
         transform: translateX(0px);
         opacity:1;
       }
     }
     @media (max-width:1000px){
      flex:0 0 48%;
     }
     @media (max-width:768px){
      flex:0 0 31.6%;
     }
     @media (max-width:600px){
      flex:0 0 48%;
     }
     @media (max-width:480px){
      flex:0 0 100%;
     }
     .guess-card-inner{
      height:100%; width:100%; display:flex;
       gap:20px; width: 200px; margin: auto; align-Items:center;
     }
    }
    }
 }

 .answer-options-list{
  display:flex; gap:10px;
  align-items:center;
  margin:.5rem 0;

 .option-input{
  width: 60%;
    height: 50px;
    border: 1px solid var(--humber-golden);
    box-sizing: border-box;
    border-radius: 10px;
    background: var(--humber-black);
    padding: 0 1rem;
    font-size: 14px;
    outline: none;
    color: var(--humber-light)
 }
 .option-select{
  width: 15%;
    height: 50px;
    border: 1px solid #00509d;
    box-sizing: border-box;
    border-radius: 10px;
    background: #ebf5ff;
    padding: 0.6rem;
    font-size: 14px;
    outline: none;
 }
 .add-option-btn{
  background: var(--gradient-primary);
    color: var(--humber-black);
    border: none;
    height: 40px;
    display:flex;
    align-items:center;
    width: 150px;
    border-radius: 20px;
    cursor: pointer;
    padding: .5rem 1rem;
    border: 1px solid transparent;
 }
}

.game-status-running{
background: orange;
padding: .3rem;
border-radius: 10px;
font-size: 12px;
text-align: center;
}
.game-status-processing {
    background: #e1e0de;
    padding: .3rem;
    border-radius: 10px;
    font-size: 12px;
    text-align: center;
}
.game-status-completed {
    background: #038f03;
    padding: .3rem;
    border-radius: 10px;
    font-size: 12px;
    text-align: center;
    color:#fff;
}

.no-question{
  text-align: center;
  font-size: 1.2rem;
  margin-top: 2rem;
}
.publish-answer{
  strong{
    color:red;
    text-transform:uppercase;
  }
}
.isPublished{
  display: flex;
  align-items: center;
  color: #037a38;
  svg{
    margin-left:.5rem;

  }
}

.absolute{
  position:absolute;
}
.relative{
  position:relative;
}
.hover_activity{
  transition: all .3s ease-in-out;
}

.hover_activity:hover{
  background-color: #f5e69740;
  color:var(--humber-light);
  cursor:pointer;
}
.row {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.col{
  position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom: 15px;
  
}

@media (max-width: 576px) {
    .col{
      -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    }
  }

  @media (min-width: 768px){
    .col {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    }
}
.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    background: var(--humber-black);
    border-radius: .55rem;

}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1rem;
    padding-bottom: 2rem;

    h4{
      color:var(--humber-golden);
    }
    div{
      font-size:1.2rem;
    }
}

.text-end{
  text-align:end!important;
}

.text-center{
  text-align:center;
}
.text-left{
  text-align:left;
}
.text-right{
  text-align:right;
}

.manage-winning-btn{
  background: #a9a9a9;
    border: beige;
    border-radius: 5px;
    cursor: pointer;
}

.manage-winning-user-info{
  color:#d3d3d3;
   padding:.2rem;
  span{
   color:#06e12b;
  }
}

 `;


