import {createContext, useReducer, useEffect, useState} from 'react';
import { isAuthenticated } from '../utils/data';
import axios from 'axios'


//Initial State

const initialState = {
    user : null,
    userBance:null
}

// Create Context
const Context = createContext();

// Roote Reducer

const rootReducer = (state, action)=>{

 switch (action.type) {
     case 'LOGIN':
         return {...state, user:action.payload};
    case 'BALANCE' :
          return {...state,  userBalance:action.payload}    
     case 'LOGOUT' :
           return {...state, user:null}
     default:
       return  state;
 }
}


const Provider = ({children})=> {

    const [state, dispatch] = useReducer(rootReducer, initialState);
    

  
    const [countdownDate, setCountdownDate] = useState(new Date('8/8/2021').getTime());
    const [countdown, setCountDown] = useState({
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    });
  
    const setNewTime = () => {
        if (countdownDate ) {
          const currentTime = new Date().getTime();
    
          const distanceToDate = countdownDate - currentTime;
    
          let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
          let hours = Math.floor(
            (distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
          );
          let minutes = Math.floor(
            (distanceToDate % (1000 * 60 * 60)) / (1000 * 60),
          );
          let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);
    
          const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    
          days = `${days}`;
          if (numbersToAddZeroTo.includes(hours)) {
            hours = `0${hours}`;
          } else if (numbersToAddZeroTo.includes(minutes)) {
            minutes = `0${minutes}`;
          } else if (numbersToAddZeroTo.includes(seconds)) {
            seconds = `0${seconds}`;
          }
    
          setCountDown({ days: days, hours: hours, minutes, seconds });
        }
      };

  


    useEffect(() => {

      

      dispatch({
          type:"LOGIN",
        //   payload: roles,
      });

      
      const tick = setInterval(() => setNewTime(), 1000);
      return () => clearInterval(tick) 
      
    }, [])



    return(
        <Context.Provider value={{state, dispatch, countdown}}>
          {children}
        </Context.Provider>
    )

}

export {Context, Provider};