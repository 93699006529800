import React, {useState} from 'react'
import { ContentWrapper } from '../../component'
import DashboardContainer from '../../container/dashboardContainer';
import FeatherIcon from 'feather-icons-react'
import { Link } from 'react-router-dom'
import { isAuthenticated } from '../../utils/data';
import axios from 'axios'


const Wallet = () => {

   


    return (
        <DashboardContainer>
        <ContentWrapper>
            
           
        </ContentWrapper>
    </DashboardContainer>
    )
}

export default Wallet
