import styled from 'styled-components/macro'


export const Container = styled.div``

export const Title = styled.div`
background: #292929;
margin-top: 1rem;
height: 40px;
display: flex;
align-items: center;
padding: 0 1rem;
color: #fff;
letter-spacing: 1px;
justify-content:space-between;
cursor: pointer;
`


export const Content = styled.div`

margin-top:2px;
cursor: pointer;
background:${({active})=>active};
`

export const Row = styled.div`
display:flex;
align-items: center;
justify-content: center;
height: 100px;
padding: 0 1rem;

`

export const Column = styled.div`
flex-basis:40%;
&:last-of-type{
    flex-basis:60%;
}
`

export const Subtitle = styled.h4`
margin-bottom:5px;
margin-top: 0px;
color: #d3d3d3;
`
export const Price = styled.small`
color: rgb(218 197 10);
font-family: arial;
`