import React from 'react'
import { Container, Search } from './styles/top-bar'

const TopBar = ({children, ...resProps}) => {
    return (
        <Container {...resProps}>
              {children}
        </Container>
    )
}

export default TopBar

TopBar.Search = function TopBarSearch({...resProps}){
    return <Search  {...resProps}  />
}
