import React from 'react'
import { Container, Row, Column, Contents,InnerContent, Button, ImageBox, Results, Small, HeaderText, List, ListItem, Countdown} from './styles/jumbotron'

const Jumbotron = ({children, ...resProps}) => {
    return (
        <Container {...resProps}>
            {children}
        </Container>
    )
}

export default Jumbotron

Jumbotron.Row = function JumbotronRow({children, ...resProps}){
    return <Row  {...resProps}>{children}</Row>
}

Jumbotron.Column = function JumbotronColumn({children, ...resProps}){
    return <Column  {...resProps}>{children}</Column>
}

Jumbotron.Contents = function JumbotronContents({children, ...resProps}){
    return <Contents {...resProps} >{children}</Contents>
}
Jumbotron.InnerContent = function JumbotronInnerContent({children, ...resProps}){
    return <InnerContent {...resProps} >{children}</InnerContent>
}

Jumbotron.HeaderText = function JumbotronHeaderText({children, ...resProps}){
    return <HeaderText {...resProps} >{children}</HeaderText>
}
Jumbotron.Small = function JumbotronSmall({children, ...resProps}){
    return <Small {...resProps} >{children}</Small>
}
Jumbotron.Button = function JumbotronButton({children, ...resProps}){
    return <Button {...resProps} >{children}</Button>
}

Jumbotron.Countdown = function JumbotronCountdown({children, ...resProps}){
    return <Countdown {...resProps}>{children}</Countdown>
}

Jumbotron.ImageBox = function JumbotronImageBox({children, ...resProps}){
    return <ImageBox  {...resProps}>{children}</ImageBox>
}

Jumbotron.Results = function JumbotronResults({children, ...resProps}){
    return <Results  {...resProps}>{children}</Results>
}

Jumbotron.List = function JumbotronList({children, ...resProps}){
    return <List  {...resProps}>{children}</List>
}

Jumbotron.ListItem = function JumbotronListItem({children, ...resProps}){
    return <ListItem  {...resProps}>{children}</ListItem>
}
