import React, {useState, useEffect, useRef, useMemo} from 'react'
import { ContentWrapper, CustomModal, OnboardingWrapper, ProgressBar } from '../../component'
import DashboardContainer from '../../container/dashboardContainer'


import {useHistory, Link} from 'react-router-dom'
import { Button, HeaderWrapper, UserStatTotal, PlayerContainer, PlayerHeader, FilterUsers,UserStat, UserStatBox,UserStatRow, PlayerSearch, Input, Label, PlayerForm } from './players-styles/players'
import { isAuthenticated } from '../../utils/data'
import axios from 'axios'; 
import toast, { Toaster } from 'react-hot-toast'; 
import { Loading} from '../../container/loading';
import ReactTooltip from 'react-tooltip';
import ReactPaginate from 'react-paginate';
import Fuse from 'fuse.js'
import FeatherIcon from 'feather-icons-react' 




const ScrambleCategory = () => {

  
    let history = useHistory();
    const {obj:{ token}} = isAuthenticated()
    const fileRef = useRef()

    const [imageFile, setImageFile] = useState();
    const [image, setImage] = useState(); 
    const [loadingProgress, setLoadingProgress] = useState(false)
    const [progress, setProgress] = useState(0);
    
    const [allCategories, setAllCategories] = useState();
    const [screen, setScreen] = useState(1);
    const [update, setUpdate] = useState();
    const [loading, setLoading] = useState(false);
    const [updateCat, setUpdateCat] = useState(false);
    const [catId, setCatId] = useState(false);
    const [showModal, setShowModal] = useState(false);
  
    const [values, setValues] = useState({
        imageUrl:'',
        catgory:'',
        description:'',

    });
    const { imageUrl, category, description,errors } = values;
 
  

    const handleChange = (name) => (e) => {
      setValues({ ...values, errors: {}, [name]: e.target.value });
    };

    const updateGuessCat = async (e) => {
        e.preventDefault();
        setLoading(true);
    
        if (!category) {
          setLoading(false);
          setValues({ ...values, errors: { categoryError: "Name is required" } });
        } else {
          let payload = { 
            id: catId,
            name: category,
            imgUrl:imageUrl, 
            description:''
          };
          console.log("Update Cate", payload);
          try {
            const { data } = await axios.put(
              `${process.env.REACT_APP_API_URL}/games/crossword-category`,
              payload,
              {
                headers: {
                  Authorization: ` ${token}`,
                },
              }
            );
    
            console.log("Guess Games", data);
    
            if (data) {
              setLoading(false);
              setShowModal(false);
              toast.success("Update was successful!");
              setUpdate(data)      
            }
          } catch (error) {
            if (error instanceof Error) {
              if (error.message === "Network Error") {
                toast.error("Please check your network connection and try again!");
                setLoading(false);
              } else {
                toast.error("System Error!");
                setLoading(false);
              }
            }
            setLoading(false);
          }
        }
      };
    
    const addCategory = ()=>{
        setShowModal(true);
        setScreen(1)
      }

    
    const createCategory = async (e) => {
        e.preventDefault();
        setLoading(true);
    
        if (!category) {
          setLoading(false);
          setValues({ ...values, errors: { categoryError: "Name is required" } });
        } else {
          let payload = {
            name: category,
            imgUrl:imageUrl,
            description:'',
          };
          try {
            const { data } = await axios.post(
              `${process.env.REACT_APP_API_URL}/games/crossword-category`,
              payload,
              {
                headers: {
                  Authorization: ` ${token}`,
                },
              }
            );
    
            console.log("Scramble Game Categories", data);
    
            if (data) {
              setLoading(false);
              setShowModal(false);
              setUpdate(data)
              
              toast.success("Created  successful!");
            }
          } catch (error) {
            if (error instanceof Error) {
              if (error.message === "Network Error") {
                toast.error("Please check your network connection and try again!");
                setLoading(false);
              } else {
                toast.error("Failed!");
                setLoading(false);
              }
            }
          }
        }
      };
   
    const getScrambleCategories = async () => {
        setLoading(true);
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL}/games/crossword-category/?size=1000`,
            {
              headers: {
                Authorization: ` ${token}`,
              },
            }
          );
    
          console.log("Guess Game Categories", data);
          setLoading(false);
    
          if (data) {
            setLoading(false);
           
            setAllCategories(data?.obj?.crossWordCategory?.sort((a,b) => new Date(a) < new Date(b) ? 1 : -1))
            // setUpdateCat(true) 
        }
        } catch (error) {
          if (error instanceof Error) {
            if (error.message === "Network Error") {
              toast.error("Please check your network connection and try again!");
             setLoading(false);
            } else {
              toast.error("Failed to retrieve Guess games");
             setLoading(false);
          }
          setLoading(false);
       }
       setLoading(false);
    }
  };  

const confirmDelete = (id)=>{
  setCatId(id)
  setShowModal(true)
  setScreen(3)
}

const editScrambleCategory = (cat)=> {
  setCatId(cat.id)
  setShowModal(true);
  setScreen(2);
  setValues({ ...values, category: cat?.name});
}

const deleteGuessCategory = async() =>{
  console.log("DELETE I")

  try{
    const {data} = await axios.delete(`${process.env.REACT_APP_API_URL}/games/guess-game-category/${catId}`,
    {
      headers: {
        Authorization: ` ${token}`,
      },
    }
    )
    
    setUpdate(data);
    setTimeout(()=>{
      setShowModal(false)
    }, 1000)
  }
  catch(error){
    console.log('Error')
  }
}



const handleImageUpload = async(e)=>{

  let imageFile = e.target.files[0];

  setImageFile(imageFile)

  setLoadingProgress(true)

  console.log("IMAGE", imageFile)
  if(e.target.files[0].size > 1000000){

    setLoadingProgress(false);
  toast.error(`File size should be less than 1MB`);
 
 }else{
  const cloudName = 'doumwledk';
  const imageData = new FormData();
  imageData.append("file", imageFile);
  imageData.append('upload_preset', 'gajtmsrs');
  try{
     
      let {data} =  await axios.post(`https://api.cloudinary.com/v1_1/${cloudName}/image/upload`, imageData,
      {           
      onUploadProgress: (e)=>{      
          let percent = Math.floor((100 * e.loaded) /e.total)
          if(percent <= 100){
              setProgress(percent)
          }
         }
         }
      );
      toast.success('Image upload was successful!');
        
        setValues({...values, imageUrl:data?.url});  
        setTimeout(()=>{
          setProgress(0) ;
        }, 5000)   
    }catch(err){
      // console.log(err)
      setLoadingProgress(false)
      toast.error('Image Upload failed, Try again');
    }
 }
}

const uploadFile = ()=>{
  fileRef.current.click();
}

useEffect(()=>{
    getScrambleCategories();

}, [update])



    return (
        <DashboardContainer>
        {showModal &&  (
        <CustomModal setShowModal={setShowModal}>
          <PlayerForm style={{ border: "2px solid #d3d3d3" }}>
            {/* <h3>{updateCat ? "Update Category" : "Add Category"}</h3> */}
             {screen=== 1 && (
               <div>
               <h3 style={{color:'var(--humber-golden)'}}>{"Add Category"}</h3>
              <OnboardingWrapper.FormGroup>
              
                <Label>Name</Label>
                <Input
                  type="text"
                  value={category}
                  placeholder="Category name"
                  onChange={handleChange("category")}
                />
                <OnboardingWrapper.Error>
                  {errors?.categoryError}
                </OnboardingWrapper.Error>
              </OnboardingWrapper.FormGroup>
              <OnboardingWrapper.FormGroup >
              <Label>Upload Image</Label>   
              <input style={{color:'var(--humber-link)'}} type="file" ref={fileRef} onChange={handleImageUpload}  accept='image/*' />
              {/* <div className='file-input'>
                  <button className='upload-btn' onClick={uploadFile}>
                    Upload
                  </button>
                  <span>
                    {imageFile ? imageFile.name : ''}
                  </span>
                </div> */}
                <br />
              {progress !==0 && (
                    <ProgressBar  completed={progress}/>
                   
              )}
                </OnboardingWrapper.FormGroup>
              <OnboardingWrapper.Button onClick={createCategory}>
                  {loading ? <small>Loading...</small> : "Add "}
                </OnboardingWrapper.Button>
               </div>
             )}

            {screen==2  && (
                <div>
                <h3>{"Update Category"}</h3>
               <OnboardingWrapper.FormGroup>
               
                 <Label>Name</Label>
                 <Input
                   type="text"
                   value={category}
                   placeholder="Category name"
                   onChange={handleChange("category")}
                 />
 
                 <OnboardingWrapper.Error>
                   {errors?.categoryError}
                 </OnboardingWrapper.Error>
               </OnboardingWrapper.FormGroup>
               <OnboardingWrapper.FormGroup >
              <Label>Upload Image</Label>   
              <input type="file" ref={fileRef} onChange={handleImageUpload}   accept='image/*' />
              {/* <div className='file-input'>
                  <button className='upload-btn' onClick={uploadFile}>
                    Upload
                  </button>
                  <span>
                    {imageFile ? imageFile.name : ''}
                  </span>
                </div> */}
                <br />
              {progress !==0 && (
                    <ProgressBar  completed={progress}/>
                   
              )}
                </OnboardingWrapper.FormGroup>

               <OnboardingWrapper.Button onClick={updateGuessCat}>
                   {loading ? <small>Loading...</small> : "Update"}
                 </OnboardingWrapper.Button>
                </div>
            )} 

            {screen === 3 && (
              <div  className='delete-cat-info'>
              <FeatherIcon icon='info' color='#ed9717'  size='40'/>
             <h3 style={{color:'white'}}>Are you sure</h3>
             <small style={{color:'white'}}>You want to delete this category?</small>
              <div style={{display:'flex'}}>
              <OnboardingWrapper.Button bgColor='red' width='150px' onClick={deleteGuessCategory}>
               {loading ? <small>Loading...</small> : "Yes "}
             </OnboardingWrapper.Button>
             <OnboardingWrapper.Button onClick={()=>setShowModal(false)}   bgColor='#ccc' width='150px'>
               {"Cancel "}
             </OnboardingWrapper.Button>
              </div>
           </div>
            )}

          </PlayerForm>
        </CustomModal>
      )}


        <ContentWrapper style={{height: '100vh'}}>
                <PlayerContainer> 
                    <HeaderWrapper>
                    <PlayerHeader>
                       Create Scramble
                    </PlayerHeader>
                    {/* <Button onClick={()=>history.push('/guess_settings')} border="none">
                      Configure game
                    </Button> */}
                    {/* <Button><FeatherIcon icon='plus' /> Add Guess</Button> */}
                    </HeaderWrapper>
                    {loading && (
                        <Loading /> 
                    )}
                     {!loading && (
                           <div className='guess-card'>
                           <div className='guess-title'>Select Scramble Category</div><br/>
                            <div className='guess-body'>    
                                <div className='guess-card-item'>
                           
                                       <FeatherIcon  icon='plus' size='40px' onClick={addCategory}/>
                                </div>
                                {allCategories?.map((cat,i)=>(
                                     
                                        <div key={i} className='guess-card-items' style={{position:'relative'}}>
                                          
                                             <div className='guess-card-inner'>
                                           
                                                 <img src={cat?.imgUrl}  width='30%' />
                                                 <div style={{fontWeight:'600', textTransform:'uppercase', color:'#ffffff99'}}>{cat.name}</div>
                                             </div>
                                         

                                           <div className='action-icon'>
                                                <FeatherIcon icon="edit-3" onClick={()=>editScrambleCategory(cat)} />
                                                <FeatherIcon icon='eye' color='#00509d' onClick={()=>history.push(`/scramble/${cat.id}`)}/>
                                                 <FeatherIcon icon="trash-2" color='red' onClick={()=>confirmDelete(cat.id)}/>
                                                 
                                                </div>
                                               
                                            </div>
                                   
                                ))} 
                                
                              
                                 </div>  
                            </div>
                         
   
                       
                       
                     )}

                         
                    <ReactTooltip  /> 
                  </PlayerContainer>
             
            
        </ContentWrapper>
        
    <Toaster position='top-center'/>
    </DashboardContainer>

    )
}

export default ScrambleCategory
