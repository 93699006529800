import React from 'react'
import {Container} from './styles/dashboard-content'

const DashboardContent = ({children, ...resProps}) => {
    return (
        <Container  {...resProps}>
               {children}
        </Container>
    )
}

export default DashboardContent
