import React from 'react'
import { ContentWrapper } from '../../component'
import DashboardContainer from '../../container/dashboardContainer'


const Support = () => {
    return (
        <DashboardContainer>
        <ContentWrapper>

        </ContentWrapper>
    </DashboardContainer>
    )
}

export default Support
