import React, { useState, useEffect, useLayoutEffect, useMemo } from "react";
import { ContentWrapper, CustomModal } from "../../component";
import DashboardContainer from "../../container/dashboardContainer";
import FeatherIcon from "feather-icons-react";
import { useHistory, Link } from "react-router-dom";
import { TableContainer } from "./players-styles/players";
import {
  Button,
  HeaderWrapper,
  UserStatTotal,
  PlayerContainer,
  PlayerHeader,
  FilterUsers,
  UserStat,
  UserStatBox,
  UserStatRow,
  PlayerSearch,
  ModalBox,
  
} from "./players-styles/players";
import { isAuthenticated } from "../../utils/data";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { Loading } from "../../container/loading";
import ReactPaginate from "react-paginate";
import {
  TabList,
  TabHeader,
  TabContent,
} from "../../container/table-styles/table-styles";
import Fuse from "fuse.js";

const Activity = ({match}) => {
  let history = useHistory();
  const {
    obj: { token },
  } = isAuthenticated();

  const addPlayer = () => {
    history.push("/add-player");
  };

  const [loading, setLoading] = useState(false);
  const [players, setPlayers] = useState();
  
  const [searchTerm, setSearchTerm] = useState("");
  const [activity, setActivity] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(20);
  

  const getActivity =  async() =>{
    setLoading(true)
    try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/games/avatar-activity?id=${match?.params?.id}`,
        
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );
        setLoading(false)
        console.log(data);
        setLoading(false);
        // const { data } 
        setActivity(data?.obj?.avatarActivities[0])
      } catch (error) {
          console.log(error.response);
        setLoading(false);
        if (error instanceof Error) {
          if (error.message === "Network Error") {
            toast.error("Network Error, Please check your network");
          }
        }
        if (error.response) {
          if (error.response.status === 401) {
            toast.error("Not authorized, Please Login again");
          } else {
            toast.error("System Error");
          }
        }
      }
      
  }


    useEffect(()=>{
        getActivity()
       
    }, [])
 
 




 

 

  



 

  return (
    <DashboardContainer>


      <ContentWrapper style={{height:'100vh'}}>
        <PlayerContainer>
          <HeaderWrapper>
          <div className="d-flex justify-content-start">
              <FeatherIcon onClick={()=>{history.goBack()}} color="var(--humber-light)" icon="arrow-left" /> &nbsp; &nbsp;
              <PlayerHeader color='var(--humber-golden)'>{activity?.activityTitle} </PlayerHeader>
          </div>
        
          <Button onClick={()=>history.push('/avatar-results')}>
            Add points
          </Button>
            
            {/* <Button onClick={addPlayer}>
              <FeatherIcon icon="plus" /> Add User
            </Button> */}
          </HeaderWrapper>
          <UserStat style={{alignItems: "center"}}>
            
            <UserStatTotal style={{minHeight:'100px', padding:'12px'}}>
              <div>
                  {activity?.activityText}
              </div>
              
            </UserStatTotal>
            <UserStatBox style={{position:'relative'}}>
               {
                   loading && (
                    <ModalBox>
                    <div className="inner">
                    <Loading />
                    </div>
                </ModalBox>
                   )
               }
              <UserStatRow style={{padding:'.1rem',fontSize:'14px'}}>
                <div>Entry Start</div>
                <div>{activity?.startDateTime}</div>
              </UserStatRow>
              <UserStatRow style={{padding:'.1rem',fontSize:'14px'}}>
                <div>Entry End time</div>
                <div>{activity?.endDateTime}</div>
              </UserStatRow>
              <UserStatRow style={{padding:'.1rem',fontSize:'14px'}}>
                <div>Draw time</div>
                <div>{activity?.drawTime}</div>
              </UserStatRow>
              <UserStatRow style={{padding:'.1rem',fontSize:'14px'}}>
                <div>Entries</div>
                <div>{activity?.maxSelection}</div>
              </UserStatRow>
              <UserStatRow style={{padding:'.1rem',fontSize:'14px'}}>
                <div>Winners</div>
                <div>N/A</div>
              </UserStatRow>
              <UserStatRow style={{padding:'.1rem',fontSize:'14px'}}>
                <div>Coin Awarded</div>
                <div>N/A</div>
              </UserStatRow>
            </UserStatBox>
          </UserStat>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: " 2rem",
              justifyContent: "space-between",
            }}
          >
            <div className="player-search">
              <PlayerSearch
                type="search"
                style={{ display: "block", width: "300px", maxWidth: "100%" }}
                placeholder="Search by email, name"
                // value={searchTerm}
                // onChange={({ target }) => setSearchTerm(target.value)}
              />
            </div>
            <div style={{color:'white'}}>
                Count:0
            </div>

            
          </div>
          <TableContainer>
              <br />

              {loading  ? (
                <Loading />
              ) : (
                <table className="reward-table">
                  <tbody>
                    <tr>
                      <th >ID</th>
                      <th>Username</th>
                      <th>No of entries</th>
                      <th>Point Awarded</th>
                      <th>Coin Awarded</th>
                      <th>Date</th>
                      <th>Time</th>
                      
                    </tr>
                    
                   
                  </tbody>
                </table>
              )}
            </TableContainer>
          {/* <div style={{ justifyContent: "center", display: "flex" }}>
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={PageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div> */}
        </PlayerContainer>
      </ContentWrapper>
      <Toaster position="top-center" />
    </DashboardContainer>
  );
};

export default Activity;
