import React, {useState, useEffect} from 'react'
import { ContentWrapper, OnboardingWrapper } from '../../component'
import DashboardContainer from '../../container/dashboardContainer'
import FeatherIcon from 'feather-icons-react'
import {useHistory} from 'react-router-dom'
import { Button, HeaderWrapper, Input, Label, PlayerColumn, PlayerContainer, PlayerForm, PlayerHeader, Select } from './players-styles/players'
import { isAuthenticated } from '../../utils/data'
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { Loading } from '../../container/loading'
import NumberFormat from 'react-number-format';
import moment from 'moment'
import { FormGroup } from './setting-styles/setting-styles'

const PlayerDetails = (props) => {

  let history = useHistory()

  const { data } = props.location
  
    const [transType, setTransType] = useState({
        transactionMethod : 'TOKENS'
    })

    const [getAllTrans, setAllTrans] = useState()

    const {obj:{ token}} = isAuthenticated()
    const {transactionMethod}  = transType

    const backTo =()=>{
      history.push('/players')
       
    };

    const [loading, setLoading] = useState(false);
   

    const [values, setValues] = useState({
      username:'',
      email:'',
      name:'',
      phone:'',
      role:'OPERATION',
  
      errors:{
        usernameError:'',
        emailError:'',
        nameError:'',
        phoneError:'',
        roleError:'',
      
      }
  
    });
  
  const {email, username, phone, role, name, errors} = values;

const roles = [
{
  role:'Operations',
  value:'OPERATION'
},
{
  role:'Games Operation',
  value:'STAFF',
},
{
  role:"Client",
  value:"ROLE_ADMIN_CLIENT",
},
{
  role:"Admin",
  value:"ROLE_ADMIN",
}
]


    const handleChange = name => e=>{  
    
        setTransType({...transType, errors:{}, [name]: e.target.value})

      }
   
      const getUserDetails = async (email) => {

        setLoading(true)
        try{
        const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/user/auth/admin/user-details?email=${email}`,{
          headers:{
            "Authorization" : ` Bearer ${token}`
          }
         });

        
        if(data){
          setLoading(false)
  
          setValues({...values,
          username:data?.obj?.username,
          email:data?.obj?.email,
          name:data?.obj?.name,
          phone:data?.obj?.phone,
          role: data?.obj?.roles?.[0],})      
        }
        }catch(error){
                
          if (error instanceof Error) {
            if(error.message === 'Network Error'){
              toast.error('Please check your network connection and try again!');
              setLoading(false)
             }else{
            
              setLoading(false)
             }
            }
          }
      } 

   
      const handleInputChange = name =>(e)=>{
        setValues({...values, errors:{}, [name]: e.target.value})
      }

  
      
    const getUserTransactions = async (phone) => {

        setLoading(true)
        try{
          // /user/api/v1/wallet/admin-wallet-transactions?wallettype=${transactionMethod}&phone=${phone}

        const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/payment/api/wallet/admin-wallet-transactions?wallettype=${transactionMethod}&phone=${phone}`,{
          headers:{
            "Authorization" : ` Bearer ${token}`
          }
         });
  
         console.log("userTrans",data)
      
        if(data){
          setLoading(false)
          setAllTrans(data)

         data?.obj.sort((a,b) => new Date(a) < new Date(b) ? 1 : -1);
       
        }
        }catch(error){
                
          if (error instanceof Error) {
            if(error.message === 'Network Error'){
              toast.error('Please check your network connection and try again!');
              setLoading(false)
             }else{
            
              setLoading(false)
             }
            }
          }
      } 

    useEffect(()=>{ 
        let email = props.match.params.email;
        getUserDetails(email);
       getUserTransactions(data)
    }, [transactionMethod])

    const updateUser = async(e) =>{
  
      e.preventDefault();
      
      setLoading(true);
      console.log('Values', values)
      if(!email){
         setValues({...values, errors:{emailError: 'Email is required'}})
         setLoading(false);

  
     }else if(!name){
         setValues({...values, errors:{nameError: 'Name is required'}})
         setLoading(false);
  
     }
    else if(!phone){
      setValues({...values, errors:{phonedError: 'Phone is required'}})
      setLoading(false);

    }else{
       try{
        let payload={   
      
          email: email,
          name: name,
          // phone:phone,  
          role: role  
        }
         const {data}  = await axios.post(`${process.env.REACT_APP_API_URL}/user/admin/update-user-profile`, payload,{
              headers:{
                "Authorization" : `Bearer ${token}`
              }
            });
            if(data?.status ===1){
              toast.error(data?.desc);
              setLoading(false)  
            }else{
              setLoading(false) ;
              toast.success(data?.desc);
            }
           

       }catch(error){
        if (error instanceof Error) {
          if(error.message === 'Network Error'){
            toast.error('Please check your network connection!');
            setLoading(false)  
           }else{
            toast.error(error.message)
           setLoading(false)
           }
    
         }
       }
    }
  }


    return (
        <DashboardContainer>
        <ContentWrapper>
               <PlayerContainer>
                    <HeaderWrapper>
                    <PlayerHeader  onClick={backTo}>
                      <div style={{display:'flex', alignItems:"center", gap:'20px', cursor:'pointer'}}> <FeatherIcon icon='arrow-left'/> Back</div>
                    </PlayerHeader>
                    
                    </HeaderWrapper>


                    <PlayerContainer>
                    <HeaderWrapper>
                    <PlayerHeader>
                      User Details
                    </PlayerHeader>
               
                    </HeaderWrapper>
                    <div className='player-form'>
                    <PlayerForm>   
                    <OnboardingWrapper.FormGroup >
                      <Label>Username</Label>
                        <Input type="text" value={username}  placeholder="Username" onChange={handleInputChange('username')} />
                        <OnboardingWrapper.Error>{errors?.usernameError}</OnboardingWrapper.Error>
                      </OnboardingWrapper.FormGroup>
                       <OnboardingWrapper.FormGroup >
                       <Label>Email</Label>
                        <Input type="email" disabled value={email}  placeholder="Email address" onChange={handleInputChange('email')} />
                        <OnboardingWrapper.Error>{errors?.emailError}</OnboardingWrapper.Error>
                      </OnboardingWrapper.FormGroup>
                     
                      <OnboardingWrapper.FormGroup >
                      <Label>Full name</Label>
                        <Input type="text"  value={name} placeholder="Full Name" onChange={handleInputChange('name')}  />
                        <OnboardingWrapper.Error>{errors?.nameError}</OnboardingWrapper.Error>
                      </OnboardingWrapper.FormGroup>

                      <OnboardingWrapper.FormGroup >
                      <Label>Phone</Label>
                        <Input type="number"  value={phone} placeholder="Phone" onChange={handleInputChange('phone')}  />
                        <OnboardingWrapper.Error>{errors?.phoneError}</OnboardingWrapper.Error>
                      </OnboardingWrapper.FormGroup>

                      <OnboardingWrapper.FormGroup >
                         <Label>Select User Role</Label>
                         <Select value={role}  onChange={handleInputChange('role')}>
                           {roles.map((role, i)=>(
                             <option key={i} value={role.value}>{role.role}</option>
                           ))}
                            
                         </Select>
                        <OnboardingWrapper.Error>{errors?.roleError}</OnboardingWrapper.Error>
                      </OnboardingWrapper.FormGroup>
                     
                       <OnboardingWrapper.Button onClick={updateUser}>{loading? <small>Loading...</small> : "Update User "}</OnboardingWrapper.Button>
                       
                       
                     </PlayerForm>
                                        
                    </div>
               </PlayerContainer>
                 <br/>
                    <div className='players-table'>
                                   {loading && (
                                     <Loading />
                                   )}                            
                                   {!loading && (
                                     <>
                                     <br/>
                                    <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                                       <div>
                                       <h3 style={textColor}>User Transaction Details</h3>
                                       </div>
                                       <div>
                                       <FormGroup style={{display:'flex', alignItems:'center', justifyContents:'space-between'}}>
                                        <Label>Check Method </Label>
                                        <Select value={transactionMethod}  onChange={handleChange('transactionMethod')}>
                                        {['TOKENS', 'COINS'].map((type)=>(
                                            <option value={type}>{type}</option>
                                        ))}
                                            
                                        </Select>
                                      
                                     </FormGroup> 
                                       </div>
                                    </div>
                                   
                                   {/* <div><h3><span style={{color:'rgb(167 166 166)'}}>User</span> : { data?.toUpperCase()} </h3></div> */}
                                     <table className='players'>
                                     <tbody>
                                     <tr>
                                       <th>Trans ID</th>
                                       <th>Balance</th>
                                       
                                       {transactionMethod === "COINS" && (
                                            <th>Coin(s)</th>
                                         )}
                                       <th>Debit Amount</th>
                                       <th>Naration</th>
                                         {transactionMethod === "TOKENS" && (
                                             <th>Token</th>
                                         )}
                                       <th>Date</th>
                                     
                                      
                                     </tr>
                                  
                                     {getAllTrans?.map((tran, i)=>(
                                       <tr key={i}>
                                       <td>#{tran.transactionId}</td> 
                                     
                                       <td className='points' style={{fontFamily:'arial'}}>
                                       <NumberFormat value={tran.balance} displayType={'text'} thousandSeparator={true} prefix={''} />
                                           </td>

                                       {transactionMethod ==='COINS' && (
                                        <td><div className='user-avatar'>{tran.coinValue}</div></td>
                                    )} 
                                       
         
                                       <td className='points'>{tran.debitAmount}</td>
                                       
                                       <td className='points'>{tran.narration}</td> 
                                    {transactionMethod ==='TOKENS' && (
                                        <td className='points'>{tran.tokenValue}</td> 
                                    )}   
                                       <td className='points'>
                                       { moment(tran.createdAt).format("MMM Do YYYY")}</td>
                                           

                                       
                                      
                                     
                                     </tr>
                                     ))}
                                    
                                     </tbody>
                                   </table>

                                   <div style={{display:'flex', alignItems:'center',justifyContent:'center', flexDirection:'column',padding: '3rem 0',}}>
                                        {!getAllTrans?.length > 0 && (
                                            <>
                                              <FeatherIcon  icon='inbox' size='40px' color='#bbbaba'/>
                                            <div style={textColor}>User does not have any activity yet</div>
                                            </>
                                        )}
                                    </div>
                                   </>
                                   )}   
                    </div>
                    
               </PlayerContainer>
              
        </ContentWrapper>
    <Toaster position='top-center'/>
    </DashboardContainer>
    )
}

const textColor={
  color:'#d3d3d3'
}

export default PlayerDetails

