import React, {useState} from 'react'
import { ContentWrapper, OnboardingWrapper } from '../../component'
import DashboardContainer from '../../container/dashboardContainer'
import FeatherIcon from 'feather-icons-react'
import { Button, HeaderWrapper, Input,Label, PlayerForm,Select, PlayerContainer, PlayerHeader } from './players-styles/players'
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { isAuthenticated } from '../../utils/data'

const AddPlayer = () => {
  
    const history = useHistory()

    const addPlayer = ()=>{
        history.push('/players')
    }

  const {obj: {token}} = isAuthenticated()
 
    const [values, setValues] = useState({
        username:'',
        email:'',
        name:'',
        phone:'',
        role:'OPERATION',
    
        errors:{
          usernameError:'',
          emailError:'',
          nameError:'',
          phoneError:'',
          roleError:'',
        
        }
    
      });
    

const roles = [
  {
    role:'Operations',
    value:'OPERATION'
  },
  {
    role:'Games Operation',
    val:'STAFF',
  },
 
  {
    role:"Client",
    value:"ROLE_ADMIN_CLIENT",
  },
  {
    role:"Admin",
    value:"ROLE_ADMIN",
  }
]

      const [loading, setLoading] = useState(false);
    
      const {email, username, phone, role, name, errors} = values;
    
        const handleChange = name => e=>{  
    
        setValues({...values, errors:{}, [name]: e.target.value})
      }
    
      
      const addPlayerForm = async(e)=>{
    
        e.preventDefault();
    
        setLoading(true);
        console.log('Values', values)
         if(!username){
           setValues({...values, errors:{usernameError:"Username is required"}})
           setLoading(false);
         }
       else if(!email){
           setValues({...values, errors:{emailError: 'Email is required'}})
           setLoading(false);

    
       }else if(!name){
           setValues({...values, errors:{nameError: 'Name is required'}})
           setLoading(false);
    
       }
      else if(!phone){
        setValues({...values, errors:{phonedError: 'Phone is required'}})
        setLoading(false);
 
      }
      // else if(!role){
      //   setValues({...values, errors:{roleError: 'Role is required'}})
      //   setLoading(false);
 
      // }
       else{
    
         try{
           
           let payload={   
            username:username,
            email: email,
            name: name,
            phone:phone,
            role: role,    
              
             }
    
    
             console.log("PAYLOAD", payload)
         
            const {data}  = await axios.post(`${process.env.REACT_APP_API_URL}/user/auth/admin/create-user`, payload,{
              headers:{
                "Authorization" : `Bearer ${token}`
              }
            })
             console.log("RESPONSE", data)
    
              if(data?.status ===1){
                  
                toast.error(data?.desc);
                 setLoading(false)
              }else{
                if(data?.status ===0){
                  toast.success(`Created successful!`);
                setValues({...values, username:'', email:'', name:'',phone:'' });
                setLoading(false)
                }
                
              }
      
           }catch(error){
              
            if (error instanceof Error) {
              if(error.message === 'Network Error'){
                toast.error('Please check your network connection!');
                setLoading(false)  
               }else{
               
               setLoading(false)
               }
        
             }else{
          
             }
            
           }
    
        }
        
    
     }

    return (
        <DashboardContainer>
        <ContentWrapper>
               <PlayerContainer>
                    <HeaderWrapper>
                    <PlayerHeader>
                        Add User
                    </PlayerHeader>
                    <Button onClick={addPlayer}><FeatherIcon icon='plus' /> View Users</Button>
                    </HeaderWrapper>
                    <div className='player-form'>
                    <PlayerForm>   
                    <OnboardingWrapper.FormGroup >
                      <Label>Username</Label>
                        <Input type="text" value={username}  placeholder="Username" onChange={handleChange('username')} />
                        <OnboardingWrapper.Error>{errors?.usernameError}</OnboardingWrapper.Error>
                      </OnboardingWrapper.FormGroup>
                       <OnboardingWrapper.FormGroup >
                       <Label>Email</Label>
                        <Input type="email" value={email}  placeholder="Email address" onChange={handleChange('email')} />
                        <OnboardingWrapper.Error>{errors?.emailError}</OnboardingWrapper.Error>
                      </OnboardingWrapper.FormGroup>
                     
                      <OnboardingWrapper.FormGroup >
                      <Label>Full name</Label>
                        <Input type="text"  value={name} placeholder="Full Name" onChange={handleChange('name')}  />
                        <OnboardingWrapper.Error>{errors?.nameError}</OnboardingWrapper.Error>
                      </OnboardingWrapper.FormGroup>

                      <OnboardingWrapper.FormGroup >
                      <Label>Phone</Label>
                        <Input type="number"  value={phone} placeholder="Phone" onChange={handleChange('phone')}  />
                        <OnboardingWrapper.Error>{errors?.phoneError}</OnboardingWrapper.Error>
                      </OnboardingWrapper.FormGroup>

                      <OnboardingWrapper.FormGroup >
                         <Label>Select User Role</Label>
                         <Select value={role}  onChange={handleChange('role')}>
                           {roles.map((role)=>(
                             <option value={role.value}>{role.role}</option>
                           ))}
                            
                         </Select>
                        <OnboardingWrapper.Error>{errors?.roleError}</OnboardingWrapper.Error>
                      </OnboardingWrapper.FormGroup>
                     
                       <OnboardingWrapper.Button onClick={addPlayerForm}>{loading? <small>Loading...</small> : "Create User "}</OnboardingWrapper.Button>
                       
                        
                     </PlayerForm>
                                        
                    </div>
               </PlayerContainer>
        </ContentWrapper>
        <Toaster position='top-center'/>
    </DashboardContainer>
    )
}

export default AddPlayer
