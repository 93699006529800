import React from 'react'
import FeatherIcon from 'feather-icons-react'
import { Modal, ModalBody, ModalContent , ModalItem} from './styles/custom-modal'

const CustomModal = ({children, setShowModal, width}) => {
    return (
        <Modal>

            <ModalBody>
               
                      <FeatherIcon icon='x-circle' className='cancel' onClick={()=>setShowModal(false)} />
               
                <ModalContent>
                    <ModalItem width={width}>
                    {children}
                    </ModalItem>
                </ModalContent>
            </ModalBody>
            
        </Modal>
    )
}

export default  CustomModal