import styled from 'styled-components/macro';
import {Link as RouteLink} from 'react-router-dom'

export const Container = styled.div`
display: flex;
padding: 0;
margin: 0;
width:100%;
flex-direction:column;
justify-content: center;
position: sticky;
top: 0;
z-index: 9999;

`

export const Row = styled.div`
display: flex;
padding: 0;
margin: 0;
width:100%;
flex-direction:column;
justify-content: center;
height: ${({height})=>height};
font-family: Arial, Helvetica, sans-serif;
font-weight: 600;

background: ${({bgColor})=>bgColor};
color: ${({color})=>color};
border-bottom:  ${({border})=>border};
align-items: center;


`

export const Item = styled.div`

/* max-width: 1400px; */
display:flex;
justify-content: space-between;
align-items: center;
padding: 0 5rem;
align-items: center;
width: 100%;  
max-width: 1300px;
`



export const SubMenu = styled.div`
display: flex;
justify-content: space-between;
width: 100%;
padding: 0 .3rem;

`

export const Button = styled(RouteLink)`
display: flex;
align-items: center;
justify-content: center;
width:130px;
height: 35px;
background: ${({bgcolor})=>bgcolor ? ' #00509D' : '#fff'};
color: ${({color})=>color ? '#fff': '#00509D'};
border: ${({border})=> border? '1px solid #00509D':'1px solid transparent'};
border-radius: .2rem;
cursor: pointer;
margin-right: .5rem;
font-weight: 500;
font-size:.8rem;
transition: .3s ease-in;
text-decoration: none;
font-family:poppins;

&:hover{
    border:1px solid #00509D;
    background: #00509D;
    color: #fff;
 

}

&:last-of-type{
    margin-right: 0;
}
&:last-of-type:hover{
    color: #fff;
    background:#00509D;
    border:1px solid transparent;
}

`

export const Logo = styled.img`

`

export const Text = styled.p`
padding-right:2.5rem;

cursor: pointer;
font-weight: 600;

`

export const List =styled.ul`
display:flex; 
list-style-type:none;
padding-left:0;
/* font-family:arial; */
 font-weight:400;
font-size:.9rem;
`

export const ListItem = styled.li`
 padding:0 1rem;
 text-align : center;
 font-family: poppins;
 font-weight: 500;
 /* border-left: 2px solid rgb(211 211 211 / 29%); */

 &:first-of-type{
  padding-left: 0;
  text-align:left;
  border-left:none;
 }

 &:last-of-type{
    border-right: 0px solid rgb(211 211 211 / 29%);
 }

`

export const Jackpot = styled.div`
display:flex;
align-items:center;
font-family: Arial, Helvetica, sans-serif;
small{
    color: #ed1723;
    padding-left: .4rem;
}
`

export const Column = styled.div`
display: flex;
align-items: center;
svg{
        display: none;
    }

@media (max-width: 768px){
   > ${List}{
        display: none;
    }

   > ${Button}{
        display: none;
    }

    svg{
        display: block;
    }
}

`

export const UserItem = styled.div`
color:#fff;
margin-right: 1rem;
font-style: normal;
font-weight: 400;
font-size: .85rem;
cursor: pointer;
img{
    width: 80%;
}

&:nth-child(2){
display:flex;
align-items:center;
border: 1px solid #606060;
box-sizing: border-box;
border-radius: 10px;
height: 40px;
}

&:last-of-type{
    margin-right:0;
    font-size:.9rem;
}


`
export const Dropdown = styled.div`
display: none;
background: #27282A;
position: absolute;
top:1.4rem;
z-index: 9999;
right: -18px;
border-radius: 20px;
`

export const UserWallet = styled.div`
    color: #fff;
    padding: 0 1rem;
    font-style: normal;
    font-weight: normal;
    h2{
        font-size: 1.1rem;
    }

&:nth-child(1){
    background: #d70505;
    color: #fff;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    border-radius:10px 0 0 10px ;
}

span{
    font-size: .9rem;
    padding-left: .2rem;
    font-weight: 600;

}
&:last-of-type{
    h2{
       
        letter-spacing: 1px;
       
    }
}
`

export const Avatar = styled.div`
&:hover{
     cursor: pointer;
 
    

   &:last-of-type > ${Dropdown}{
         display: block;
         position: absolute;
        top: 3.2rem;
        z-index: 9999;
        right: 5rem;
        
     }
 }

`

export const UserBalance = styled.div`
display: flex;
align-items: center;   
font-size: 1.1rem;
letter-spacing: 1px;
font-weight: 600;
position: relative;
svg{
    margin-left:.5rem;
}

 &:hover svg{
     cursor: pointer;
    

     ${Dropdown}{
         display: block;
     }
 }
 
 &:hover {
     cursor: pointer;

     ${Dropdown}{
         display: block;
         
     }
 }

`

export const DropdownList = styled.div`
display: flex;
flex-direction: column;
align-items: center;
padding:1rem;



`

export const DropdownListItem = styled.div`
height: 40px;
font-style: normal;
font-weight: normal;
font-size: .9rem;
display: flex;
align-items: center;

border-bottom: 1px solid #606060;
width: 100%;
min-width: 180px;

&:last-of-type{
    border-bottom: 0px solid #d3d3d3; 
}
a{
    text-decoration: none;
    color: #fff;
    transition: .2s ease-in;
}
a:hover{
    color: #b1aeae;
}
`