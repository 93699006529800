import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  ContentWrapper,
  CustomModal,
  OnboardingWrapper,
  ProgressBar,
} from "../../component";
import { AvatarProfile , AvatarDetails, List ,  CardItem } from  '../dashboard/user-dashboard-styles/user-dashboard-styles'
import DashboardContainer from "../../container/dashboardContainer";

import { useHistory, Link } from "react-router-dom";
import {
  Button,
  HeaderWrapper,
  UserStatTotal,
  PlayerContainer,
  PlayerHeader,
  FilterUsers,
  UserStat,
  UserStatBox,
  UserStatRow,
  PlayerSearch,
  Input,
  Label,
  PlayerForm,
  Select
} from "./players-styles/players";
import { isAuthenticated } from "../../utils/data";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { Loading } from "../../container/loading";
import ReactTooltip from "react-tooltip";
import ReactPaginate from "react-paginate";
import Fuse from "fuse.js";
import FeatherIcon from "feather-icons-react";

const CreateAvatar = () => {
  let history = useHistory();
  const {
    obj: { token },
  } = isAuthenticated();
  const fileRef = useRef();

  const [imageFile, setImageFile] = useState();
  const [image, setImage] = useState();
  const [loadingProgress, setLoadingProgress] = useState(false);
  const [progress, setProgress] = useState(0);

  const [allAvatar, setAllAvatar] = useState();
  const [screen, setScreen] = useState(1);
  const [update, setUpdate] = useState();
  const [loading, setLoading] = useState(false);
  const [avatarId, setAvatarId] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const [values, setValues] = useState({
    imageUrl: "",
    avatar: "",
    price: "",
    age: "",
    hobbies: "",
    stateOfOrigin: "",
    lifestyle: "",
    errors: "",
    gender:"Male"
  });
  const {
    imageUrl,
    avatar,
    price,
    profile,
    age,
    hobbies,
    stateOfOrigin,
    errors,
    lifestyle,
    gender
  } = values;

  const handleChange = (name) => (e) => {
    setValues({ ...values, errors: {}, [name]: e.target?.value });
    console.log(values)
  };

  const updateGuessCat = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!avatar) {
      setLoading(false);
      setValues({ ...values, errors: { avatarError: "Name is required" } });
    } else {
      let payload = {
        id: avatarId,
        name: avatar,
        imgUrl: imageUrl,
        price:price,
        age:age,
        lifestyle:lifestyle,
        stateOfOrigin: stateOfOrigin,
        hobbies:hobbies,
        gender:gender
      };
      console.log("Update Cate", payload);

    
      try {
        const { data } = await axios.put(
          `${process.env.REACT_APP_API_URL}/games/avatar`,
          payload,
          {
            headers: {
              Authorization: ` ${token}`,
            },
          }
        );

        console.log("Guess Games", data);

        if (data) {
          setLoading(false);
          setShowModal(false);
          toast.success("Update was successful!");
          setUpdate(data);
          setValues({ ...values, imageUrl: "", avatar: "", price: "", profile: "" , hobbies: "", age: "", gender:""});
        }
      } catch (error) {
        if (error instanceof Error) {
          if (error.message === "Network Error") {
            toast.error("Please check your network connection and try again!");
            setLoading(false);
          } else {
            toast.error("System Error!");
            setLoading(false);
          }
        }
      }
    }
  };

  const addAvatar = () => {
    setShowModal(true);
    setScreen(1);
    setValues({ ...values, imageUrl: "", avatar: "", price: "", profile: "" , hobbies: "", age: "", gender:""});
  };

  const createAvatar = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!avatar) {
      setLoading(false);
      setValues({ ...values, errors: { avatarError: "Name is required" } });
    } else if (!age) {
      setLoading(false);
      setValues({ ...values, errors: { ageError: "Age  is required" } });
    } else if (!price) {
      setLoading(false);
      setValues({ ...values, errors: { priceError: "Price is required" } });
    } else if (!hobbies) {
      setLoading(false);
      setValues({ ...values, errors: { hobbiesError: "Hobbies is required" } });
    } else if (!stateOfOrigin) {
      setLoading(false);
      setValues({ ...values, errors: { stateError: "state is required" } });

     } else if (!stateOfOrigin) {
        setLoading(false);
        setValues({ ...values, errors: { genderError: "gender is required" } });
    } else if (!lifestyle) {
      setLoading(false);
      setValues({
        ...values,
        errors: { lifestyleError: "life style is required" },
      });
    } else {
      let payload = {
        name: avatar,
        price: price,
        imgUrl: imageUrl,
        age: age,
        stateOfOrigin: stateOfOrigin,
        lifestyle: lifestyle,
        hobbies: hobbies,
        gender:gender
      };

      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_API_URL}/games/avatar`,
          payload,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );

        console.log("AVATAR", data);

        if (data) {
          setLoading(false);
          setShowModal(false);
          setUpdate(data);

          toast.success("Avatar Created  successful!");
        }
      } catch (error) {
        if (error instanceof Error) {
          if (error.message === "Network Error") {
            toast.error("Please check your network connection and try again!");
            setLoading(false);
          } else {
            toast.error("Failed to create Avatar!");
            setLoading(false);
          }
        }
      }
    }
  };

  const getAllAvatars = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/games/avatar?size=1000`,
        {
          headers: {
            Authorization: ` ${token}`,
          },
        }
      );

      setLoading(false);
      if (data) {
        setLoading(false);

        setAllAvatar(
          data?.obj?.avatar?.sort((a, b) =>
            new Date(a) < new Date(b) ? 1 : -1
          )
        );
      }
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === "Network Error") {
          toast.error("Please check your network connection and try again!");
          setLoading(false);
        } else {
          toast.error("Failed to retrieve Guess games");
          setLoading(false);
        }
      }
    }
  };

  const confirmDelete = (id) => {
    setAvatarId(id);
    setShowModal(true);
    setScreen(3);
  };

  const editAvatar = (avatar) => {
    setAvatarId(avatar.id);
    setShowModal(true);
    setScreen(2);
    
    console.log(avatar.lifestyle);
    setValues({
      ...values,
      age: avatar?.age,
      avatar: avatar?.name,
      lifestyle: avatar?.lifestyle,
      hobbies: avatar?.hobbies,
      imageUrl: avatar?.imgUrl,
      price: avatar?.price,
      gender:avatar?.gender
    });
  };

  const deleteAvatar = async () => {
    setLoading(true);
    try {
      const { data } = await axios.delete(
        `${process.env.REACT_APP_API_URL}/games/avatar/${avatarId}`,
        {
          headers: {
            Authorization: ` ${token}`,
          },
        }
      );
      setUpdate(data);
      setLoading(false);
      setTimeout(() => {
        setShowModal(false);
      }, 1000);
    } catch (error) {
      if (error instanceof Error) {
        setLoading(false);
        toast.error("Please check your network connection and try again");
      } else {
        setLoading(false);
        toast.error("Failed to delete Avatar");
      }
    }
  };

  const handleImageUpload = async (e) => {
    let imageFile = e.target.files[0];

    setImageFile(imageFile);

    setLoadingProgress(true);

    console.log("IMAGE", imageFile);
    if (e.target.files[0]?.size > 1000000) {
      setLoadingProgress(false);
      toast.error(`File size should be less than 1MB`);
    } else {
      const cloudName = "doumwledk";
      const imageData = new FormData();
      imageData.append("file", imageFile);
      imageData.append("upload_preset", "gajtmsrs");
      try {
        let { data } = await axios.post(
          `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
          imageData,
          {
            onUploadProgress: (e) => {
              let percent = Math.floor((100 * e.loaded) / e.total);
              if (percent <= 100) {
                setProgress(percent);
              }
            },
          }
        );
        toast.success("Image upload was successful!");

        setValues({ ...values, imageUrl: data?.url });
        setTimeout(() => {
          setProgress(0);
        }, 5000);
      } catch (err) {
        // console.log(err)
        setLoadingProgress(false);
        toast.error("Image Upload failed, Try again");
      }
    }
  };

  const uploadFile = () => {
    fileRef.current.click();
  };

  useEffect(() => {
    getAllAvatars();
  }, [update]);

  return (
    <DashboardContainer>
      {showModal && (
        <CustomModal style={{maxHeight:'512px'}} setShowModal={setShowModal}>
          <PlayerForm>
            {screen === 1 && (
              <div>
                <h3>{"Create Avatar"}</h3>
                <OnboardingWrapper.FormGroup>
                  <Label>Name</Label>
                  <Input
                    type="text"
                    value={avatar}
                    placeholder="Avatar name"
                    onChange={handleChange("avatar")}
                  />

                  <OnboardingWrapper.Error>
                    {errors?.avatarError}
                  </OnboardingWrapper.Error>
                </OnboardingWrapper.FormGroup>
                <OnboardingWrapper.FormGroup>
                  <Label>Life style</Label>
                  <Input
                    type="text"
                    value={lifestyle}
                    placeholder="Avatar life style"
                    onChange={handleChange("lifestyle")}
                  />
                  <OnboardingWrapper.Error>
                    {errors?.lifestyleError}
                  </OnboardingWrapper.Error>
                </OnboardingWrapper.FormGroup>

                <OnboardingWrapper.FormGroup>
                  <Label>Price</Label>

                  <Input
                    type="number"
                    value={price}
                    placeholder="Avatar price"
                    onChange={handleChange("price")}
                  />
                  <OnboardingWrapper.Error>
                    {errors?.priceError}
                  </OnboardingWrapper.Error>
                </OnboardingWrapper.FormGroup>
                <OnboardingWrapper.FormGroup>
                  <Label>Age</Label>
                  <Input
                    type="number"
                    value={age}
                    placeholder="Avatar age"
                    onChange={handleChange("age")}
                  />
                  <OnboardingWrapper.Error>
                    {errors?.ageError}
                  </OnboardingWrapper.Error>
                </OnboardingWrapper.FormGroup>
                <OnboardingWrapper.FormGroup>
                  <Label>State of origin</Label>
                  <Input
                    type="text"
                    value={stateOfOrigin}
                    placeholder="Avatar state of origin"
                    onChange={handleChange("stateOfOrigin")}
                  />
                  <OnboardingWrapper.Error>
                    {errors?.stateError}
                  </OnboardingWrapper.Error>
                </OnboardingWrapper.FormGroup>
                <OnboardingWrapper.FormGroup>
                  <Label>Hobbies</Label>
                  <Input
                    type="text"
                    value={hobbies}
                    placeholder="Avatar hobbies"
                    onChange={handleChange("hobbies")}
                  />
                  <OnboardingWrapper.Error>
                    {errors?.hobbiesError}
                  </OnboardingWrapper.Error>
                </OnboardingWrapper.FormGroup>
                <OnboardingWrapper.FormGroup>
                  <Label>Gender</Label>
                  <Select
                    
                  
                    value={gender}
                    onChange={handleChange("gender")}
                  >
                  <option>
                    Male
                  </option>
                  <option>
                    Female
                  </option>
                  </Select>
                  <OnboardingWrapper.Error>
                    {errors?.genderError}
                  </OnboardingWrapper.Error>
                </OnboardingWrapper.FormGroup>

                <OnboardingWrapper.FormGroup>
                  <Label>Upload Image</Label>
                  <input
                    type="file"
                    ref={fileRef}
                    onChange={handleImageUpload}
                    accept="image/*"
                  />

                  <br />
                  {progress !== 0 && <ProgressBar completed={progress} />}
                </OnboardingWrapper.FormGroup>
                <OnboardingWrapper.Button onClick={createAvatar}>
                  {loading ? <small>Loading...</small> : "Create "}
                </OnboardingWrapper.Button>
              </div>
            )}

            {screen == 2 && (
              <div>
                <h3>{"Update Avatar"}</h3>
                <OnboardingWrapper.FormGroup>
                  <Label>Name</Label>
                  <Input
                    type="text"
                    value={avatar}
                    placeholder="Avatar name"
                    onChange={handleChange("avatar")}
                  />

                  <OnboardingWrapper.Error>
                    {errors?.avatarError}
                  </OnboardingWrapper.Error>
                </OnboardingWrapper.FormGroup>

                <OnboardingWrapper.FormGroup>
                  <Label>Age</Label>
                  <Input
                    type="text"
                    value={age}
                    placeholder="Avatar age"
                    onChange={handleChange("age")}
                  />

                  <OnboardingWrapper.Error>
                    {errors?.ageError}
                  </OnboardingWrapper.Error>
                </OnboardingWrapper.FormGroup>
                <OnboardingWrapper.FormGroup>
                  <Label>Price</Label>
                  <Input
                    type="number"
                    value={price}
                    placeholder="Avatar price"
                    onChange={handleChange("price")}
                  />

                  <OnboardingWrapper.Error>
                    {errors?.priceError}
                  </OnboardingWrapper.Error>
                </OnboardingWrapper.FormGroup>
                <OnboardingWrapper.FormGroup>
                  <Label>Hobbies</Label>
                  <Input
                    type="text"
                    value={hobbies}
                    placeholder="Avatar hobbies"
                    onChange={handleChange("hobbies")}
                  />

                  <OnboardingWrapper.Error>
                    {errors?.hobbiesError}
                  </OnboardingWrapper.Error>
                </OnboardingWrapper.FormGroup>
                <OnboardingWrapper.FormGroup>
                  <Label>Life style </Label>
                  <Input
                    type="text"
                    value={lifestyle}
                    placeholder="Avatar life style"
                    onChange={handleChange("lifestyle")}
                  />

                  <OnboardingWrapper.Error>
                    {errors?.lifestyleError}
                  </OnboardingWrapper.Error>
                </OnboardingWrapper.FormGroup>
                <OnboardingWrapper.FormGroup>
                  <Label>State of origin</Label>
                  <Input
                    type="text"
                    value={stateOfOrigin}
                    placeholder="Avatar price"
                    onChange={handleChange("stateOfOrigin")}
                  />

                  <OnboardingWrapper.Error>
                    {errors?.stateError}
                  </OnboardingWrapper.Error>
                </OnboardingWrapper.FormGroup>
                <OnboardingWrapper.FormGroup>
                  <Label>Gender</Label>
                  <Select
                    
                  
                    value={gender}
                    onChange={handleChange("gender")}
                  >
                  <option>
                    Male
                  </option>
                  <option>
                    Female
                  </option>
                  </Select>
                  <OnboardingWrapper.Error>
                    {errors?.genderError}
                  </OnboardingWrapper.Error>
                </OnboardingWrapper.FormGroup>

                <OnboardingWrapper.FormGroup>
                  <Label>Upload Image</Label>
                  <input
                    type="file"
                    ref={fileRef}
                    onChange={handleImageUpload}
                    accept="image/*"
                  />

                  <br />
                  {progress !== 0 && <ProgressBar completed={progress} />}
                </OnboardingWrapper.FormGroup>

                <OnboardingWrapper.Button onClick={updateGuessCat}>
                  {loading ? <small>Loading...</small> : "Update"}
                </OnboardingWrapper.Button>
              </div>
            )}

            {screen === 3 && (
              <div className="delete-cat-info">
                <FeatherIcon icon="info" color="#ed9717" size="40" />
                <h3 style={{ color: "var(--humber-light)" }}>Are you sure</h3>
                <small style={{ color: "var(--humber-light)" }}>
                  You want to delete this Avatar?
                </small>
                <div style={{ display: "flex" }}>
                  <OnboardingWrapper.Button
                    bgColor="red"
                    width="150px"
                    onClick={deleteAvatar}
                  >
                    {loading ? <small>Deleting...</small> : "Yes "}
                  </OnboardingWrapper.Button>
                  <OnboardingWrapper.Button
                    onClick={() => setShowModal(false)}
                    bgColor="#ccc"
                    width="150px"
                  >
                    {"Cancel "}
                  </OnboardingWrapper.Button>
                </div>
              </div>
            )}
          </PlayerForm>
        </CustomModal>
      )}

      <ContentWrapper >
        <PlayerContainer>
          <HeaderWrapper>
            <PlayerHeader color={'var(--humber-golden)'}> <FeatherIcon type="button" onClick={()=>history.goBack()} icon="arrow-left"  />  ULTIMATE CONTESTANT</PlayerHeader>
              <div className="d-flex" style={{ gap: "20px" }}>
              <OnboardingWrapper.Button borderRadius="0" onClick={()=>history.push('/avatar-results')}>
                    Enter results
                </OnboardingWrapper.Button>
                <OnboardingWrapper.Button borderRadius="0" onClick={()=>history.push('/avatar-activities')}>
                    Create Activity
                </OnboardingWrapper.Button>
              </div>
            {/* <Button><FeatherIcon icon='plus' /> Add Guess</Button> */}
          </HeaderWrapper>
          {loading && <Loading />}
          {!loading && (
            <div className="guess-card">
              {/* <div className="guess-title">Select Avatar </div> */}
              <br />
              <div className="guess-body">
                <div className="guess-card-item">
                  <FeatherIcon icon="plus" size="40px" onClick={addAvatar} />
                </div>
                {allAvatar?.map((avatar, i) => (
                  <div
                    key={i}
                    className="guess-card-items"
                    style={{ position: "relative" }}
                  >
                                    <CardItem>
                                   <AvatarProfile>
                                      <div className='avatar-pic'> 
                                      <img style={{width:'62px'}} src={avatar?.imgUrl} alt='avatar' />     
                                      </div>
                                      <div className='avatar-box'>
                                           <div className='avatar-name'>
                                                {avatar?.name}
                                           </div>
                                           <div className='avatar-role'>
                                               Contentant {avatar.id}
                                           </div>
                                      </div>
                                      
                                  </AvatarProfile>
                                   <AvatarDetails style={{fontSize: '14px',
    lineHeight: '20px',}}>
                                        <List>
                                            Name: <strong>{avatar?.name}</strong>
                                        </List>
                                        <List>
                                            Gender: <strong>Male</strong>
                                        </List>
                                        <List>
                                            State: <strong>{avatar.stateOfOrigin}</strong>
                                        </List>
                                        <List>
                                            Lifestyle: <strong>{avatar.lifestyle}</strong>
                                        </List>
                                        <List>
                                            Hobbies: <strong>{avatar.hobbies}</strong>
                                        </List>
                                        <List>
                                            Age: <strong>30</strong>
                                        </List>
                                        <List>
                                            Price: <strong>{avatar.price} Tokens</strong>
                                        </List>
                                   </AvatarDetails>
                                   </CardItem>
                    <div className="guess-card-inner">
                      <img src={avatar?.imgUrl} width="30%" />
                      <div
                        style={{
                          fontWeight: "600",
                          textTransform: "uppercase",
                          color: "var(--humber-golden",
                        }}
                      >
                        {avatar.name}
                      </div>
                    </div>

                    <div className="action-icon">
                      <FeatherIcon
                        icon="edit-3"
                        onClick={() => editAvatar(avatar)}
                      />
                      <FeatherIcon icon="eye" color="#00509d" />
                      <FeatherIcon
                        icon="trash-2"
                        color="red"
                        onClick={() => confirmDelete(avatar.id)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          <ReactTooltip />
        </PlayerContainer>
      </ContentWrapper>

      <Toaster position="top-center" />
    </DashboardContainer>
  );
};

export default CreateAvatar;
