import styled from "styled-components/macro";

export const GamesContainer = styled.div``;

export const ResultsRow = styled.div`
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  h1{
    color:var(--humber-light);
    font-size: 20px;
  }
  small{
    color:var(--humber-light)
  }

  .input{
    margin-top: -36px;
    margin-left: 20px;

  }
  input{
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem  + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--humber-light);
    background:transparent;
   border:none;
    border-bottom: 1px solid var(--humber-golden)!important;
    :focus{
      outline:none;
    }
}

  }
`;

export const Container = styled.div`
  width: 100%;
  ${
    "" /* padding-right: 0;
  padding-left: 0; */
  }
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1400px) {
    max-width: 1320px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

export const ResultColumn = styled.div`
  flex: 50%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;

  @media (max-width: 576px) {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
`;
