import React from 'react'
import {Container, Logo, Button,Row, Column, SubMenu, Text,Avatar, Item, List, ListItem, Jackpot, UserItem, UserWallet,UserBalance, Dropdown,
    DropdownList, DropdownListItem
} from './styles/menu'

const Menu = ({children, ...resProps}) => {
    return (
        <Container {...resProps}>
            {children}
        </Container>
    )
}

export default Menu

Menu.Row = function MenuRow({children, ...resProps}){
    return <Row {...resProps}>{children}</Row>
}

Menu.Column = function MenuColumn({children, ...resProps}){
    return <Column {...resProps}>{children}</Column>
}

Menu.Item = function MenuItem({children, ...resProps}){
    return   <Item {...resProps}>{children}</Item>
}

Menu.SubMenu = function MenuSubMenu({children, ...resProps}){
    return <SubMenu {...resProps}>{children}</SubMenu>
}

Menu.Text = function MenuText({children, ...resProps}){
    return <Text {...resProps}>{children}</Text>
}

Menu.List = function MenuList({children, ...resProps}){
    return <List {...resProps}>{children}</List>
}

Menu.ListItem = function MenuListItem({children, ...resProps}){
    return <ListItem {...resProps}>{children}</ListItem>
}

Menu.Jackpot = function MenuJackpot({children, ...resProps}){
    return <Jackpot {...resProps}>{children}</Jackpot>
}



Menu.Logo = function MenuLogo({...resProps}){
    return <Logo {...resProps}  />
}

Menu.Button = function MenuLogo({children ,...resProps}){
    return <Button {...resProps} >{children} </Button>
}

Menu.UserItem = function MenuUserItem({children ,...resProps}){
    return <UserItem {...resProps} >{children} </UserItem>
}

Menu.UserWallet = function MenuUserWallet({children ,...resProps}){
    return <UserWallet {...resProps} >{children} </UserWallet>
}

Menu.UserBalance = function MenuUserBalance({children, ...resProps}){
    return <UserBalance {...resProps}>{children}</UserBalance>
}

Menu.Avatar = function MenuAvatar({children, ...resProps}){
    return <Avatar {...resProps}>{children}</Avatar>
}

Menu.Dropdown = function MenuDropdown ({children, ...resProps}){
    return <Dropdown {...resProps}>{children}</Dropdown>
}
Menu.DropdownList = function MenuDropdownList ({children, ...resProps}){
    return <DropdownList {...resProps}>{children}</DropdownList>
}
Menu.DropdownListItem = function MenuDropdownListItem ({children, ...resProps}){
    return <DropdownListItem {...resProps}>{children}</DropdownListItem>
}