import styled from 'styled-components/macro'


export const LoadingWrapper = styled.div`

.loader2 {
    margin: 2px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 4px solid #0581b6;
    border-right: 4px solid #ed9717;
    border-bottom: 4px solid #ffffff;
    border-left: 4px solid #ffffff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    animation: load8 1.1s infinite linear;
    -webkit-animation: load8 1.1s infinite linear;
}

.loader2, .loader2::after {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

`