import React, {useState, useEffect} from 'react'
import DatePicker from "react-datepicker";
import toast, { Toaster } from "react-hot-toast";
import FeatherIcon from "feather-icons-react";
import ReactPaginate from 'react-paginate'
import { Loading } from '../../../container/loading';
import moment from 'moment'

const AllGuessByCategory = (
    {categoryQuestions,
     spinner, 
     setGetQuestion, 
     getQuestion,
     setShowModal,
     showModal,
     setQuestionStep,
     setQuestionCreate,
     questionCreate,
     setValues,
     values,
     setOptions,
     setEndDate,
     setStartDate,
     setDrawDate

    }) => {


const dayNow = new Date().getTime()

function timeFormat(time){
  return new Date(`${time}`).getTime();
}
const [currentPage, setCurrentPage] = useState(1);
const [postPerPage, setPostPerPage] = useState(10);
const [stat, setStat] = useState({})

const indexOfLastPost = currentPage * postPerPage;
const indexOfFirstPost = indexOfLastPost - postPerPage;
const paginatedQuestions = categoryQuestions?.slice(indexOfFirstPost, indexOfLastPost);
const PageCount = Math.ceil(categoryQuestions?.length / 10);

 const handleUpdateQuestion=(guess)=>{                         
   setGetQuestion(guess);
   setQuestionCreate({...questionCreate,answerType:guess?.answerType, isPublished:guess?.isPublished });
   setValues({...values, answerText:guess?.answerText,  questionText:guess?.questionText, id:guess?.id});
   setOptions(guess?.guessOptionList)
   setQuestionStep(2)
    setShowModal(true);
    setEndDate( new Date(guess?.endDateTime));
    setStartDate( new Date(guess?.startDateTime))
    setDrawDate(guess?.drawTime ? new Date(guess?.drawTime) : new Date() )

    console.log("GUESS DETAIL", guess)
   
 
 }

 const confirmDeleteQuestion =(guess)=>{
  setQuestionStep(3)
  setShowModal(true);
  setGetQuestion(guess);
 }

 const changePage = ({ selected }) => {
  console.log(selected)
  setCurrentPage(selected + 1);
};

useEffect(() => {

}, [getQuestion, showModal])

    return (

        <>
         <div className="players-table">
             
             {spinner && (
              <Loading  />
             )}
            {(!spinner && categoryQuestions?.length >=1) && (
              <>
                <table className="players">
                <tbody>
                  <tr>
                    <th>#</th>
                    <th>GUESS QUESTION</th>
                    <th>CORRECT ANSWER</th>
                    <th>ANSWER TYPE</th>              
                    <th>START DATE</th>
                    <th>END DATE</th>
                    
                    <th>STATUS</th>
                    <th>ACTIONS</th>
                  </tr>
        
                  {paginatedQuestions?.map((guess, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
        
                      <td className="points">{guess?.questionText}</td>
                     
                      <td className="points">{guess?.answerText}</td>
                      <td className="points">{guess?.answerType==="FREE_TEXT" ? 'FREE TEXT' : "OPTIONS"}</td>
                      
                      <td>
                        <div className="user-avatar">
                        {moment(guess.startDateTime).format('lll')}
                        </div>
                      </td>
                      <td>
                        <div className="user-avatar">
                          {moment(guess.endDateTime).format('lll')}
                        </div>
                      </td>
                      
                     
                         
                         <td className="points">
                         <div className={dayNow >  timeFormat(guess.startDateTime) && dayNow <  timeFormat(guess.endDateTime)   ? 'game-status-running ':dayNow >  timeFormat(guess.endDateTime) && dayNow < timeFormat(guess.drawTime) ? 'game-status-processing':'game-status-completed'}
                         >{dayNow >  timeFormat(guess.startDateTime)  && dayNow <  timeFormat(guess.endDateTime)   ? 'Running': dayNow >  timeFormat(guess.endDateTime) && dayNow < timeFormat(guess.drawTime) ?  'Processing' : 'Completed'}
                         </div>
                         </td>
                      <td className="points">
                        <div className="cat-action-btn">
                        
                          <FeatherIcon
                            icon="edit-3"
                            size="15px"
                            color="#444444"
                           
                            onClick={() =>handleUpdateQuestion(guess)}
                          />
                          <FeatherIcon
                            icon="trash-2"
                            size="15px"
                            color="red"
                            onClick={() =>confirmDeleteQuestion(guess)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  pageCount={PageCount}
                  onPageChange={changePage}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                  />  
                </>     
            )}
            </div>
         
            {(!spinner && !categoryQuestions?.length ) && (

            <div className='no-question'>No Question Yet!</div>

            )}

         
            
        </>
    )
}

export default AllGuessByCategory
