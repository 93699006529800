import React from 'react'
import { LoadingWrapper } from './loading-styles/loading-styles'



export const Loading = () => {
    return (
        <div className="loader"></div>
    )
}


export const Loading2 =()=>{
    return (
        <LoadingWrapper>
             <div className="loader2"></div>
        </LoadingWrapper>
    )
}

