import styled from 'styled-components/macro'


export const Container = styled.div`
 background: #FFFFFF;
 box-shadow: ${({boxShadow})=>boxShadow ? 'none' : '0px 4px 20px 8px rgba(0, 0, 0, 0.07)'};
 border-radius: ${({borderR})=>borderR};
 width: 100%;
 max-width: 300px;
 padding: 1.2rem 0;
 margin: 1rem;
 border: 3px solid transparent;
 transition: .5s ease-in;
 cursor: pointer;
 &:hover{
     border: ${({border})=>border ? ' 3px solid #ff0000': '3px solid transparent'};
 }
`

export const Bg = styled.div`
width: 100%;
 /* background: #c90909; */
 background-color: #c90909;
background-image: url('/luckyball.png');
background-position: right;
background-repeat: no-repeat;
`
export const Wrapper = styled.div`
width: 1000px;
 padding:3rem;
  margin: auto;

  @media(max-width:1000px){
      width: 100%;
      padding: 1rem 0;
  }
`

export const Item = styled.div`
  display: flex;
 justify-content: center;
 
  @media(max-width:1000px){
      width: 100%;
      padding: 1rem 0;
  }
`

export const Row = styled.div`
display: flex;
justify-content: space-evenly;
align-items: center;
overflow: hidden;
padding: 0rem 1.5rem;
padding-bottom: 1rem;

img{
    width:120px;
}



`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
`
export const Column = styled.div`
    
`

export const Footer = styled.div`
border-top: 1px solid #E5E5E5;

padding: 1rem 1.5rem;

`

export const SubTitle = styled.p`
font-family: poppins;
margin: 0;
font-size: 1.3rem;
font-weight: 700;
`
export const Text = styled.p`
font-family: lato;
margin: 0;
padding-top: 1rem;
text-align: center;

`

export const Title = styled.p`
font-family: poppins;
margin: ${({margin})=>margin};
font-size: 1.4rem;
padding-left:${({alignLeft})=>alignLeft};
text-align: ${({center})=>center};
font-weight: 700;
color:${({color})=>color};

`

export const SmallText = styled.p`
font-size:.8rem;
font-family: poppins;
margin: 0;
font-weight: 500;
color: #989898;
padding-top:.2rem;
`

export const ButtonGroup = styled.div`
display:flex;
align-items: center;
justify-content: ${({justifyContent})=>justifyContent};
padding: ${({padding})=>padding}

`

export const Button = styled.button`
width:130px;
height: 35px;
background: ${({bgColor})=>bgColor ? ' #FF0000' : 'transparent'};
color: ${({color})=>color ? '#fff': '#000'};
border: ${({border})=> border? '1px solid #c7c7c7;':'1px solid transparent'};
border-radius: 4rem;
cursor: pointer;
margin-right: .5rem;
font-weight: 500;
font-size:.8rem;
transition: .3s ease-in;
font-family:poppins;

&:hover{
    border:1px solid #b1b1b1;
    background: #efefef;
 

}

&:last-of-type{
    margin-right: 0;
}
&:last-of-type:hover{
    color: #fff;
    background:#e30303;
    border:1px solid transparent;
}



`
export const IconBox = styled.div`
display: flex;
justify-content: center;
img{
    width: 100px;
}

`


export const Ul = styled.ul`
display: flex;
padding: 0;
`

export const Li = styled.li`
list-style-type:none;
width: 40px;
height: 40px;
margin-right: 5px;
border: 1px solid #111;
justify-content: center;
display: flex;
align-items: center;
border-radius: 50%;
font-size: 1.3rem;
font-weight: 600;
font-family:poppins;
&:last-of-type{
    margin-right: 0px;
}
`

export const PlayNowBtn = styled.div`
    width: 200px;
    background: #e30303;
    height: 50px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4rem;
    cursor: pointer;
    font-weight: 500;
    font-size:.1.1rem;
    transition: .3s ease-in;
    font-family:poppins;
`