import styled from 'styled-components/macro';

export const Container = styled.div`
width: 100%;
display: flex;
flex-direction: column;
background: #000;
align-items: center;
padding: 4rem 0;
justify-content: center;
overflow: hidden;
border-top: 2px solid #5B5B5B;
`

export const Row = styled.div`
display: flex;
width: 100%;
max-width: 1300px;
justify-content: space-between;
margin: auto;

flex-wrap: wrap;

@media(max-width:600px){
    flex-direction: column;
}


`
export const Column = styled.div`
display: flex;
flex-direction: column;
justify-content: center;

flex: 0 1 calc(40% - 8px);


&:nth-child(2){
    flex: 0 1 calc(20% - 16px);
    align-items: center;
   
}

.svg-inline--fa{
    padding-right:.8rem;
    padding-top: .5rem;
    font-size:1.5rem;
    transition: .2s ease-in-out;
    &:last-of-type{
        padding-right: 0;
    }

  &:hover{
      transform: scale(1.2);
      cursor: pointer;
  }  
}

`

export const Title = styled.p`
font-size: 35px;
letter-spacing: 0px;
margin-bottom: 0px;
color: #fff;

`

export const List = styled.ul`
list-style-type: none;
color:#d3d3d3;
/* font-family:poppins; */
padding-left:0;



`

export const Item = styled.li`
display: flex;
align-items: flex-start;
padding:.45rem 0;
font-size:.9rem;
line-height: 1.5;
text-align: justify;
/* letter-spacing: .8px; */
img{
    padding:0;
    margin-right:.4rem;
}
a{
    text-decoration: none;
    color:#d3d3d3;
    

}
a:hover{
     color:#FDC500;
     cursor: pointer;
 }

`

export const Form = styled.form`

`
export const FormGroup = styled.div``

export const Input = styled.input`
width: 100%;
max-width: 380px;
height: 43px;
padding: 0 0 0 1rem;
border-radius: .3rem;
border: none;
outline: none;
&::placeholder{
    /* color:#d3d3d3; */
}

`

export const Button = styled.div`
width: 200px;
    height: 40px;
    border: none;
    border-radius: 30px;
    background: #00509D;
    color: #fff;
    margin-top: 2.5rem;
    display: flex;
 
    justify-content: center;

    align-items: center;
    cursor: pointer;
`