import React, { useState, useEffect } from "react";
import { ContentWrapper, CustomModal } from "../../component";
import {
  ModalColumn,
  ModalHeader,
  ModalRow,
} from "../../component/custom-modal/styles/custom-modal";
import DashboardContainer from "../../container/dashboardContainer";
import { PlayerContainer, Select } from "./players-styles/players";
import { DataSearch } from "./reward-styles/reward-styles";
import {
  FormInput,
  MyFormInputWrapper,
} from "./setting-styles/setting-styles";
import { OverviewHeader } from "./user-dashboard-styles/user-dashboard-styles";
import FeatherIcon from "feather-icons-react";
import {
  GamesButton,
  InputLabel,
  FlexContainer,
} from "./games-styles/games-styles";
import { isAuthenticated } from "../../utils/data";
import { applyFilters } from "../../utils/filter";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { Loading } from "../../container/loading";

const ScrambleActivities = () => {

  const [update, setUpdate] = useState();
  const [loading, setLoading] = useState(false);
  const {
    obj: { token },
  } = isAuthenticated();
  // const [page, setPage] = useState(0)
  const [size, setPageSize] = useState(10);
  const [historyList, setHistory] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(20);

  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
//   const transactions = words?.slice(indexOfFirstPost, indexOfLastPost);
 

  
//   const PageCount = Math.ceil(count / size);

  const changePage = ({ selected }) => {
    const filter = {
      page: selected,
    };
    
  };
  const formatTime = (time) => {
    return moment(time).calendar();
  };

 
  const getAllCrossWordsHistory = async () => {
    setLoading(true)
    try {
      const filter = {
        page: 0,
        size: 1000,
        orderColumn: "id",
        orderDirection: "DESC",
      };
      const URL = applyFilters(
        `${process.env.REACT_APP_API_URL}/games/crossword-game-answer`,
        { ...filter }
      );

      const { data } = await axios.get(URL, {
        headers: {
          Authorization: ` ${token}`,
        },
      });
        //  let sort = data?.obj?.crossWordUserAnswers?.sort((a,b) => new Date(a) < new Date(b) ? 1 : -1)
        setHistory(data?.obj?.crossWordUserAnswers)
       setLoading(false);
       console.log("HISTORY", data?.obj)
       } catch (error) {
      if (error instanceof Error) {
        if (error.message === "Network Error") {
          toast.error("Please check your network connection and try again!");
          setLoading(false);
        } else {
          toast.error("System Error!");
          setLoading(false);
        }
      }
      setLoading(false);
    }
  };

  
  

  useEffect(() => {
    getAllCrossWordsHistory();
  }, []);


  return (
    <DashboardContainer>

      <ContentWrapper style={{height:'100vh'}}>
        <PlayerContainer>
          <OverviewHeader>SCRAMBLE ACTIVITIES</OverviewHeader>
         {!loading &&(
              <div style={{ display: "flex", justifyContent: "space-between" }}>
             
              {/* <GamesButton>Total: {words?  words?.count :'0'}</GamesButton> */}
            </div>
         )}

          <div className="players-table">
            {loading && <Loading />}
            {!loading && (
              <table className="players">
                <tbody>
                  <tr>
                    <th>GAME ID</th>
                    <th>USER ID</th>
                    <th>SCRAMBLED WORDS</th>
                    <th>USER ANSWERS</th>
                    <th>IS-CORRECT</th>
                    <th>COIN AWARDED</th>
                    <th>DATE</th>
                  </tr>

                  {historyList?.map((history, i) => (
                    <tr key={i}>
                      <td>#{history.crosswordId}</td>
                      <td>{history.createdBy}</td>
                      <td>{history?.crossWordGame?.scrambledText}</td>
                      <td className="points">{history?.userAnswerText}</td>
                      <td className="points" style={{color:history?.wasCorrectAnswer==='YES' ? '#09b709': 'red'}}>
                         {history?.wasCorrectAnswer}
                      </td>
                      <td className="points" style={{color:'yellow'}}>
                         {history?.coinAward}
                      </td>
                     
                      <td>{formatTime(history?.createdTime)}</td>
                     
                     
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>

{/*          
          <div style={{ justifyContent: "center", display: "flex" }}>
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={PageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div> */}
        </PlayerContainer>
      </ContentWrapper>
      <Toaster />
    </DashboardContainer>
  );
};

export default ScrambleActivities;

