import React, { useState, useEffect } from "react";
import axios from "axios";
import DashboardContainer from "../../container/dashboardContainer";
import {
  ContentWrapper,
  CustomModal,
  OnboardingWrapper,
} from "../../component";
import {
  PlayerContainer,
  PlayerHeader,
  Select,
} from "./players-styles/players";
import { OverviewHeader } from "./user-dashboard-styles/user-dashboard-styles";
import {
  TabContent,
} from "../../container/table-styles/table-styles";
import {
  GamesButton,
} from "./games-styles/games-styles";
import FeatherIcon from "feather-icons-react";
import { useHistory } from "react-router-dom";
import { isAuthenticated } from "../../utils/data";
import moment from "moment";
import DatePicker from "react-datepicker";
import toast, { Toaster } from "react-hot-toast";

import {
  FormInput, 
} from "./setting-styles/setting-styles";
import AllGuessByCategory from "./guess-questions-state/all-guess-by-category";
import GuessOption from "./guess-option";


const GuessQuestions = ({match}) => {
  const {
    obj: { token },
  } = isAuthenticated();
  const history = useHistory();
  const [step, setStep] = useState("questions");

  const [spinner, setSpinner] = useState(false);
  const [updateDom, setUpdateDom] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const [catId, setCatId] = useState();
  
  const [questionModal, setQuestionModal] = useState(false);
  const [categoryQuestions, setCategoryQuestions] = useState();
  const [loading, setLoading] = useState(false);
  const [categoryName, setCategoryName] = useState();
  const [getQuestion, setGetQuestion] = useState()
  const [questionStep, setQuestionStep] = useState(0);
  const [questionCreate, setQuestionCreate] = useState({});
  const [correctAnswer, setCorrectAnswer] = useState()
 
  const [options, setOptions] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [drawDate, setDrawDate] = useState(new Date());
  const [page, setPage] = useState('0')

  const size = 10

  const format = "YYYY-MM-DD HH:mm:ss"
  let start = moment(startDate).format(format);
  let end = moment(endDate).format(format);
  let drawTime = moment(drawDate).format(format);
    
  const checkStartDate = new Date(start).getTime();
  const checkEndDate=  new Date(end).getTime();
  const checkDrawDate=  new Date(drawTime).getTime(); 
  const currentTime =  new Date().getTime(); 
  

const validateTime = () => {
    if( (checkStartDate < checkEndDate || checkEndDate <= currentTime ) && currentTime <= checkDrawDate ){
      return true;
    }
  }

  const handleQuestionModal = () => {
    setQuestionModal(true);
    setQuestionStep(1);
  };

  const getCategoryQuestions = async (id) => {
    setSpinner(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/games/guess-game?guessCategoryId=${id}&page=${page}&size=${size}`,
        {
          headers: {
            Authorization: ` ${token}`,
          },
        }
      );

      console.log("sorted",data) 

      setSpinner(false);
      if (data) {
        setSpinner(false)
        setCategoryName(data?.obj?.guessCategory?.name)
        setCategoryQuestions(data?.obj?.guessGame?.sort((a,b) => new Date(a) < new Date(b) ? 1 : -1))
      }
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === "Network Error") {
          toast.error("Please check your network connection and try again!");
          setSpinner(false)
        } else {
          toast.error("System Error!");
          setSpinner(false)
        }
        setSpinner(false);
      }
    }
  }

  const deleteQuestion = async () => {
    setLoading(true);
    try {
      const { data } = await axios.delete(
        `${process.env.REACT_APP_API_URL}/games/guess-game/${getQuestion?.id}`,
        {
          headers: {
            Authorization: ` ${token}`,
          },
        }
      );

      setLoading(false);
      if (data) {
        setLoading(false)
        toast.success("Question deleted successfully!");
        setUpdateDom(data);
        setTimeout(()=>{
          setQuestionModal(false) 
         },500)
      }
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === "Network Error") {
          toast.error("Please check your network connection and try again!");
          setLoading(false)
        } else {
          toast.error("Failed to delete!");
          setLoading(false)
        }
        setLoading(false);
      }
    }
  }

 const correctOption = options?.filter((x)=> x.isCorrectAnswer==="YES");

 console.log("Correct Options", correctOption)

  const checkYeses = correctOption?.length

  

  const [values, setValues] = useState({
    id: "",
    answerText:'',
    questionText:'',
    optionText:'',
    errors: {
      nameError: "",
    },
  });

  const { answerText,questionText, id, optionText, errors } = values;


  console.log("QUESTIONS", questionCreate)

  const handleChange = (name) => (e) => {
    setValues({ ...values, errors: {}, [name]: e.target.value });
  };

  const createOptionQuestions = async () => {
    
    if(!questionText){
      toast.error('Guess Question is required');
      setLoading(false);
      }
      else if(checkYeses >= 1){
        toast.error('You cannot provide a correct answer while creating Guess question');
          setLoading(false)
      }
      // else if(checkYeses > 1){
      //   toast.error('Question should have only one correct answer');
      //     setLoading(false)
      // }
      else if(!startDate){
          toast.error('Start date is required');
          setLoading(false);
      }else if(!endDate){
        toast.error('End date is required');
        setLoading(false);
      }else if(checkStartDate >=checkEndDate){
        toast.error('End date should be more than start date');
        setLoading(false)  
      }
      else if(!drawDate){
        toast.error('Draw date is required');
        setLoading(false)  
      }else if(checkEndDate >=checkDrawDate){
        toast.error('Draw date should be more than end date');
        setLoading(false) 
      }
      else{
      try {
        const payload = [{
          guessGame: {
            questionText: questionText,
            answerText: correctOption?.[0]?.optionText,
            answerType: "OPTIONS",
            startDateTime: start,
            endDateTime: end,
            drawTime: drawTime    
             },   
             guessCategory: {
              id: parseInt(catId)
            },    
            guessOptionList:options
  
        }]
         console.log("OPTION PAYLOAD", payload)
  
        const {data} = await axios.post(
          `${process.env.REACT_APP_API_URL}/games/guess-game-bulk`,payload, 
          {
            headers: {
              Authorization: ` ${token}`,
            },
          }
        );
        if(data){
          toast.success("Question created successfully");
          setLoading(false)
          setValues({
            ...values, questionText:'',
            answerText:''
          });
          setUpdateDom(data);
        
        }
        
      } catch (error) {
        if (error instanceof Error) {
          if (error.message === "Network Error") {
            toast.error("Please check your network connection and try again!");
            setLoading(false);
          } else {
            toast.error(" Failed to create question!");
            setLoading(false);
          }
        }
      }

    }
  
  };

  console.log("CORRECT ANSWER", correctAnswer)

  const createFreeText = async()=>{
    setLoading(true)
    if(!questionText){
      toast.error('Guess Question is required');
      setLoading(false);
    }
      else if(!startDate){
          toast.error('Start date is required');
          setLoading(false);
      }else if(!endDate){
        toast.error('End date is required');
        setLoading(false);
      }else if(checkStartDate >=checkEndDate){
        toast.error('End date should be more than start date');
        setLoading(false)
      }
    else{
      try{
      const payload={        
              questionText: questionText,
              answerText: answerText,
              answerType: "FREE_TEXT",
              guessCategory: {
                id: parseInt(catId)
              },
             startDateTime: start,
             endDateTime: end,
             drawTime: drawTime        
            }

          const {data} = await axios.post(`${process.env.REACT_APP_API_URL}/games/guess-game`, payload,  
            {
              headers: {
                Authorization: ` ${token}`,
              }
            }
          )
          if(data){
            toast.success("Question created successfully");
            setLoading(false)
            setValues({
              ...values, questionText:'',
              answerText:''
            });
            setUpdateDom(data);
          }

      }catch(error){
        if (error instanceof Error) {
          if (error.message === "Network Error") {
            toast.error("Please check your network connection and try again!");
            setLoading(false);
          } else {
            toast.error(" Failed to create question!");
            setLoading(false);
          }
        }
      }
    }
  
   
  }


  const updateQuestion = async()=>{
    setLoading(true)
    if(!questionText){
      toast.error('Guess Question is required');
      setLoading(false);
    }
      else if(!startDate){
          toast.error('Start date is required');
          setLoading(false);
      }else if(!endDate){
        toast.error('End date is required');
        setLoading(false);
      }else if(checkStartDate >=checkEndDate){
        toast.error('End date should be more than start date');
        setLoading(false)
      }
    else{
      try{
      const payload={ 
              id: id,      
              questionText: questionText,
              answerText: answerText,
              answerType: questionCreate.answerType,
              guessCategory: {
                id: parseInt(catId)
              },
             startDateTime: start,
             endDateTime: end,  
             drawTime: drawTime   
           
          }

          console.log("PAyload", payload)

          const {data} = await axios.put(`${process.env.REACT_APP_API_URL}/games/guess-game`, payload,  
            {
              headers: {
                Authorization: ` ${token}`,
              }
            }
          )
          if(data){
            toast.success("Question updated successfully");
            setLoading(false)
            setUpdateDom(data);
            if(validateTime()){
              setTimeout(()=>{
                setQuestionModal(false) 
               },500)
            }else{
              setQuestionStep(4);
              setCorrectAnswer(data?.obj?.answerText);
           
            }
          }

      }catch(error){
        if (error instanceof Error) {
          if (error.message === "Network Error") {
            toast.error("Please check your network connection and try again!");
            setLoading(false);
          } else {
            toast.error(" Failed to create question!");
            setLoading(false);
          }
        }
      }
    }
  }


  const updateOptions = async()=>{
    setLoading(true)
    if(!questionText){
      toast.error('Guess Question is required');
      setLoading(false);
      }
   
      else if(checkYeses < 1 && currentTime > checkDrawDate ){
        toast.error('Question must have a correct answer');
        setLoading(false)
      }
      else if(checkYeses > 1 && currentTime > checkDrawDate ){
        toast.error('Question should have only one correct answer');
        setLoading(false)

      }  
      else if(!startDate){
          toast.error('Start date is required');
          setLoading(false);
      }else if(!endDate){
        toast.error('End date is required');
        setLoading(false);
      }else if(checkStartDate >=checkEndDate){
        toast.error('End date should be more than start date');
        setLoading(false)
      }
      else if(checkEndDate >=checkDrawDate){
        toast.error('Draw date should be more than end date');
        setLoading(false)
      }
    else{
      try{
      const payload=[
        {
          guessGame:{
            id:id,
            questionText: questionText,
            answerText: correctOption?.[0]?.optionText,
            answerType: questionCreate.answerType,
            startDateTime: start,
            endDateTime: end ,
            drawTime: drawTime
          },
             guessCategory: {
              id: parseInt(catId)
            },    
            guessOptionList:options
  
        }
      ]
         

          const {data} = await axios.put(`${process.env.REACT_APP_API_URL}/games/guess-game-bulk`, payload,  
            {
              headers: {
                Authorization: ` ${token}`,
              }
            }
          )
          if(data){
            console.log("PAOption Update", data)
            toast.success("Question updated successfully");
            setLoading(false)
            setUpdateDom(data);
            if(validateTime()){
              setTimeout(()=>{
                setQuestionModal(false) 
               },500)
            }else{
              setQuestionStep(4);
              setCorrectAnswer(data?.obj?.[0]?.guessResponse?.obj?.answerText)
            }
            
          }

      }catch(error){
        if (error instanceof Error) {
          if (error.message === "Network Error") {
            toast.error("Please check your network connection and try again!");
            setLoading(false);
          } else {
            toast.error(" Failed to create question!");
            setLoading(false);
          }
        }
      }
    }
  }


  const publishAnswer = async()=>{
    setLoading(true)
      try{
          
          let payload = {
            shouldPublish: true,
            referenceId: id
          }

          console.log("PAYLOAD", payload)
          const {data} = await axios.post(`${process.env.REACT_APP_API_URL}/games/guess-game/action/publish`, payload,  
            {
              headers: {
                Authorization: `${token}`,
              }
            }
          )
          if(data){
            toast.success("Answer published successfully");
            setLoading(false)
            setUpdateDom(data);
              setTimeout(()=>{
                setQuestionModal(false) 
               },500)  
          }

      }catch(error){
        if (error instanceof Error) {
          if (error.message === "Network Error") {
            toast.error("Please check your network connection and try again!");
            setLoading(false);
          } else {
            toast.error(" Failed to publish Answer!");
            setLoading(false);
          }
        }
      }
    
  }

  
 
  useEffect(() => {
     let catId = match.params.id;
     setCatId(catId)
     getCategoryQuestions(catId);

  }, [updateDom]);



 

  return (
    <DashboardContainer>
     

      {questionModal && (
        <CustomModal width={questionStep===3 || questionStep===4? "300px":"750px"} setShowModal={setQuestionModal}>
          {questionStep === 1 && (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                boxSizing: "border-box",
                padding: "10px 20px",
              }}
            >
              <h2 style={{ padding: "0 0 20px 0", color:'var(--humber-golden)' }}>Create Question</h2>
              <div style={{ display: "flex", width: "100%" }}>
                <FormInput
                  width="90%"
                  placeholder="Enter Guess Question"
                  value={questionText}
                  onChange={handleChange("questionText")}
                />
              </div>
             
              <div
                style={{
                  width: "100%",
                  margin: "20px 0",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <label
                  htmlFor="correctanswer"
                  style={{
                    color: "#c4c4c4",
                    paddingLeft: "10px",
                    fontSize: "13px",
                    paddingRight: "30px",
                  }}
                >
                  Answer Type
                </label>
                <Select
                  width="170px"
                  height="35px"
                  value={questionCreate.answerType}
                  onChange={(e) =>
                    setQuestionCreate({
                      ...questionCreate,
                      answerType: e.target.value,
                    })
                  }
                >
                  <option  value="">
                    Select Answer Type
                  </option>
                  <option value="OPTIONS">Options</option>
                  <option value="FREE_TEXT"> Free Text</option>
                </Select>
                
              </div>
              {/* <GuessOption /> */}
              {questionCreate?.answerType ==="OPTIONS" && (  
                  <GuessOption loading={loading} questionCreate={questionCreate} setOptions={setOptions} />
              )}
              {questionCreate.answerType === "FREE_TEXT" && (
                <div style={{ display: "flex", flexDirection:'column', width: "100%" }}>
                  <FormInput
                    width="90%"  disabled
                    placeholder="Guess Question Answer"
                    value={answerText}
                    onChange={handleChange('answerText')}
                   
                  /><br />
                  
                  
                </div>
              )}
             
             {(questionCreate.answerType === "OPTIONS" || questionCreate.answerType === "FREE_TEXT" ) && (
                 <div style={{display:'flex',   alignItem:'center', width:'100%'}}>
                 <div style={{display:'flex', flexDirection:'column', marginRight:'10px',}}>
                 <label
                    style={{
                      color: "#c4c4c4",
                      padding: "10px 0",
                      fontSize: "13px",
                    }}
              >
                Start date
              </label>
                   <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      // isClearable={true}
                      timeInputLabel="Time:"
                      dateFormat="MM/dd/yyyy h:mm aa"
                      showTimeInput
                      minDate={new Date()}
                    />

                 </div>
                  
                 <div style={{display:'flex', flexDirection:'column',marginRight:'10px'}}>
                 <label
                htmlFor="correctanswer"
                style={{
                  color: "#c4c4c4",
                  padding: "10px 0",
                  fontSize: "13px",
                  
                }}
              >
                End Date
              </label>
                   <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      // isClearable={true}
                      timeInputLabel="Time:"
                      dateFormat="MM/dd/yyyy h:mm aa"
                      showTimeInput
                      minDate={new Date()}
                    
                    />
                 </div>

                 <div style={{display:'flex', flexDirection:'column'}}>
                 <label
                htmlFor="correctanswer"
                style={{
                  color: "#c4c4c4",
                  padding: "10px 0",
                  fontSize: "13px",
                  
                }}
              >
                Draw Date
              </label>
                   <DatePicker
                      selected={drawDate}
                      onChange={(date) => setDrawDate(date)}
                      // isClearable={true}
                      timeInputLabel="Time:"
                      dateFormat="MM/dd/yyyy h:mm aa"
                      showTimeInput
                      minDate={new Date()}
                      // disabled={}
                    />
                 </div>

                 </div>

              )}
               
              <div>
              {questionCreate.answerType ==='FREE_TEXT' && (
                   <GamesButton  disabled={loading ? true : false} onClick={createFreeText}>
                   {loading ? "Creating..." : "Create Question"}
                 </GamesButton>
              )}
                 {questionCreate.answerType ==="OPTIONS" && (
                   <GamesButton  disabled={loading ? true : false} onClick={createOptionQuestions}>
                   {loading ? "Creating..." : "Create Question"}
                 </GamesButton>
              )}
              
              </div>
            </div>
          )}
           {questionStep === 2 && (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                boxSizing: "border-box",
                padding: "10px 20px",
              }}
            > 
              <div  style={{ padding: "0 0 20px 0",color:'#fff' }}>
                <h2>
                  {!validateTime() && questionCreate?.isPublished !=="YES" ? 'Publish Answer' : !validateTime() && questionCreate?.isPublished ==="YES"?
                   <div className='isPublished'>Answer Already Published <FeatherIcon icon='check-circle' /></div>:'Update Question'}</h2>
                  {!validateTime() && questionCreate?.isPublished !=="YES" && (
                    <div>Kindly enter the correct answer & publish</div>
                  )}
              </div>
             
              <div style={{ display: "flex", width: "100%" }}>
                <FormInput
                  width="90%"
                  placeholder="Enter Guess Question"
                  value={questionText}
                  onChange={handleChange("questionText")}
                  disabled={!validateTime()}
                />
              </div>
             
              <div
                style={{
                  width: "100%",
                  margin: "20px 0",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <label
                  htmlFor="correctanswer"
                  style={{
                    color: "#c4c4c4",
                    paddingLeft: "10px",
                    fontSize: "13px",
                    paddingRight: "30px",
                  }}
                >
                  Answer Type
                </label>
                <Select
                  width="170px"
                  height="35px"
                  disabled
                  value={questionCreate.answerType}
                  onChange={(e) =>
                    setQuestionCreate({
                      ...questionCreate,
                      answerType: e.target.value,
                    })
                  }
                >
                  <option  value="">
                    Select Answer Type
                  </option>
                  <option value="OPTIONS">Options</option>
                  <option value="FREE_TEXT"> Free Text</option>
                </Select>
                
              </div>
              {/* <GuessOption /> */}
              {questionCreate?.answerType ==="OPTIONS" && (  
                  <GuessOption loading={loading} questionCreate={questionCreate}
                   options={options} setOptions={setOptions} 
                    checkDrawDate={checkDrawDate}
                   currentTime={currentTime}/>
              )}
              {questionCreate.answerType === "FREE_TEXT" && (
                <div style={{ display: "flex", flexDirection:'column', width: "100%" }}>
                  <FormInput
                    width="90%"
                    placeholder="Guess Question Answer"
                    value={answerText}
                    onChange={handleChange('answerText')}
                    disabled={currentTime < checkDrawDate ? true : false}
                   
                   
                  /><br />
                  
                  
                </div>
              )}
             
             {(questionCreate.answerType === "OPTIONS" || questionCreate.answerType === "FREE_TEXT" ) && (
                 <div style={{display:'flex',   alignItem:'center', width:'100%'}}>
                 <div style={{display:'flex', flexDirection:'column', marginRight:'10px',}}>
                 <label
                    style={{
                      color: "#c4c4c4",
                      padding: "10px 0",
                      fontSize: "13px",
                    }}
              >
                Start date
              </label>
                   <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      // isClearable={true}
                      timeInputLabel="Time:" 
                      dateFormat="MM/dd/yyyy h:mm aa"
                      showTimeInput 
                      minDate={new Date()}
                      disabled={!validateTime()}
                     
                    />
 
                 </div> 
                  
                 <div style={{display:'flex', flexDirection:'column',marginRight:'10px'}}>
                 <label
                htmlFor="correctanswer"
                style={{
                  color: "#c4c4c4",
                  padding: "10px 0",
                  fontSize: "13px",
                  
                }}
              >
                End Date
              </label>
                   <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      // isClearable={true}
                      timeInputLabel="Time:"
                      dateFormat="MM/dd/yyyy h:mm aa"
                      showTimeInput
                      minDate={new Date()}
                      disabled={!validateTime()}
                     
                    />
                 </div>
                 <div style={{display:'flex', flexDirection:'column'}}>
                 <label
                htmlFor="correctanswer"
                style={{
                  color: "#c4c4c4",
                  padding: "10px 0",
                  fontSize: "13px",
                  
                }}
              >
                Draw Date
              </label>
                   <DatePicker
                      selected={drawDate}
                      onChange={(date) => setDrawDate(date)}
                      // isClearable={true}
                      timeInputLabel="Time:"
                      dateFormat="MM/dd/yyyy h:mm aa"
                      showTimeInput
                      minDate={new Date()}
                      disabled={!validateTime()}
                    
                    />
                 </div>

                 </div>

              )}
               
              <div>
              { questionCreate.answerType ==="FREE_TEXT" && !validateTime() && ( 
                   <GamesButton  disabled={loading || !validateTime() && questionCreate?.isPublished ==="YES"? true : false} onClick={updateQuestion}>
                   {loading ? "Updating..." : "Update & Publish"}
                 </GamesButton>)
                  }
                <>
                { questionCreate.answerType ==="OPTIONS" && !validateTime() ? 
                   <GamesButton  disabled={loading || !validateTime() && questionCreate?.isPublished ==="YES"? true : false} onClick={updateOptions}>
                   {loading ? "Updating..." : "Update & Publish"}
                 </GamesButton>
                : 
                 <>
                   {questionCreate.answerType ==='FREE_TEXT' && validateTime() && (
                   <GamesButton  disabled={loading ? true : false} onClick={updateQuestion}>
                   {loading ? "Updating..." : "Update Question"}
                 </GamesButton>
              )}
                 {questionCreate.answerType ==="OPTIONS" &&  (
                   <GamesButton  disabled={loading ? true : false} onClick={updateOptions}>
                   {loading ? "Updating..." : "Update Question"}
                 </GamesButton>
              )}
                 </>
                }
                </>
              </div>
            </div>
          )}

          {questionStep === 3 && (
           <div  className='delete-question' style={{textAlign:'center'}}>
           <FeatherIcon icon='info' color='#ed9717'  size='40'/>
          <h3>Are you sure</h3>
          <small>You want to delete this Question?</small>
           <div style={{display:'flex', gap:'20px'}}>
           <OnboardingWrapper.Button bgColor='red' width='150px' onClick={deleteQuestion}>
            {loading ? <small>Loading...</small> : "Yes "}
          </OnboardingWrapper.Button>
          <OnboardingWrapper.Button onClick={()=>setShowModal(false)}   bgColor='#ccc' width='150px'>
            {"Cancel "}
          </OnboardingWrapper.Button>
           </div>
          </div>
          )} 

          {questionStep === 4 && (
           <div  className='publish-answer' style={{textAlign:'center'}}>
           <FeatherIcon icon='info' color='#ed9717'  size='40'/>
           <div >
            <h3 style={{color:'#fff'}}>Are you sure</h3>
              <small style={{color:'#fff'}}>You want to publish <strong style={{color:'#ed9717'}}>{correctAnswer}</strong> as the Correct Answer?</small>
             </div>
           <div style={{display:'flex', gap:'20px'}}>
           <OnboardingWrapper.Button bgColor='var(--gradient-primary)' width='150px' onClick={publishAnswer}>
            {loading ? <small>Loading...</small> : "Yes & Publish"}
          </OnboardingWrapper.Button>
          <OnboardingWrapper.Button onClick={()=>setQuestionStep(2)}   bgColor='#ccc' width='150px'>
            {"Not Sure "}
          </OnboardingWrapper.Button>
           </div>
        </div>
          )} 

        </CustomModal>
      )}

      
    
      <ContentWrapper style={{height: '100%'}}>
        <PlayerContainer>
          <OverviewHeader>{categoryName || ''} QUESTIONS</OverviewHeader>
          <PlayerHeader>
            <div onClick={() => history.push("/guess-create")}>
              <FeatherIcon icon="arrow-left" />
              All Guess Games
            </div>
          </PlayerHeader>
      
          {step === "questions" && (        
            <TabContent>

                <GamesButton   disabled={loading ? true : false} onClick={handleQuestionModal}>
                  <FeatherIcon icon="plus" size="15px" />
                  Add Question
                </GamesButton>
                <br /> <br /> 
               
              <AllGuessByCategory
               values={values}
               setValues={setValues}
               showModal={questionModal} 
               setShowModal={setQuestionModal} 
               setQuestionStep={setQuestionStep}
              setGetQuestion={setGetQuestion} 
              questionCreate={questionCreate}
              setQuestionCreate={setQuestionCreate} 
              getQuestion={getQuestion} 
              categoryQuestions={categoryQuestions} 
              spinner={spinner} 
              setOptions={setOptions}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setDrawDate={setDrawDate}
            
             

              />
            
            </TabContent>
          )}
        </PlayerContainer>
      </ContentWrapper>
      <Toaster position="top-center" />
    </DashboardContainer>
  );
};

export default GuessQuestions;
