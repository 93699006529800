import styled from 'styled-components/macro'

export const DataSearchContainer = styled.div`
display: flex;
position: relative;

@media (max-width: 600px){
    width: 100%;
}
`
export const DataSearch = styled.input.attrs({
    type: 'text'
})`
width: 300px;
// height: 50px;
border: 1px solid #FA8833;
box-sizing: border-box;
border-radius: 10px;
background: a3a3a3;
padding: .7rem 2rem .7rem 2.5rem;
font-size: 14px;
outline: none;
color: #a7a7a7;

@media (max-width:600px){
    width: 100%; 
}
`
export const RewardsDiv = styled.div`
display:flex;
justify-content: space-between;
align-items: center;
gap:20px;

@media (max-width:600px){
    gap: 20px;
    flex-direction: column;
    align-items: flex-start;
}
`