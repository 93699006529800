import React from 'react';
import {Container, Row, Column, Title, List, Item, Form, FormGroup, Input, Button} from './styles/footer'

const Footer = ({children,...resProps}) => {
    return (
        <Container  {...resProps}>
             {children}
        </Container>
    )
}

export default Footer


Footer.Row = function FooterRow({children, ...resProps}){
    return <Row {...resProps}>{children}</Row>
}

Footer.Column = function FooterColumn({children, ...resProps}){
    return <Column {...resProps}>{children}</Column>
}

Footer.Title = function FooterTitle({children, ...resProps}){
    return <Title {...resProps}>{children}</Title>
}

Footer.List = function FooterList({children, ...resProps}){
    return <List {...resProps}>{children}</List>
}

Footer.Item = function FooterItem({children, ...resProps}){
    return <Item {...resProps}>{children}</Item>
}

Footer.Form = function FooterForm({children, ...resProps}){
    return <Form {...resProps}>{children}</Form>
}
Footer.FormGroup = function FooterFormGroup({children, ...resProps}){
    return <FormGroup {...resProps}>{children}</FormGroup>
}

Footer.Input = function FooterFormInput({ ...resProps}){
    return <Input {...resProps}  />
}

Footer.Button = function FooterButton({children, ...resProps}){
    return <Button {...resProps}>{children}</Button>
}



