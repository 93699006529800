import styled from "styled-components/macro";


export const Container = styled.div`

background:#000; 
display:flex; 
justify-content:center;
flex-direction: column;
align-items:center; 
padding:${({padding})=>padding};
overflow:hidden;
`

export const Row = styled.div`
display:flex;
justify-content:${({justifyContent})=>justifyContent};
background: ${({bgColor})=>bgColor} ;
position:relative; 
padding: ${({padding})=>padding};
width:1300px;
border-radius: ${({borderRadius})=>borderRadius}; 
border-top: ${({borderTop})=>borderTop};
border-bottom: ${({borderBottom})=>borderBottom};

`

export const Column = styled.div`
font-family:poppins;
width:100%; 
padding:0 3rem;

`

export const Contents = styled.div`
display:flex;
justify-content:space-between;
 align-items:center;
`

export const InnerContent = styled.div`

`

export const HeaderText = styled.p`
font-family:poppins;
font-size: 2.8rem;
font-weight: 700;
margin: 0;
color:#000;

`
export const Small = styled.small`
font-family:poppins;
font-weight:600;
color: #3e3e3e;
font-size: 1.1rem;

`

export const Button = styled.button`
width: 170px;
height: 50px;
background: #e30303;
color:#fff;
border:1px solid transparent;
border-radius: 4rem;
cursor: pointer;
margin-right: .5rem;
font-weight: 500;
font-size: 1.1rem;
transition: .3s ease-in;
text-transform: uppercase;

&:hover{
    background:#ec3535;
}

`

export const ImageBox = styled.div`
width:200px;
img{
 width:200px;
 position:absolute; 
  left:-1.5rem;
}
`

export const Results = styled.div`
border-top: 2px solid #c8c8c8;
    margin-top: .7rem;

p{
 font-weight:600;
 margin-bottom:0;
}
`

export const Countdown = styled.div`
color: #000;
margin-top: 0px;
margin-bottom: 0px;
background: #ffffff;
padding: .5rem 2rem;
border-radius: .5rem;
font-size: 2rem;
font-weight: 700;
min-width: 240px;
span{
    max-width: 50px;
}

small{
    font-size: 1rem;
    color:#6a6a6a;
}
`

export const List = styled.div`
  display: flex;
  list-style-type:none;
  justify-content: center;
  padding: 1rem 0;
`

export const ListItem = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #f3c70e;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-family: poppins;
    font-weight: 700;
    margin-right: .6rem;
    border: 2px solid #000;

`