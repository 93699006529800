import React from 'react'
import { ContentWrapper } from '../component'
import DashboardContainer from '../container/dashboardContainer'


const Reports = () => {


// guess-game-category

    return ( 
        <DashboardContainer>
            <ContentWrapper>
                
            </ContentWrapper>
        </DashboardContainer>
    
    )
}

export default Reports
