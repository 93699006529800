import React from 'react'
import {Container, Body, Row, Title, Subtitle, Text} from './styles/main'

const MainWrapper = ({children, ...resProps}) => {
    return (
        <Container {...resProps}>
            {children}
        </Container>
    )
}

export default MainWrapper 


MainWrapper.Body = function MainWrapperBody({children, ...resProps}){
    return <Body {...resProps}>{children}</Body>
}

MainWrapper.Row = function MainWrapperRow ({children, ...resProps}){
    return <Row {...resProps}>{children}</Row>
}
MainWrapper.Title = function MainWrapperTitle ({children, ...resProps}){
    return <Title {...resProps}>{children}</Title>
}
MainWrapper.Subtitle = function MainWrapperSubtitle ({children, ...resProps}){
    return <Subtitle {...resProps}>{children}</Subtitle>
}
MainWrapper.Text = function MainWrapperText ({children, ...resProps}){
    return <Text {...resProps}>{children}</Text>
}