import React, { useState, useEffect } from "react";
import {
  ContentWrapper,
  CustomModal,
  OnboardingWrapper,
} from "../../component";
import DashboardContainer from "../../container/dashboardContainer";
import axios from "axios";
import FeatherIcon from "feather-icons-react";
import { useHistory, Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import {
  OverviewColumn,
  OverviewHeader,
  OverviewContainer,
  OverviewInner,
  OverviewRow,
  OverviewTitle,
} from "./user-dashboard-styles/user-dashboard-styles";
import { TableContainer } from "./players-styles/players";
import { Loading, Loading2 } from "../../container/loading";
import {
  Button,
  HeaderWrapper,
  PlayerColumn,
  PlayerContainer,
  PlayerHeader,
  PlayerSearch,
} from "./players-styles/players";
import moment from "moment";
import { isAuthenticated } from "../../utils/data";
import { GamesButton } from "./games-styles/games-styles";
import EditReward from "./edit-reward";
import DatePicker from "react-datepicker";

import { FormInput } from "./setting-styles/setting-styles";

const ManageRewards = () => {
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [activities, setActivities] = useState([]);
  const [itemDetail, setItem] = useState({});
  const [update, setUpdate] = useState();
  const {
    obj: { token },
  } = isAuthenticated();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [drawDate, setDrawDate] = useState(new Date());
  const [AvartarModal, setAvartarModal] = useState(false);
  const [screen, setScreen] = useState(1);
  const history = useHistory();
  const [activityId, setActivityId] = useState();
  const [toggle, setToggle] = useState("create");
  const [activity, setActivity] = useState({});

  const [values, setValues] = useState({
    activityTitle: "",
    id: "",
    activityText: "",
    maxSelection: "",
    error: "",
  });
  const format = "YYYY-MM-DD HH:mm:ss";
  let start = moment(startDate).format(format);
  let end = moment(endDate).format(format);
  let draw = moment(drawDate).format(format);
  const {
    activityTitle,
    activityText,
    drawTime,
    startDateTime,
    endDateTime,
    maxSelection,
    id,
    error,
  } = values;

  const getActivitiesList = async () => {
    setLoading1(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/games/avatar-activity`,
        {
          headers: {
            Authorization: ` Bearer ${token}`,
          },
        }
      );

      console.log(data);
      setLoading(false);

      setActivities(
        data?.obj?.avatarActivities?.sort((a, b) =>
          new Date(a) < new Date(b) ? 1 : -1
        )
      );
    } catch (error) {
      setLoading(false);
    }
  };
  const deleteActivity = async () => {
    setAvartarModal(true);
    try {
      const { data } = await axios.delete(
        `${process.env.REACT_APP_API_URL}/games/avatar-activity/${activityId}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      getActivitiesList();
      setAvartarModal(false);
      console.log(data);
      setLoading(false);
      toast.success("Activity Deleted successfull");
    } catch (error) {
      setLoading(false);
      if (error instanceof Error) {
        if (error.message === "Network Error") {
          toast.error("Network Error, Please check your network");
        }
      }
      if (error.response) {
        if (error.response.status === 401) {
          toast.error("Not authorized, Please Login again");
        } else {
          toast.error("System Error");
        }
      }
    }
  };
  const updateActivity = async (data) => {
    const payload = {
      id,
      activityTitle,
      activityText,
      endDateTime,
      maxSelection,
      startDateTime: start,
      endDateTime: end,
      drawTime: draw,
    };
    setLoading(true);
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/games/avatar-activity`,
        payload,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      getActivitiesList();
      setAvartarModal(false);
      console.log(data);
      setLoading(false);
      toast.success("Activity updated successfull");
    } catch (error) {
      setLoading(false);
      if (error instanceof Error) {
        if (error.message === "Network Error") {
          toast.error("Network Error, Please check your network");
        }
      }
      if (error.response) {
        if (error.response.status === 401) {
          toast.error("Not authorized, Please Login again");
        } else {
          toast.error("System Error");
        }
      }
    }
  };
  const handleChange = (name) => (e) => {
    setValues({ ...values, error: {}, [name]: e.target?.value });
  };
  const ConfirmPublish = (id) => {
    console.log(id);
    setToggle("publish");
  };
  const confirmDelete = (id) => {
    setActivityId(id);
    setAvartarModal(true);
    setToggle("delete");
  };
  const clearForms = () => {
    setValues({
      ...values,
      id: "",
      activityText: "",
      activityTitle: "",
      maxSelection: "",
      drawTime: "",
      startDateTime: "",
      endDateTime: "",
    });
    setEndDate(new Date());
    setDrawDate(new Date());
    setStartDate(new Date());
  };
  const EditActivity = (activity) => {
    setScreen(2);
    setAvartarModal(true);
    setActivity(activity);
    setValues({
      ...values,
      id: activity?.id,
      activityText: activity?.activityText,
      activityTitle: activity?.activityTitle,
      maxSelection: activity?.maxSelection,
      drawTime: activity?.drawTime,
      startDateTime: activity?.startDateTime,
      endDateTime: activity?.endDateTime,
    });

    setStartDate(new Date(activity?.startDateTime));
    setEndDate(new Date(activity?.endDateTime));
    setDrawDate(new Date(activity?.drawTime));
  };

  const publishActivity = async () => {
    console.log(values);
    console.log(id);
    setLoading2(true);
    const payload = {
      shouldPublish: true,
      referenceId: id,
    };
    console.log(payload);

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/games/avatar-activity/action/publish`,
        payload,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      setLoading2(false);
      toast.success("Activity published");
      getActivitiesList();
      setAvartarModal(false);
    } catch (error) {
      setLoading2(false);
      console.log(error.response);
      if (error instanceof Error) {
        if (error.message == "Network Error") {
          toast.error(
            "Error in network connection, please check your network connection"
          );
        }
      }
      if (error.response) {
        toast.error(error.response.data.desc);
      }
    }
  };

  const SheduleActivity = async () => {
   
    setLoading2(true);
    
    
    const payload = {
      shouldDisplay: true,
      referenceId: activityId,
    };
    try {
      const { data } = await axios.post(
        `https://staging.api.biggies.humbergames.com/games/avatar-activity/scheduled`,
        payload,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      if (data) {
        toast.success("Activity scheduled successfull");
      }
      setAvartarModal(false)

      getActivitiesList();
      setLoading2(false);
    } catch (err) {
      setLoading2(false);
      if (err instanceof Error) {
        if (err.Message == "Network Error") {
          toast.error("Network Error, Please check your network");
        }
      }
      if (err.response) {
        console.log(err.response);
        toast.error(err.response.data.desc);
      }
    }
  };

  const confirmDisplay = (id) => {
    setToggle("schedule");
    console.log(id);
    setAvartarModal(true);
    setActivityId(id);
  };
  const CreateActivity = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!activityTitle) {
      setLoading(false);
      setValues({ ...values, error: { titleError: "Title required" } });
    } else if (!activityText) {
      setLoading(false);
      setValues({
        ...values,
        error: { textError: "Activity description required" },
      });
    } else if (!maxSelection) {
      setLoading(false);
      setValues({ ...values, error: { maxEntryError: "Max entry required" } });
    } else if (!draw) {
      setLoading(false);
      toast.error("draw date is required");
    } else if (!start) {
      setLoading(false);
      toast.error("Start date is required");
    } else if (!end) {
      setLoading(false);
      toast.error("End date is required");
    } else {
      const payload = {
        activityTitle,
        activityText,
        maxSelection,
        startDateTime: start,
        endDateTime: end,
        drawTime: draw,
      };

      console.log(payload);

      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_API_URL}/games/avatar-activity`,
          payload,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );
        getActivitiesList();
        console.log(data);
        setLoading(false);
        toast.success("Activity created successfull");
      } catch (error) {
        setLoading(false);
        if (error instanceof Error) {
          if (error.message === "Network Error") {
            toast.error("Network Error, Please check your network");
          }
        }
        if (error.response) {
          if (error.response.status === 401) {
            toast.error("Not authorized, Please Login again");
          } else {
            toast.error("System Error");
          }
        }
      }
    }
  };

  useEffect(() => {
    getActivitiesList();
  }, [update]);
  return (
    <DashboardContainer>
      <ContentWrapper style={{ height: "100vh" }}>
        <PlayerContainer>
          <OverviewContainer>
            <OverviewHeader color="var(--humber-golden)">
              <FeatherIcon
                type="button"
                onClick={() => history.goBack()}
                icon="arrow-left"
                size="15px"
              />{" "}
              Ultimate Contestant
            </OverviewHeader>
            <GamesButton
              border="none"
              onClick={() => {
                setAvartarModal(true);
                clearForms();
                setScreen(1);
                setToggle("create");
              }}
            >
              <FeatherIcon icon="plus" size="15px" />
              create activity
            </GamesButton>

            <TableContainer style={{ overflowX: "auto" }}>
              <br />

              {loading ? (
                <Loading />
              ) : (
                <table className="reward-table">
                  <tbody>
                    <tr>
                      <th>ID</th>
                      <th>Title</th>
                      <th>Description</th>
                      <th>Max selection</th>
                      <th>Result Taken</th>
                      <th>Active</th>
                      <th>Published</th>
                      <th>Start date</th>
                      <th>End date</th>
                      <th>Action</th>
                    </tr>
                    {activities?.map((activity, i) => (
                      <tr className="hover_activity" key={i}>
                        <td
                          onClick={() =>
                            history.push(`/avatar-activities/${activity?.id}`)
                          }
                        >
                          {activity?.id}
                        </td>
                        <td
                          onClick={() =>
                            history.push(`/avatar-activities/${activity?.id}`)
                          }
                        >
                          {activity?.activityTitle}
                        </td>
                        <td
                          onClick={() =>
                            history.push(`/avatar-activities/${activity?.id}`)
                          }
                        >
                          {activity?.activityText}
                        </td>
                        <td>{activity?.maxSelection}</td>
                        <td>{activity?.resultTaken}</td>
                        <td>
                          {activity?.isDisplayed ? "Active" : "Not Active"}
                        </td>
                        <td>{activity?.isPublished}</td>
                        <td>
                          {activity?.startDateTime
                            ? moment(activity?.startDateTime).format("ll")
                            : "No Date"}
                        </td>
                        <td>
                          {activity?.endDateTime
                            ? moment(activity?.endDateTime).format("ll")
                            : "No Date"}
                        </td>
                        <td>
                          <div className="d-flex">
                            <div className="cat-action-btn">
                              <FeatherIcon
                                onClick={() => {
                                  EditActivity(activity);
                                  setToggle("create");
                                }}
                                icon="edit-3"
                              />
                              <FeatherIcon
                                onClick={() => confirmDelete(activity?.id)}
                                icon="trash-2"
                              />
                            </div>
                            <div
                              disabled={activity?.isDisplayed}
                              role="button"
                              onClick={() =>
                                activity.isDisplayed !== true
                                  ? confirmDisplay(activity?.id)
                                  : ""
                              }
                              className={`${
                                activity?.isDisplayed ? "enable" : "disabled"
                              }`}
                              style={{ margin: "10px" }}
                            >
                              {activity?.isDisplayed ? "Enabled" : "Disabled"}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </TableContainer>
          </OverviewContainer>

          {/* < ReactPaginate
                        previousLabel={"previous" }
                        nextLabel={ "next" }
                        breakLabel={ "..." }
                        breakClassName={ "break-me" }
                        pageCount={ pageCount }
                        onPageChange={ handlePageClick }
                        containerClassName={ "pagination" }
                        subContainerClassName={ "pages pagination" }
                        activeClassName={ "active" } /> */}
        </PlayerContainer>
        {AvartarModal && (
          <CustomModal setShowModal={setAvartarModal}>
            {toggle == "create" && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  boxSizing: "border-box",
                  padding: "10px 20px",
                }}
              >
                {activity?.isPublished == "YES" && (
                  <div className="isPublished">
                    Activity Already Published{" "}
                    <FeatherIcon icon="check-circle" />
                  </div>
                )}

                <h2
                  style={{
                    padding: "0 0 20px 0",
                    color: "var(--humber-golden)",
                  }}
                >
                  {screen == 1 ? "Create" : "Update"} Activity
                </h2>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    marginBottom: "10px",
                  }}
                >
                  <FormInput
                    style={{ border: error?.titleError ? "1px solid red" : "" }}
                    width="90%"
                    placeholder="Enter Activity title"
                    onChange={handleChange("activityTitle")}
                    value={activityTitle}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    marginBottom: "10px",
                  }}
                >
                  <FormInput
                    style={{ border: error?.textError ? "1px solid red" : "" }}
                    width="90%"
                    placeholder="Enter Activity Description"
                    onChange={handleChange("activityText")}
                    value={activityText}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    marginBottom: "10px",
                  }}
                >
                  <FormInput
                    style={{
                      border: error?.maxEntryError ? "1px solid red" : "",
                    }}
                    width="90%"
                    placeholder="Enter Max selection"
                    type="number"
                    onChange={handleChange("maxSelection")}
                    value={maxSelection}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItem: "center",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginRight: "10px",
                    }}
                  >
                    <label
                      style={{
                        color: "#c4c4c4",
                        padding: "10px 0",
                        fontSize: "13px",
                      }}
                    >
                      Start date
                    </label>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      // isClearable={true}
                      timeInputLabel="Time:"
                      dateFormat="MM/dd/yyyy h:mm aa"
                      showTimeInput
                      minDate={new Date()}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginRight: "10px",
                    }}
                  >
                    <label
                      htmlFor="correctanswer"
                      style={{
                        color: "#c4c4c4",
                        padding: "10px 0",
                        fontSize: "13px",
                      }}
                    >
                      End Date
                    </label>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      // isClearable={true}
                      timeInputLabel="Time:"
                      dateFormat="MM/dd/yyyy h:mm aa"
                      showTimeInput
                      minDate={new Date()}
                    />
                  </div>

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label
                      htmlFor="correctanswer"
                      style={{
                        color: "#c4c4c4",
                        padding: "10px 0",
                        fontSize: "13px",
                      }}
                    >
                      Draw Date
                    </label>
                    <DatePicker
                      selected={drawDate}
                      onChange={(date) => setDrawDate(date)}
                      // isClearable={true}
                      timeInputLabel="Time:"
                      dateFormat="MM/dd/yyyy h:mm aa"
                      showTimeInput
                      minDate={new Date()}
                      // disabled={}
                    />
                  </div>
                </div>

                <div>
                  {screen === 1 && (
                    <>
                      <GamesButton border="none" onClick={CreateActivity}>
                        {`${loading ? "Creating...." : "Create Activity"}`}
                      </GamesButton>{" "}
                    </>
                  )}
                  {screen === 2 && (
                    <>
                      <GamesButton
                        disabled={activity?.isPublished == "YES"}
                        border="none"
                        onClick={updateActivity}
                      >
                        {`${loading ? "Updating...." : "Update Activity"}`}
                      </GamesButton>{" "}
                      {activity?.isPublished !== "YES" && (
                        <GamesButton
                          border="none"
                          onClick={() => ConfirmPublish(id)}
                        >
                          {"Publish"}
                        </GamesButton>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}

            {toggle == "delete" && (
              <div className="delete-cat-info" style={{ textAlign: "center" }}>
                <FeatherIcon icon="info" color="#ed9717" size="40" />
                <h3 style={{ color: "var(--humber-light)" }}>Are you sure</h3>
                <small style={{ color: "var(--humber-light)" }}>
                  You want to delete this Avatar?
                </small>
                <div style={{ display: "flex", gap: "20px" }}>
                  <OnboardingWrapper.Button
                    bgColor="red"
                    width="150px"
                    onClick={deleteActivity}
                  >
                    {loading ? <small>Deleting...</small> : "Yes "}
                  </OnboardingWrapper.Button>
                  <OnboardingWrapper.Button
                    onClick={() => setAvartarModal(false)}
                    bgColor="#ccc"
                    width="150px"
                  >
                    {"Cancel "}
                  </OnboardingWrapper.Button>
                </div>
              </div>
            )}
            {toggle == "publish" && (
              <div className="delete-cat-info" style={{ textAlign: "center" }}>
                <FeatherIcon icon="info" color="#ed9717" size="40" />
                <h3 style={{ color: "var(--humber-light)" }}>Are you sure</h3>
                <small style={{ color: "var(--humber-light)" }}>
                  You want to Publish this activity ?
                </small>
                <div style={{ display: "flex", gap: "20px" }}>
                  <OnboardingWrapper.Button
                    bgColor="red"
                    width="150px"
                    onClick={publishActivity}
                  >
                    {loading2 ? <small>Publishing...</small> : "Yes "}
                  </OnboardingWrapper.Button>
                  <OnboardingWrapper.Button
                    onClick={() => setAvartarModal(false)}
                    bgColor="#ccc"
                    width="150px"
                  >
                    {"Cancel "}
                  </OnboardingWrapper.Button>
                </div>
              </div>
            )}
            {toggle == "schedule" && (
              <div className="delete-cat-info" style={{ textAlign: "center" }}>
                <FeatherIcon icon="info" color="#ed9717" size="40" />
                <h3 style={{ color: "var(--humber-light)" }}>Are you sure</h3>
                <small style={{ color: "var(--humber-light)" }}>
                  You want to Activate this activity ?
                </small>
                <div style={{ display: "flex", gap: "20px" }}>
                  <OnboardingWrapper.Button
                    bgColor="red"
                    width="150px"
                    onClick={SheduleActivity}
                  >
                    {loading2 ? <small>Publishing...</small> : "Yes "}
                  </OnboardingWrapper.Button>
                  <OnboardingWrapper.Button
                    onClick={() => setAvartarModal(false)}
                    bgColor="#ccc"
                    width="150px"
                  >
                    {"Cancel "}
                  </OnboardingWrapper.Button>
                </div>
              </div>
            )}
          </CustomModal>
        )}
      </ContentWrapper>
      <Toaster position="top-center" />
    </DashboardContainer>
  );
};

export default ManageRewards;
