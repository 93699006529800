import React, { useState, useLayoutEffect } from 'react'
import {OnboardingWrapper } from '../component'
import { Input, Label, } from '../pages/dashboard/players-styles/players'
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { isAuthenticated } from '../utils/data'
import { FormGroup, SettingsContainer, SettingsRow, Subtitle } from '../pages/dashboard/setting-styles/setting-styles'
import { Loading } from '../container/loading'

const CurrencySettings = () => {

    const { obj: { token } } = isAuthenticated()

    const [gameCurrency, setGameCurrency] = useState({
      id: '',
      currency: "NGN",
      currencyAmount: '',
      tokenValue: '',
      meta: {},
      error:''
    })
    const [loading, setLoading] = useState(false);
  
    const { currencyAmount, tokenValue, error } = gameCurrency;
  
  const handleChange = name => e => {
    setGameCurrency({ ...gameCurrency,error:'', [name]: e.target.value });
  }

  const getCurrency = async () => {

    setLoading(true)
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/payment/api/wallet-setup/get-currency`, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
    setLoading(false)
    setGameCurrency({
      ...gameCurrency,
      id: data?.obj?.id,
      currencyAmount: data?.obj?.currencyAmount,
      tokenValue: data?.obj?.tokenValue,
    });
  
  }


  const saveSettings = async (e) => {

    e.preventDefault();
    setLoading(true);
    if(currencyAmount?.length < 3){
       setGameCurrency({...gameCurrency, error:'Minimum value should be N100'})
       setLoading(false);
    }else{
      try {

        let payload = gameCurrency
  
        console.log("PAYLOAD", payload)
  
        const { data } = await axios.put(`${process.env.REACT_APP_API_URL}/payment/api/wallet-setup/update-currency`, payload, {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        })
        console.log("RESPONSE", data)
        setLoading(false)
  
        if (data?.status === 1) {
  
          toast.error(data?.desc);
          setLoading(false)
        } else {
          if (data?.status === 0) {
            toast.success(`Currency settings save successful!`);
  
            setLoading(false)
          }
  
        }
  
      } catch (error) {
  
        if (error instanceof Error) {
          if (error.message === 'Network Error') {
            toast.error('Please check your network connection!');
            setLoading(false)
          } else {
            setLoading(false)
          }
  
        } else {
  
        }
  
      }
    }
    
  }

  useLayoutEffect(()=>{
    getCurrency();
  },[])

    return (
        <div className='player-form'>
        <SettingsContainer bgColor="#000">

          <div>
            <h3>Game Currency</h3>
            <small>Naira conversion  to Token</small>
            <SettingsRow>
              <FormGroup >
                <Label>Naira</Label>
                <Input type="number" value={currencyAmount} placeholder="N100" onChange={handleChange('currencyAmount')} />
                {error && (
                  <OnboardingWrapper.Error>{error}</OnboardingWrapper.Error>
                )}
               </FormGroup>

              <FormGroup >
                <Label>Token</Label>
                <Input type="number" value={tokenValue} placeholder='1 token' onChange={handleChange('tokenValue')} />
             
              </FormGroup>
              
            </SettingsRow>
          </div>
           <br/>
          <OnboardingWrapper.Button onClick={saveSettings}>{loading ? <small>Loading...</small> : "Save "}</OnboardingWrapper.Button>
        </SettingsContainer>

      </div>
     
    )
}

export default CurrencySettings
