import styled from 'styled-components/macro'

export const TabHeader = styled.div`
display:flex;
align-items: center;
gap:20px;

@media (max-width:600px){
    gap: 5px;
}
`


export const TabContent = styled.div`
 
 .dashboard_table{
     border:none;

     th,td{
         padding:1rem;
     }

     td:nth-child(1){
        max-width: 305px;

     }
 }

`

export const TabList = styled.div`
min-width: ${({minw}) => minw ? '110px': 0};
display: flex;
align-items: center;
justify-content: center;
padding: ${({pad})=> pad ? '0 10px': 0};
flex: 1;
height: 40px;
font-weight: ${({activeClass, notbold})=> activeClass ? '400' : notbold ? '100' : '600'};
border:1px solid #d3d3d3;
border-radius: 5px;
cursor: pointer;
background: ${({activeClass})=> activeClass ? '#ed9717' : '#fff'};
color: ${({activeClass})=> activeClass ? '#fff' : ''};
${({notbold})=> notbold ? 'font-size: 13px' : ''};


`

export const CheckBox = styled.input.attrs(props => ({
type: "checkbox",
size: props.size || "1em",
}))`
border: 2px solid palevioletred;
margin: ${props => props.size};
padding: ${props => props.size};
`

export const Drop = styled.div`

` 