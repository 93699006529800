import React from 'react'
import {Container, Filler,Label} from './styles/progress-bar'

const ProgressBar = ({completed,...resProps}) => {
    return (
        <Container {...resProps}>
        <Filler  completed={completed}>  
          <Label  {...resProps}>{`${completed}%`}</Label>
        </Filler>
      </Container>
    )
}

export default ProgressBar
