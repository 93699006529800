import React, { useState, useLayoutEffect } from "react";
import { ContentWrapper, MainWrapper } from "../../component";
import "react-multi-carousel/lib/styles.css";
import FeatherIcon from "feather-icons-react";
import DashboardContainer from "../../container/dashboardContainer";
import { Link } from "react-router-dom";
import axios from "axios";
import { isAuthenticated } from "../../utils/data";
import {
  OverviewColumn,
  OverviewContainer,
  TableContainer,
  OverviewHeader,
  OverviewIcon,
  OverviewInner,
  OverviewRow,
  OverviewTitle,
  Small,
} from "./user-dashboard-styles/user-dashboard-styles";
import { TabContent } from "../../container/table-styles/table-styles";
import { Loading, Loading2 } from "../../container/loading";
import toast, { Toaster } from "react-hot-toast";
import NumberFormat from "react-number-format";
import {UserStatRow } from "./players-styles/players";

const title = {
  color: "var(--humber-golden)",
  fontSize: "25px",
  marginLeft: "13px",
  marginLeft: "11px",
  marginBottom: "-15px",
  marginTop: "17px",
  justifyContent: "flex-start",
  display: "flex",
};

const UserDashboard = () => {
  const {
    obj: { name, token },
  } = isAuthenticated();

  const [leaderboard, setLeaderboard] = useState();
  const [players, setPlayers] = useState();
  const [wallet, setWallet] = useState();
  const [loading, setLoading] = useState(false);
  const [loadPlayers, setLoadPlayers] = useState(false);
  const [transactions, setTransactions] = useState();
  const [stat, setStat] = useState({});

  const getLeaderboard = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/payment/api/points/view-leaderboard`,
        {
          headers: {
            Authorization: ` Bearer ${token}`,
          },
        }
      );

      setLoading(false);
      setLeaderboard(data?.obj?.content);
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === "Network Error") {
          toast.error("Please check your network connection and try again!");
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    }
  };

  const getTransactionTotal = async () => {
    setLoadPlayers(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/payment/api/transactions/total`,
        {
          headers: {
            Authorization: ` Bearer ${token}`,
          },
        }
      );

      if (data) {
        setLoadPlayers(false);
        setTransactions(data);

        console.log("Trans", data);
      }
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === "Network Error") {
          toast.error("Please check your network connection and try again!");
          setLoadPlayers(false);
        } else {
          setLoadPlayers(false);
        }
      }
    }
  };

  const getPlayersCount = async () => {
    setLoadPlayers(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/auth/admin/user-count`,
        {
          headers: {
            Authorization: ` Bearer ${token}`,
          },
        }
      );

      if (data) {
        setLoadPlayers(false);
        setPlayers(data);
      }
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === "Network Error") {
          toast.error("Please check your network connection and try again!");
          setLoadPlayers(false);
        } else {
          setLoadPlayers(false);
        }
      }
    }
  };

  const getUserSum = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/auth/admin/registration-stat`,
        {
          headers: {
            Authorization: ` Bearer ${token}`,
          },
        }
      );

      setStat(data);
    } catch (err) {
      console.log(err.response);
    }
  };
  const getWallet = async () => {
    setLoadPlayers(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/payment/api/wallet/admin/balance`,
        // `${process.env.REACT_APP_API_URL}/user/api/v1/wallet/admin/balance`,
        {
          headers: {
            Authorization: ` Bearer ${token}`,
          },
        }
      );

      if (data) {
        setLoadPlayers(false);
        setWallet(data);
        console.log("wallet", data);
      }
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === "Network Error") {
          toast.error("Please check your network connection and try again!");
          setLoadPlayers(false);
        } else {
          setLoadPlayers(false);
        }
      }
    }
  };

  useLayoutEffect(() => {
    const abortController = new AbortController();
    getLeaderboard();
    getPlayersCount();
    getTransactionTotal();
    getWallet();
    getUserSum();
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <>
      <DashboardContainer>
        <ContentWrapper>
          <OverviewContainer>
            <OverviewHeader>Hi {name}!</OverviewHeader>
            <OverviewRow>
              <OverviewColumn border="none" bgColor="var(--humber-black)">
                <Link to="/players">
                  <OverviewInner>
                    <OverviewTitle>Registrations</OverviewTitle>
                    <OverviewIcon>
                      <FeatherIcon icon="users" />
                    </OverviewIcon>
                  </OverviewInner>
                  <OverviewInner>
                    <div>
                      <Small>Total</Small>
                      <OverviewTitle
                        color="var(--humber-light)"
                        size="2rem"
                        weight="600"
                      >
                        {loadPlayers ? (
                          <Loading2 />
                        ) : (
                          <NumberFormat
                            value={players?.Players || "0"}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        )}
                      </OverviewTitle>
                    </div>
                  </OverviewInner>
                </Link>
              </OverviewColumn>
              <OverviewColumn border="none" bgColor="var(--humber-black)">
                <Link to="/transactions">
                  <OverviewInner>
                    <OverviewTitle>Deposit</OverviewTitle>
                    <OverviewIcon>
                      <FeatherIcon icon="credit-card" />
                    </OverviewIcon>
                  </OverviewInner>
                  <OverviewInner>
                    <div>
                      <Small>Total</Small>
                      <OverviewTitle
                        color="var(--humber-light)"
                        size="2rem"
                        weight="600"
                      >
                        {loadPlayers ? (
                          <Loading2 />
                        ) : (
                          <>
                            <NumberFormat
                              value={transactions || "0"}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"₦"}
                            />
                          </>
                        )}
                      </OverviewTitle>
                    </div>
                  </OverviewInner>
                </Link>
              </OverviewColumn>
               {/* <OverviewColumn>
                                   <Link to='/transactions'>
                                   <OverviewInner>
                                      <OverviewTitle >
                                      Game Reserve
                                      </OverviewTitle>
                                      <OverviewIcon>
                                          <FeatherIcon  icon='credit-card' />
                                      </OverviewIcon>
                                   </OverviewInner>
                                   <OverviewInner>
                                 <div>
                                  <Small>Total</Small>
                                <OverviewTitle size='2rem' weight='600'>
                               {loadPlayers ? <Loading2 /> : <> 
                               <NumberFormat value={'0'} displayType={'text'} thousandSeparator={true} prefix={'₦'} />
                                 </>}
                                   </OverviewTitle>
                                  </div>
                                 </OverviewInner>
                                   </Link>
                               </OverviewColumn>  */}
               <OverviewColumn border="none" bgColor="var(--humber-black)">
                             
                                 <OverviewInner>
                                 <OverviewTitle>
                                   Wallet
                                   </OverviewTitle>
                                   <OverviewIcon>
                                          <FeatherIcon  icon='credit-card' />
                                      </OverviewIcon>
                                 </OverviewInner>
                                 <OverviewInner>
                                 <div>
                                 {loadPlayers ? <><Small>Wallet types</Small>  <Loading2 /> </> : 
                                      <div className='wallet-type-container'>
                                      <div className='wallet-type-box'>
                                          <div className='wallet-type'>
                                             <FeatherIcon icon='square' size='15px' color='orange' fill='orange'/>Coins</div>
                                         
                                          <NumberFormat value={wallet?.COINS || '0'} displayType={'text'} thousandSeparator={true}  />
                                      </div>
                                      <div className='wallet-type-box'>
                                          <div className='wallet-type'>
                                             <FeatherIcon icon='square' size='15px' color='#d3d3d3' fill='#d3d3d3'/>Points</div>
                                         
                                          <NumberFormat value={wallet?.POINTS || '0'} displayType={'text'} thousandSeparator={true}  />
                                      </div>
                                      <div className='wallet-type-box'>
                                          <div className='wallet-type'>
                                             <FeatherIcon icon='square' size='15px' color='#08e0a3' fill='#08e0a3'/>Token</div>
                                        
                                          <NumberFormat value={wallet?.TOKEN || '0'} displayType={'text'} thousandSeparator={true}  />
                                           
                                      </div>
                                  </div>
                                   }
                                 
                                  </div>
                                 </OverviewInner>
                               
                               </OverviewColumn> 

              <OverviewColumn border="none" bgColor="var(--humber-black)">
                <OverviewInner>
                  <OverviewTitle>Game Plays</OverviewTitle>
                  <OverviewIcon>
                    <FeatherIcon icon="activity" />
                  </OverviewIcon>
                </OverviewInner>
                <OverviewInner>
                  <div>
                    <Small>Total</Small>
                    <OverviewTitle
                      color="var(--humber-light)"
                      size="2rem"
                      weight="600"
                    >
                      0
                    </OverviewTitle>
                  </div>
                </OverviewInner>
              </OverviewColumn>
              <OverviewColumn border="none" bgColor="var(--humber-black)">
                <Link to="/games">
                  <OverviewInner>
                    <OverviewTitle>Games</OverviewTitle>
                    <OverviewIcon>
                      <FeatherIcon icon="github" />
                    </OverviewIcon>
                  </OverviewInner>
                  <OverviewInner>
                    <div>
                      <Small>Total</Small>
                      <OverviewTitle
                        color="var(--humber-light)"
                        size="2rem"
                        weight="600"
                      >
                       4
                      </OverviewTitle>
                    </div>
                  </OverviewInner>
                </Link>
              </OverviewColumn>
            </OverviewRow>
            {/* <div className="row">
              <div className="col">
                <div className="card" style={{ width: "100%" }}>
                  <div className="card-body ">
                    <h4 className="card-title">Prize reserve</h4>
                    <OverviewInner
                      style={{ gap: "10px" }}
                      padding={"0"}
                      justifyContent={"flex-start"}
                    >
                      <div style={{ color: "var(--humber-light)" }}>Coin:</div>
                      <div style={{ color: "var(--humber-light)" }}>
                        N2,000,400
                      </div>
                    </OverviewInner>
                    <OverviewInner
                      style={{ gap: "10px" }}
                      padding={"0"}
                      justifyContent={"flex-start"}
                    >
                      <div style={{ color: "var(--humber-light)" }}>Coin:</div>
                      <div style={{ color: "var(--humber-light)" }}>
                        N2,000,400
                      </div>
                    </OverviewInner>
                    <OverviewInner
                      style={{ gap: "10px" }}
                      padding={"0"}
                      justifyContent={"flex-start"}
                    >
                      <div style={{ color: "var(--humber-light)" }}>Coin:</div>
                      <div style={{ color: "var(--humber-light)" }}>
                        N2,000,400
                      </div>
                    </OverviewInner>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card  p-3" style={{ width: "100%" }}>
                  <div className="card-body">
                    <h4 className="card-title">Prize reserve</h4>
                    <OverviewInner
                      style={{ gap: "10px" }}
                      padding={"0"}
                      justifyContent={"flex-start"}
                    >
                      <div style={{ color: "var(--humber-light)" }}>Coin:</div>
                      <div style={{ color: "var(--humber-light)" }}>
                        N2,000,400
                      </div>
                    </OverviewInner>
                    <OverviewInner
                      style={{ gap: "10px" }}
                      padding={"0"}
                      justifyContent={"flex-start"}
                    >
                      <div style={{ color: "var(--humber-light)" }}>Coin:</div>
                      <div style={{ color: "var(--humber-light)" }}>
                        N2,000,400
                      </div>
                    </OverviewInner>
                    <OverviewInner
                      style={{ gap: "10px" }}
                      padding={"0"}
                      justifyContent={"flex-start"}
                    >
                      <div style={{ color: "var(--humber-light)" }}>Coin:</div>
                      <div style={{ color: "var(--humber-light)" }}>
                        N2,000,400
                      </div>
                    </OverviewInner>
                  </div>
                </div>
              </div>
            </div> */}
            <TabContent>
              <div className="dashboard-table" style={{ padding: "2rem" }}>
                <table className="players">
                  <tbody>
                    <tr className="tr_deposites dashboard_table">
                      <th>Registrations</th>
                      <th className="text-end">Counts</th>
                    </tr>
                    <tr
                      style={{ color: "var(--humber-light)" }}
                      className="tr_deposites dashboard_table"
                    >
                      <td>Today's registered Users</td>
                      <td className="points text-end">
                        <div>{stat["TODAY"]}</div>
                      </td>
                    </tr>
                    <tr
                      style={{ color: "var(--humber-light)" }}
                      className="tr_deposites dashboard_table"
                    >
                      <td>Total Registration for the past 7 days</td>
                      <td className="points text-end">
                        <div>{stat["SEVEN DAYS"]}</div>
                      </td>
                    </tr>
                    <tr
                      style={{ color: "var(--humber-light)" }}
                      className="tr_deposites dashboard_table"
                    >
                      <td>Total Registrations for the past 30 days</td>
                      <td className="points text-end">
                        <div>{stat["1 MONTH"]}</div>
                      </td>
                    </tr>
                    <tr
                      style={{ color: "var(--humber-light)" }}
                      className="tr_deposites dashboard_table"
                    >
                      <td>Total Registration for the past 3 Months</td>
                      <td className="points text-end">
                        <div>{stat["3 MONTHS"]}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <UserStatRow border="none" justifyContent="flex-end">
                  <Link className="view_more" to="/players">
                    <i>View More Registrations</i>
                  </Link>
                </UserStatRow>
              </div>
            </TabContent>

            {/* <div style={{ ...title }}>Deposits</div>
            <TabContent>
              <div className="dashboard-table" style={{ padding: "2rem" }}>
                <table className="players">
                  <tbody>
                    <tr className="tr_deposites dashboard_table">
                      <th>Period</th>
                      <th className="text-end">Value(N)</th>
                      <th className="text-end">Counts</th>
                    </tr>
                    <tr
                      style={{ color: "var(--humber-light)" }}
                      className="tr_deposites dashboard_table"
                    >
                      <td >Today's Deposits</td>
                      <td className="points text-end">
                        <div>{stat["TODAY"]}</div>
                      </td>
                      <td className="points text-end">
                        <div>{stat["TODAY"]}</div>
                      </td>
                    </tr>
                    <tr
                      style={{ color: "var(--humber-light)" }}
                      className="tr_deposites dashboard_table"
                    >
                      <td>Total Deposits the past 7 days</td>
                      <td  className="points text-end">
                        <div>{stat["SEVEN DAYS"]}</div>
                      </td>
                      <td  className="points text-end">
                        <div>{stat["SEVEN DAYS"]}</div>
                      </td>
                    </tr>
                    <tr
                      style={{ color: "var(--humber-light)" }}
                      className="tr_deposites dashboard_table"
                    >
                      <td>Total Deposits the past 30 days</td>
                      <td className="points text-end">
                        <div>{stat["SEVEN DAYS"]}</div>
                      </td>
                      <td  className="points text-end">
                        <div>{stat["SEVEN DAYS"]}</div>
                      </td>
                    </tr>
                    <tr
                      style={{ color: "var(--humber-light)" }}
                      className="tr_deposites dashboard_table"
                    >
                      <td>Total Deposits the past 3 Months</td>
                      <td  className="points text-end">
                        <div>{stat["SEVEN DAYS"]}</div>
                      </td>
                      <td  className="points text-end">
                        <div>{stat["SEVEN DAYS"]}</div>
                      </td>
                    </tr>
                    
                  </tbody>
                </table>
                <UserStatRow border="none" justifyContent="flex-end">
                  <Link className="view_more" to="/players">
                    <i>View More Deposits</i>
                  </Link>
                </UserStatRow>
              </div>
            </TabContent>
           
          


            <div style={{ ...title }}>Game play</div>
            <TabContent>
              <div className="dashboard-table" style={{ padding: "2rem" }}>
                <table className="players">
                  <tbody>
                    <tr className="tr_deposites dashboard_table">
                      <th>Period</th>
                      <th className="text-end">Active Players</th>
                      <th className="text-end">Guess</th>
                      <th className="text-end">Trivia</th>
                      <th className="text-end">Avatar</th>
                      <th className="text-end">All plays</th>
                    </tr>
                    <tr
                      style={{ color: "var(--humber-light)" }}
                      className="tr_deposites dashboard_table"
                    >
                      <td >Today's Game plays</td>
                      <td className="points text-end">
                        <div>{stat["TODAY"]}</div>
                      </td>
                      <td className="points text-end">
                        <div>{stat["TODAY"]}</div>
                      </td>
                      <td className="points text-end">
                        <div>{stat["TODAY"]}</div>
                      </td>
                      <td className="points text-end">
                        <div>{stat["TODAY"]}</div>
                      </td>
                      <td className="points text-end">
                        <div>{stat["TODAY"]}</div>
                      </td>
                    </tr>
                    <tr
                      style={{ color: "var(--humber-light)" }}
                      className="tr_deposites dashboard_table"
                    >
                      <td>Total Game played in the past 7 days</td>
                      <td colspan="5"  className="points text-end">
                        <div>{stat["SEVEN DAYS"]}</div>
                      </td>
                     
                    </tr>
                    <tr
                      style={{ color: "var(--humber-light)" }}
                      className="tr_deposites dashboard_table"
                    >
                      <td>Total Game played in the past 7 days</td>
                      <td colspan="5"  className="points text-end">
                        <div>{stat["SEVEN DAYS"]}</div>
                      </td>
                     
                    </tr>
                    <tr
                      style={{ color: "var(--humber-light)" }}
                      className="tr_deposites dashboard_table"
                    >
                      <td>Total Game played  in the past 7 days</td>
                      <td colspan="5"  className="points text-end">
                        <div>{stat["SEVEN DAYS"]}</div>
                      </td>
                     
                    </tr>
                    <tr
                      style={{ color: "var(--humber-light)" }}
                      className="tr_deposites dashboard_table"
                    >
                      <td>Total Game played in the past 7 days</td>
                      <td colspan="5"  className="points text-end">
                        <div>{stat["SEVEN DAYS"]}</div>
                      </td>
                     
                    </tr>
                    
                  </tbody>
                </table>
                <UserStatRow border="none" justifyContent="flex-end">
                  <Link className="view_more" to="/players">
                    <i>View More Game plays</i>
                  </Link>
                </UserStatRow>
              </div>
            </TabContent>
           
            <div style={{ ...title }}>Prize reserve</div>
            <TabContent>
              <div className="dashboard-table" style={{ padding: "2rem" }}>
                <table className="players">
                  <tbody>
                    <tr className="tr_deposites dashboard_table">
                      <th>Period</th>
                      <th className="text-end">Price reserve</th>
                      <th className="text-end">Wins from price reserve</th>
                    </tr>
                    <tr
                      style={{ color: "var(--humber-light)" }}
                      className="tr_deposites dashboard_table"
                    >
                      <td >Today's Prize reserve</td>
                      <td className="points text-end">
                        <div>300,000</div>
                      </td>
                      <td className="points text-end">
                      <div>300,000</div>
                      </td>
                    </tr>
                    <tr
                      style={{ color: "var(--humber-light)" }}
                      className="tr_deposites dashboard_table"
                    >
                      <td>Total Prize reserve the past 7 days</td>
                      <td colspan="2"  className="points text-end">
                        <div>{stat["SEVEN DAYS"]}</div>
                      </td>
                     
                    </tr>
                    <tr
                      style={{ color: "var(--humber-light)" }}
                      className="tr_deposites dashboard_table"
                    >
                      <td>TotalPrize reserve the past 30 days</td>
                      <td colspan="2"  className="points text-end">
                        <div>{stat["SEVEN DAYS"]}</div>
                      </td>
                    </tr>
                    <tr
                      style={{ color: "var(--humber-light)" }}
                      className="tr_deposites dashboard_table"
                    >
                      <td>Total Prize reserve the past 3 Months</td>
                      <td colspan="2"  className="points text-end">
                        <div>{stat["SEVEN DAYS"]}</div>
                      </td>
                    </tr>
                    
                  </tbody>
                </table>
                <UserStatRow border="none" justifyContent="flex-end">
                  <Link className="view_more" to="/players">
                    <i>View More Reserves</i>
                  </Link>
                </UserStatRow>
              </div>
            </TabContent>

             <div style={{ ...title }}>Deposits</div>
            <TabContent>
              <div className="dashboard-table" style={{ padding: "2rem" }}>
                <table className="players">
                  <tbody>
                    <tr className="tr_deposites dashboard_table">
                      <th>Period</th>
                      <th className="text-end">Value(N)</th>
                      <th className="text-end">Counts</th>
                    </tr>
                    <tr
                      style={{ color: "var(--humber-light)" }}
                      className="tr_deposites dashboard_table"
                    >
                      <td >Today's Deposits</td>
                      <td className="points text-end">
                        <div>{stat["TODAY"]}</div>
                      </td>
                      <td className="points text-end">
                        <div>{stat["TODAY"]}</div>
                      </td>
                    </tr>
                    <tr
                      style={{ color: "var(--humber-light)" }}
                      className="tr_deposites dashboard_table"
                    >
                      <td>Total Deposits the past 7 days</td>
                      <td  className="points text-end">
                        <div>{stat["SEVEN DAYS"]}</div>
                      </td>
                      <td  className="points text-end">
                        <div>{stat["SEVEN DAYS"]}</div>
                      </td>
                    </tr>
                    <tr
                      style={{ color: "var(--humber-light)" }}
                      className="tr_deposites dashboard_table"
                    >
                      <td>Total Deposits the past 30 days</td>
                      <td className="points text-end">
                        <div>{stat["SEVEN DAYS"]}</div>
                      </td>
                      <td  className="points text-end">
                        <div>{stat["SEVEN DAYS"]}</div>
                      </td>
                    </tr>
                    <tr
                      style={{ color: "var(--humber-light)" }}
                      className="tr_deposites dashboard_table"
                    >
                      <td>Total Deposits the past 3 Months</td>
                      <td  className="points text-end">
                        <div>{stat["SEVEN DAYS"]}</div>
                      </td>
                      <td  className="points text-end">
                        <div>{stat["SEVEN DAYS"]}</div>
                      </td>
                    </tr>
                    
                  </tbody>
                </table>
                <UserStatRow border="none" justifyContent="flex-end">
                  <Link className="view_more" to="/players">
                    <i>View More Deposits</i>
                  </Link>
                </UserStatRow>
              </div>
            </TabContent>

            <div style={{ ...title }}>Winnings</div>
            <TabContent>
              <div className="dashboard-table" style={{ padding: "2rem" }}>
                <table className="players">
                  <tbody>
                    <tr className="tr_deposites dashboard_table">
                      <th>Period</th>
                      <th className="text-end">Value(N)</th>
                      <th className="text-end">Counts</th>
                    </tr>
                    <tr
                      style={{ color: "var(--humber-light)" }}
                      className="tr_deposites dashboard_table"
                    >
                      <td >Today's Winnings</td>
                      <td className="points text-end">
                        <div>{stat["TODAY"]}</div>
                      </td>
                      <td className="points text-end">
                        <div>{stat["TODAY"]}</div>
                      </td>
                    </tr>
                    <tr
                      style={{ color: "var(--humber-light)" }}
                      className="tr_deposites dashboard_table"
                    >
                      <td>Total Winnings the past 7 days</td>
                      <td  className="points text-end">
                        <div>{stat["SEVEN DAYS"]}</div>
                      </td>
                      <td  className="points text-end">
                        <div>{stat["SEVEN DAYS"]}</div>
                      </td>
                    </tr>
                    <tr
                      style={{ color: "var(--humber-light)" }}
                      className="tr_deposites dashboard_table"
                    >
                      <td>Total Winnings the past 30 days</td>
                      <td className="points text-end">
                        <div>{stat["SEVEN DAYS"]}</div>
                      </td>
                      <td  className="points text-end">
                        <div>{stat["SEVEN DAYS"]}</div>
                      </td>
                    </tr>
                    <tr
                      style={{ color: "var(--humber-light)" }}
                      className="tr_deposites dashboard_table"
                    >
                      <td>Total Winnings the past 3 Months</td>
                      <td  className="points text-end">
                        <div>{stat["SEVEN DAYS"]}</div>
                      </td>
                      <td  className="points text-end">
                        <div>{stat["SEVEN DAYS"]}</div>
                      </td>
                    </tr>
                    
                  </tbody>
                </table>
                <UserStatRow border="none" justifyContent="flex-end">
                  <Link className="view_more" to="/players">
                    <i>View More Winnings</i>
                  </Link>
                </UserStatRow>
              </div>
            </TabContent> */}
            {/* <OverviewRow>
              <OverviewColumn bgColor="transparent" height="fit-content">
                <InnerColumn>
                  <div className="leaderboard">
                    <OverviewTitle color="var(--humber-light)" weight="600">
                      Game Reserve Accounts
                    </OverviewTitle>
                    <div>
                      <div style={{ padding: "1rem 0" }}>
                        <OverviewTitle
                          color="var(--humber-light)"
                          size="1.1rem"
                        >
                          Total Game Reserve
                        </OverviewTitle>

                        <div
                          style={{
                            background: "#00509d",
                            height: "30px",
                            margin: ".7rem 0",
                          }}
                        >
                          <div
                            style={{
                              background: "#ed9717",
                              width: "60%",
                              height: "30px",
                            }}
                          ></div>
                        </div>
                      </div>
                      <div style={{ padding: "1rem 0" }}>
                        <OverviewTitle
                          color="var(--humber-light)"
                          size="1.1rem"
                        >
                          Token Reserve
                        </OverviewTitle>

                        <div
                          style={{
                            background: "#00509d",
                            height: "30px",
                            margin: ".7rem 0",
                          }}
                        >
                          <div
                            style={{
                              background: "#ed9717",
                              width: "80%",
                              height: "30px",
                            }}
                          ></div>
                        </div>
                      </div>
                      <div style={{ padding: "1rem 0" }}>
                        <OverviewTitle
                          color="var(--humber-light)"
                          size="1.1rem"
                        >
                          Coin Reserve
                        </OverviewTitle>

                        <div
                          style={{
                            background: "#00509d",
                            height: "30px",
                            margin: ".7rem 0",
                          }}
                        >
                          <div
                            style={{
                              background: "#ed9717",
                              width: "30%",
                              height: "30px",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className='view-more'>
                                           <div className='view-more-box'>
                                           <Link to='/'>View More   <FeatherIcon icon='arrow-right' /></Link>
                                           </div>
                      </div>
                  </div>
                </InnerColumn>

                <InnerColumn>
                  <div className="games-report-wrapper">
                    <div className="circle">
                      <div className="game-title">Games</div>
                      <div className="total">3</div>
                    </div>
                    <div className="games-stat">
                      <div className="games-stat-box">
                        <div className="games-title">
                          {" "}
                          <FeatherIcon
                            icon="square"
                            size="15px"
                            color="orange"
                            fill="orange"
                          />
                          Avatar
                        </div>
                        <div className="games-coins"> 20 coins</div>
                      </div>

                      <div className="games-stat-box">
                        <div className="games-title">
                          {" "}
                          <FeatherIcon
                            icon="square"
                            size="15px"
                            color="red"
                            fill="red"
                          />
                          Guess Games
                        </div>
                        <div className="games-coins"> 45 coins</div>
                      </div>
                      <div className="games-stat-box">
                        <div className="games-title">
                          {" "}
                          <FeatherIcon
                            icon="square"
                            size="15px"
                            color="#0581b6"
                            fill="#0581b6"
                          />
                          Trivia
                        </div>
                        <div className="games-coins"> 20 coins</div>
                      </div>
                    </div>
                  </div>
                  <div className='view-more'>
                                           <div className='view-more-box'>
                                           <Link to='/'>View More   <FeatherIcon icon='arrow-right' /></Link>
                                           </div>
                                        </div>
                </InnerColumn>
              </OverviewColumn>
              <OverviewColumn bgColor="var(--humber-dark)" height="fit-content">
                <div className="leaderboard">
                  <img src=""   alt='leaderboard' width='60px' />
                  <OverviewTitle color="var(--humber-light)">
                    Leaderboard
                  </OverviewTitle>
                  {!loading && (
                    <TableContainer>
                      <div className="leaderboard-table">
                        <table className="leaders">
                          <tbody>
                            <tr>
                              <th>Rank</th>
                              <th>Name</th>

                              <th>Points</th>
                            </tr>

                            {leaderboard?.map((item, i) => (
                              <tr key={i}>
                                <td class="rank">{i + 1}</td>
                                <td>
                                  <div className="user-avatar">
                                    <img src="" alt='avatar' /> 
                                              
                                              style={{background:`#${Math.floor(Math.random()*16777215).toString(16)}`}}
                                             
                                    <div className="text-avatar">
                                      {item?.user?.name.charAt(0)}
                                    </div>
                                    <span>
                                      {item?.user?.name.split(" ")[0]}
                                    </span>{" "}
                                  </div>
                                </td>

                                <td className="points">
                                  {}
                                  <NumberFormat
                                    value={item.points}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />
                                  pts
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </TableContainer>
                  )}
                </div>
                {loading && <Loading />}
              </OverviewColumn>
            </OverviewRow> */}
                
              <OverviewColumn bgColor="var(--humber-dark)" height="fit-content">
              <div className="leaderboard dashboard-table">
                  <OverviewTitle color="var(--humber-light)">
                    Leaderboard
                  </OverviewTitle>
                    <TableContainer>
                      <div className="leaderboard-table">
                        <table className="leaders">
                          <tbody>
                            <tr>
                              <th>Rank</th>
                              <th>Name</th>

                              <th>Points</th>
                            </tr>

                            {leaderboard?.map((item, i) => (
                              <tr key={i}>
                                <td className="rank">{i + 1}</td>
                                <td>
                                  <div className="user-avatar">
                                   
                                              
                                              {/* style={{background:`#${Math.floor(Math.random()*16777215).toString(16)}`}} */}
                                             
                                    <div className="text-avatar">
                                      {item?.user?.name?.charAt(0)}
                                    </div>
                                    <span>
                                      {item?.user?.name?.split(" ")[0]}
                                    </span>{" "}
                                  </div>
                                </td>

                                <td className="points">
                                  {}
                                  <NumberFormat
                                    value={item?.points}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />
                                  pts
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </TableContainer>
                    </div>
                    </OverviewColumn>
                   
            <Toaster position="top-center" />
          </OverviewContainer>
        </ContentWrapper>
      </DashboardContainer>
    </>
  );
};

export default UserDashboard;
